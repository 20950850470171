import Modal from 'components/Modal'
import styled, { css } from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: flex-end;
  border-top: 0.1rem solid #dfe6ed;
  padding: 1.4rem 3.2rem;
  z-index: -1;
  button {
    margin-left: 0.8rem;
  }
`

export const ImportMetricValuesContainer = styled.div`
  display: flex;
  padding: 2.4 2.4rem 0 2.4rem;

  @media ${maxSize.md} {
    flex-direction: column;
  }
`

export const TableWrapper = styled.div`
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 0.8rem;
`

export const DataPointsContainer = styled.div<{
  withCustomWidth?: boolean
}>`
  padding: 2.4rem;
  width: ${(props) => (props.withCustomWidth ? '42.6rem' : '100%')};

  @media ${maxSize.md} {
    width: 100%;
  }
`

export const TableHeader = styled.span`
  display: flex;
  border-bottom: 0.2rem solid ${color('veryLightGray')};
  padding: 1.4rem;
`

export const TableHeaderItem = styled.span<{
  flex?: number
  disabled?: boolean
  minWidth?: string
}>`
  flex: ${(props) => props.flex ?? 1};
  color: ${color('lightGray')};
  font-weight: bold;
  margin-left: 1.4rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  min-width: ${(props) => props.minWidth ?? 'auto'};

  svg {
    margin-right: 0.4rem;
    font-size: 1.4rem;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`

export const OptionalLabel = styled.i`
  text-transform: lowercase;
`

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  padding: 0.6rem 1.6rem;
`

export const TableCol = styled.div<{
  flex?: number
  minWidth?: string
}>`
  flex: ${(props) => props.flex ?? 1};
  min-width: ${(props) => props.minWidth ?? 'auto'};
`

export const TableColIndex = styled(TableCol)`
  color: ${color('lightGray')};
  font-weight: bold;
  font-size: 1.4rem;
`

export const TableContent = styled.div<{
  withoutScroll?: boolean
}>`
  width: 100%;

  @media (max-width: 768px) {
    width: auto;
  }

  .ReactVirtualized__List {
    ${(props) =>
      props.withoutScroll &&
      css`
        overflow: visible !important;
      `}
  }
  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }
`

export const ShareGroupsContainer = styled.div`
  padding: 1.8rem 2.4em 0.5rem 0;
  flex: 1;
  min-width: 30rem;
  max-width: 40rem;

  @media ${maxSize.md} {
    min-width: auto;
    max-width: auto;
  }

  ul[class*='BoxGroupsAndUsersChips_chipsList'] {
    max-height: 15.5rem;
  }
`

export const MetricValuesContainer = styled.div`
  width: 100%;
`

export const LoaderWrapper = styled.div`
  div[class*='Loader_overlay'] {
    width: 100%;
    height: 100%;
  }
`
