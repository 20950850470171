import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import CompanyService from 'api/CompanyService'
import NumbersService from 'api/NumbersService'
import Title from 'components/Title'
import AddInput from 'components/AddInput'
import List from 'components/List'
import SetAsMainRow from 'components/List/SetAsMainRow'
import Toast from 'components/Toast'

import { cloneDeep } from 'lodash'
import * as Styles from '../common/styles'

const CompanyName = ({
  isFundCompany,
  companyId,
  doingBusinessAs,
  onChange,
}) => {
  const intl = useIntl()

  const save = async (rows) => {
    const newName = rows[0]

    try {
      const {
        data: {
          entities: { companyData },
        },
      } = await CompanyService.editCompany(companyId, {
        doingBusinessAs: rows,
        name: newName,
      })

      await NumbersService.updateMetrics({
        subjectId: companyId,
        newName,
      })
      onChange({ companyData })
    } catch (errors) {
      Toast.display(
        intl.formatMessage(
          { id: 'errors.editItemError' },
          { name: '', item: intl.messages['general.companyNames'] }
        ),
        'error'
      )
    }
  }

  const setAsMain = (index) => {
    if (index < 0 || index >= doingBusinessAs.length) {
      return
    }

    const rows = [...doingBusinessAs]

    ;[rows[0], rows[index]] = [rows[index], rows[0]]
    save(rows)
  }

  const removeItem = (index) => {
    const rows = doingBusinessAs
    rows.splice(index, 1)
    save(rows)
  }

  const getRows = (() =>
    doingBusinessAs.map((elem, index) => (
      <SetAsMainRow
        text={elem}
        isMain={!index}
        deletable={!!index}
        capitalized
        setAsMain={() => setAsMain(index)}
        removeItem={() => removeItem(index)}
      />
    )))()

  return (
    <Styles.Item>
      <Title title={intl.formatMessage({ id: 'editCompany.name' })} />
      <AddInput
        name="companyName"
        handleAdd={(value) => save([...doingBusinessAs, value])}
        placeholder={intl.messages['editCompany.doingBusinessAs']}
        forceShowIcon
        checkLengthError
      />
      <List elements={getRows} striped={false} rowHeightAuto rowWidthAuto />
    </Styles.Item>
  )
}

CompanyName.propTypes = {
  companyId: PropTypes.string,
  doingBusinessAs: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  isFundCompany: PropTypes.bool,
}

CompanyName.defaultProps = {
  companyId: null,
  doingBusinessAs: [],
  isFundCompany: false,
}

export default CompanyName
