import styled from 'styled-components'

export const ChipsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8rem;
  max-height: 25rem;
  min-height: 3rem;
  overflow: auto;
`

export const ChipsListItem = styled.li`
  margin: 0.6rem 0.6rem 0 0;
`
