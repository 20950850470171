import { InfiniteQueryData } from 'api/types'
import { useMemo } from 'react'

import { SortDirection } from 'utils/constants'
import { MetricSortBy } from 'utils/constants/metrics'
import { getMetricsGroupByMetricCards } from 'utils/functions/metricsCard'
import { useMetricsQuery } from 'utils/hooks/queries/useMetrics'
import { useObserveQuery } from 'utils/hooks/useObserveQuery'
import { metricsKeys } from 'utils/queries/metrics'
import { IndexMetric } from 'utils/types/metricsV2'

export const useSelectedMetrics = () => {
  const { metrics: allMetrics, isLoading: isLoadingAllMetrics } =
    useMetricsQuery({
      settings: {
        includeLinkedMetrics: false,
      },
    })

  const { data: metrics, isFetching } = useObserveQuery<
    InfiniteQueryData<IndexMetric>
  >(
    metricsKeys.getMetrics({
      selected: true,
    })
  )

  const isLoading = isFetching

  const hasMetrics = useMemo(() => {
    return (
      isLoadingAllMetrics ||
      (allMetrics?.pages?.flatMap((page) => page.data) ?? []).length > 0
    )
  }, [allMetrics, isLoadingAllMetrics])

  const hasSelectedMetrics = useMemo(() => {
    return (
      isLoading ||
      (metrics?.pages?.flatMap((page) => page.data) ?? []).length > 0
    )
  }, [metrics, isLoading])

  const metricsCards = useMemo(
    () =>
      metrics
        ? getMetricsGroupByMetricCards(metrics, {
            sortBy: MetricSortBy.NAME,
            direction: SortDirection.ASC,
          }).sort((a, b) => a.name.localeCompare(b.name))
        : [],
    [metrics]
  )

  return {
    metricsCards,
    hasMetrics,
    isLoading: isLoading || isLoadingAllMetrics,
    hasSelectedMetrics,
  }
}
