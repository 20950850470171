import React from 'react'
import GroupLogo from 'components/GroupLogo'
import Tooltip from 'components/Tooltip'
import { useIntl } from 'react-intl'
import type { PlacesType } from 'react-tooltip'
import {
  CountGroups,
  GroupItem,
  GroupName,
  Label,
  Row,
  SharedGroups,
  TooltipsWrapper,
} from './GroupTooltips.styles'
import useGroupTooltips from './useGroupTooltips'

export interface Groups {
  id: string
  name?: string
  logo?: {
    url: string
  }
  description?: string
}

interface GroupTooltipsProps {
  disabled?: boolean
  direction?: string
  groups?: Groups[]
  unsortedGroups?: Groups[]
  small?: boolean
  tooltipPlace?: PlacesType
  className?: string
  maxGroupsToDisplay?: number
  tooltipTopOffset?: number
  tooltipRightOffset?: number
  isUpdateAction?: boolean
  tooltipConfig?: {
    backgroundColor?: string
    content?: any
    style?: any
    class?: string
    clickable?: boolean
    delayHide?: number
    parentTooltipStyles?: React.CSSProperties
  }
}

const GroupTooltips = ({
  groups,
  unsortedGroups = [],
  small,
  direction,
  maxGroupsToDisplay,
  tooltipPlace,
  tooltipTopOffset,
  tooltipRightOffset,
  tooltipConfig,
  className,
  isUpdateAction,
  disabled,
}: GroupTooltipsProps) => {
  const intl = useIntl()

  const {
    tooltipGroups,
    tooltipId,
    showCountGroupTooltips,
    hiddenGroups,
    groupsToDisplay,
    groupIndexOffset,
  } = useGroupTooltips({
    groups: groups ?? [],
    direction,
    unsortedGroups,
    maxGroupsToDisplay,
  })

  const showTooltipOnRight = showCountGroupTooltips && direction === 'right'
  const showTooltipOnLeft = showCountGroupTooltips && direction === 'left'

  if (!groups?.length && !unsortedGroups?.length) {
    return (
      <Label italic>
        {intl.formatMessage({ id: 'metrics.yourGroupOnly' })}
      </Label>
    )
  }

  const renderLogo = (group) =>
    group?.logoComponent ? (
      group.logoComponent
    ) : (
      <GroupLogo imageUrl={group?.logo?.url} name={group?.name} small={small} />
    )

  const renderGroup = (group) => (
    <Row key={group.id}>
      {renderLogo(group)}
      <GroupName className="group-name">{group?.name}</GroupName>
    </Row>
  )

  const renderGroups = () => (
    <>
      {unsortedGroups &&
        unsortedGroups?.length > 0 &&
        unsortedGroups?.map(renderGroup)}
      {tooltipGroups.map(renderGroup)}
    </>
  )

  return (
    <SharedGroups data-testid="groups-tooltip-container">
      <Tooltip
        id={`groupTooltip_${tooltipId}`}
        className={className}
        place={tooltipPlace}
        topOffset={tooltipTopOffset}
        rightOffset={tooltipRightOffset}
        event="mouseover mouseenter"
        eventOff="mouseleave mouseout scroll mousewheel blur"
        text={
          tooltipConfig?.content ? (
            tooltipConfig.content(renderGroups())
          ) : (
            <div>{renderGroups()}</div>
          )
        }
        direction={direction}
        arrowColor="transparent"
        backgroundColor={tooltipConfig?.backgroundColor}
        clickable={tooltipConfig?.clickable}
        delayHide={tooltipConfig?.delayHide}
        class={tooltipConfig?.class}
        parentTooltipStyles={tooltipConfig?.parentTooltipStyles}
      >
        <TooltipsWrapper
          isUpdateAction={isUpdateAction}
          countGroups={groups?.length}
        >
          {showTooltipOnRight && (
            <>
              <GroupItem
                small={small}
                direction={direction}
                length={Number(groups?.length) + 1}
                id={`group_${tooltipId}_grouped`}
                index={0}
              />
              <CountGroups>+{hiddenGroups.length}</CountGroups>
            </>
          )}

          {groupsToDisplay?.map((group, index) => (
            <GroupItem
              key={group.id}
              small={small}
              direction={direction}
              length={Number(groups?.length) + 1}
              id={`group_${tooltipId}_grouped`}
              index={index + groupIndexOffset}
              disabled={disabled}
            >
              {renderLogo(group)}
            </GroupItem>
          ))}

          {showTooltipOnLeft && (
            <>
              <GroupItem
                small={small}
                direction={direction}
                length={Number(groups?.length) + 1}
                id={`group_${tooltipId}_grouped`}
                index={groupsToDisplay.length}
              />
              <CountGroups>+{hiddenGroups.length}</CountGroups>
            </>
          )}
        </TooltipsWrapper>
      </Tooltip>
    </SharedGroups>
  )
}

export default GroupTooltips
