/* eslint-disable no-param-reassign */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/prop-types, array-callback-return, consistent-return */
import { FormattedMessage, type IntlShape } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { chain, sortBy } from 'lodash'
import {
  MetricFrequency,
  METRIC_NAMES,
  MetricStates,
  MetricSortBy,
} from 'utils/constants/metrics'
import { displayThousands, withDecimals } from 'utils/functions/number'
import { MetricSources } from 'utils/types/metrics'

import Tooltip from 'components/Tooltip'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'

import {
  Triangle,
  DateContainer,
  GroupCell,
  DateWrapper,
  TooltipGlobalStyles,
} from 'containers/Metrics/MetricsList/Metrics.styles'
import OptionsDropdown from 'ui/OptionsDropdown'
import FloatColumnWrapper from 'components/ExpandableTable/FloatButtonColumn/FloatColumnWrapper'
import { FMVCell } from 'components/FairMarketValueHistory/FairMarketValueHistory.styles'
import GrowthIndicator from 'components/Dashboard/GrowthIndicator/GrowthIndicator'
import GrowthValue from 'components/Dashboard/GrowthIndicator/GrowthValue'
import GroupTooltips from 'components/GroupTooltips/GroupTooltips'
import {
  TimesIconWrapper,
  ValueWrapper,
} from 'containers/Metrics/ShareDataPoints/ShareDataPoints.styles'
import {
  GrowthIndicatorWrapper,
  MetricValue,
} from 'containers/Metrics/ShowMetric/ShowMetric.styles'
import { SortDirection } from 'utils/constants/sortDirection'
import { HoldingType } from 'utils/types/company'
import { color } from 'utils/functions/colors'
import theme from 'utils/theme'
import { useGroupsQuery } from 'utils/hooks/queries/useGroupQuery'
import {
  DataPoint,
  IndexMetric,
  LinkedMetricState,
  Metric,
  Milestone,
} from 'utils/types/metricsV2'
import { SkeletonAvatarWithAnimation } from 'components/Skeletons/Skeletons.styles'
import { SelectableDataPoint } from 'containers/Metrics/ShowMetric/useMetric'
import { Column } from 'ui/Table/types'
import { metricsKeys } from 'utils/queries/metrics'
import { GroupTypes } from '../constants/groups'

const MetricDataPointSharedGroupsCell = ({
  dataPoint,
  currentGroupId,
}: {
  dataPoint: DataPoint
  currentGroupId: string
}) => {
  const { data: sharedGroups, isLoading } = useGroupsQuery(
    dataPoint.sharedGroups
  )

  return (
    <GroupCell>
      {isLoading ? (
        <SkeletonAvatarWithAnimation width="2.1rem" height="2.1rem" />
      ) : (
        <GroupTooltips
          small
          groups={sharedGroups?.filter(
            (metricGroup) => metricGroup?.id !== currentGroupId
          )}
          maxGroupsToDisplay={3}
          direction="left"
          tooltipPlace="right"
        />
      )}
    </GroupCell>
  )
}

type CombinedMetric = IndexMetric | Metric

export const getDropdownOptionsForStatus = ({
  metricData,
  onRemoveMetric,
  onAddNewValue,
  onEditMetric,
  activeMilestones,
  onEditMilestone,
  onSetMilestone,
  intl,
  onViewMetricDetail,
  // redirectToLogs,
  onImportCsv,
  onExportCsv,
}: {
  metricData: CombinedMetric
  onRemoveMetric: (metric: CombinedMetric) => void
  onAddNewValue: (metric: CombinedMetric) => void
  onEditMetric: (metric: CombinedMetric) => void
  activeMilestones: Milestone[]
  onEditMilestone: (metric: CombinedMetric, milestone: Milestone) => void
  onSetMilestone: (metric: CombinedMetric) => void
  intl: IntlShape
  onViewMetricDetail: (metricId: string) => void
  redirectToLogs: () => void
  onImportCsv: () => void
  onExportCsv: (metric: CombinedMetric) => void
}) => {
  const isCalculatedMetric = metricData.source === MetricSources.System
  const hasDataPoints = metricData.dataPoints?.length > 0

  return [
    <OptionsDropdown.Item
      key={metricData.id.concat('_viewMetricDetails')}
      label={<FormattedMessage id="metrics.viewMetricDetails" />}
      icon={['fal', 'analytics']}
      onSelectOption={() => onViewMetricDetail(metricData?.id)}
    />,
    !isCalculatedMetric && (
      <ReadOnlyTooltip
        key={metricData.id.concat('_addDataPoint')}
        id={`addDataPointDropdown_${metricData.id}`}
        text={intl.formatMessage({ id: 'readOnlyMode.addMetricValues' })}
        place="left"
        leftOffset={-10}
        maxWidth="38.5rem"
      >
        <OptionsDropdown.Item
          key={metricData.id.concat('_addNewValue_item')}
          label={<FormattedMessage id="metrics.addValue" />}
          icon={['fal', 'plus-square']}
          onSelectOption={() => onAddNewValue(metricData)}
        />
      </ReadOnlyTooltip>
    ),

    hasDataPoints && (
      <ReadOnlyTooltip
        key={metricData.id.concat('_exportCsv')}
        id={`exportCsvDropdown_${metricData.id}`}
        text={intl.messages['readOnlyMode.exportMetricCsv']}
        place="left"
        leftOffset={-10}
        maxWidth="38.5rem"
      >
        <OptionsDropdown.Item
          key={metricData.id.concat('_exportCsv_item')}
          label={intl.messages['metrics.exportCsv']}
          icon={['fal', 'file-export']}
          onSelectOption={() => onExportCsv(metricData)}
        />
      </ReadOnlyTooltip>
    ),
    !isCalculatedMetric && (
      <ReadOnlyTooltip
        key={metricData.id.concat('_importCsv')}
        id={`importCsvDropdown_${metricData.id}`}
        text={intl.formatMessage({ id: 'readOnlyMode.importMetricCsv' })}
        place="left"
        leftOffset={-10}
        maxWidth="38.5rem"
      >
        <OptionsDropdown.Item
          key={metricData.id.concat('_importCsv_item')}
          label={intl.formatMessage({
            id: 'metrics.importCsv',
          })}
          icon={['fal', 'file-import']}
          onSelectOption={onImportCsv}
        />
      </ReadOnlyTooltip>
    ),
    hasDataPoints &&
      (activeMilestones?.length ? (
        <ReadOnlyTooltip
          key={metricData.id.concat('_editMilestone')}
          id={`editMilestoneDropdown_${metricData.id}`}
          text={intl.formatMessage({ id: 'readOnlyMode.editMilestone' })}
          place="left"
          leftOffset={-10}
          maxWidth="38.5rem"
        >
          <OptionsDropdown.Item
            key={metricData.id.concat('_editMilestone_item')}
            label={<FormattedMessage id="metrics.editMilestone" />}
            icon={['fal', 'trophy-alt']}
            onSelectOption={() => {
              onEditMilestone(metricData, activeMilestones[0])
            }}
          />
        </ReadOnlyTooltip>
      ) : (
        <ReadOnlyTooltip
          key={metricData.id.concat('_setMilestone')}
          id={`setMilestoneDropdown_${metricData.id}`}
          text={intl.formatMessage({ id: 'readOnlyMode.setMilestone' })}
          place="left"
          leftOffset={-10}
          maxWidth="38.5rem"
        >
          <OptionsDropdown.Item
            key={metricData.id.concat('_setMilestone_item')}
            label={
              <FormattedMessage
                id={
                  isCalculatedMetric
                    ? 'metrics.setGroupMilestone'
                    : 'metrics.setMilestone'
                }
              />
            }
            icon={['fal', 'trophy-alt']}
            onSelectOption={() => onSetMilestone(metricData)}
          />
        </ReadOnlyTooltip>
      )),
    !isCalculatedMetric && (
      <OptionsDropdown.Item
        key={metricData.id.concat('_metricSettings')}
        label={<FormattedMessage id="metrics.metricSettings" />}
        icon={['fal', 'cog']}
        onSelectOption={() => onEditMetric(metricData)}
      />
    ),
    // TODO: TAS-13863
    // <OptionsDropdown.Item
    //   key={metricData.id.concat('_metricLogs')}
    //   label={<FormattedMessage id="metrics.metricLog" />}
    //   icon={['fal', 'clipboard-list-check']}
    //   onSelectOption={redirectToLogs}
    // />,
    !isCalculatedMetric && (
      <OptionsDropdown.Item
        key={metricData.id.concat('_removeMetric')}
        label={<FormattedMessage id="metrics.removeMetric" />}
        color={theme.colors.red}
        icon={['fal', 'trash-alt']}
        onSelectOption={() => onRemoveMetric(metricData)}
      />
    ),
  ].filter(Boolean)
}

export const isMetricOutOfDate = (
  frequency: MetricFrequency,
  countDays: number,
  limitPercent = 0.25
) => {
  if (countDays === null) {
    return false
  }
  switch (frequency) {
    case MetricFrequency.ANNUALLY:
      return countDays <= Math.ceil(365 * limitPercent)
    case MetricFrequency.QUARTERLY:
      return countDays <= Math.ceil(90 * limitPercent)
    case MetricFrequency.MONTHLY:
      return countDays <= Math.ceil(30 * limitPercent)
    case MetricFrequency.WEEKLY:
      return countDays <= Math.ceil(7 * limitPercent)
    default:
      return false
  }
}

export const getMetricCompaniesPortfoliosFormat = (companies, portfolios) => {
  return [
    {
      id: 'PORTFOLIOS',
      group: 'Funds or Portfolios',
      subOptions: portfolios,
    },
    {
      id: 'COMPANIES',
      group: 'Portfolio Holdings',
      subOptions: companies.map((company) => ({
        ...company,
        type: 'Company',
        logo: company.primaryLogo?.url,
      })),
    },
  ]
}

export const getMetricNames = (intl: IntlShape) => {
  return [
    {
      id: 'CUSTOMER_METRICS',
      group: 'Customer Metrics',
      subOptions: [
        {
          id: METRIC_NAMES.CUSTOMER_ACQUISITION_COST,
          label: intl.formatMessage({ id: 'metrics.customerAcquisitionCost' }),
        },
        {
          id: METRIC_NAMES.CUSTOMER_CHURN_RATE,
          label: intl.formatMessage({ id: 'metrics.customerChurnRate' }),
        },
        {
          id: METRIC_NAMES.CUSTOMER_LIFETIME_VALUE,
          label: intl.formatMessage({ id: 'metrics.customerLifetimeValue' }),
        },
        {
          id: METRIC_NAMES.CUSTOMER_SATISFACTION_RETENTION,
          label: intl.formatMessage({
            id: 'metrics.customerSatisfactionRetention',
          }),
        },
        {
          id: METRIC_NAMES.NUMBER_OF_CUSTOMERS,
          label: intl.formatMessage({ id: 'metrics.numberOfCustomers' }),
        },
      ],
    },
    {
      id: 'FINANCIAL_METRICS',
      group: 'Financial Metrics',
      subOptions: [
        {
          id: METRIC_NAMES.REVENUE,
          label: intl.formatMessage({ id: 'metrics.revenue' }),
        },
        {
          id: METRIC_NAMES.EXPENSES,
          label: intl.formatMessage({ id: 'metrics.expenses' }),
        },
        {
          id: METRIC_NAMES.OPERATING_CASH_FLOW,
          label: intl.formatMessage({ id: 'metrics.operatingCashFlow' }),
        },
        {
          id: METRIC_NAMES.NET_INCOME,
          label: intl.formatMessage({ id: 'metrics.netIncome' }),
        },
        {
          id: METRIC_NAMES.NET_PROFIT,
          label: intl.formatMessage({ id: 'metrics.netProfit' }),
        },
      ],
    },
    {
      id: 'SALES_AND_MARKETING_METRICS',
      group: 'Sales and marketing metrics',
      subOptions: [
        {
          id: METRIC_NAMES.SALES,
          label: intl.formatMessage({ id: 'metrics.sales' }),
        },
        {
          id: METRIC_NAMES.SALES_GROWTH,
          label: intl.formatMessage({ id: 'metrics.salesGrowth' }),
        },
        {
          id: METRIC_NAMES.NEW_LEADS_PROSPECTS,
          label: intl.formatMessage({ id: 'metrics.newLeadsProspects' }),
        },
        {
          id: METRIC_NAMES.SALE_CONVERSION_RATE,
          label: intl.formatMessage({ id: 'metrics.saleConversionRate' }),
        },
      ],
    },
  ]
}

export const getFrequencyNames = (intl: IntlShape) => {
  return [
    {
      id: MetricFrequency.WEEKLY,
      label: intl.formatMessage({ id: 'metrics.weekly' }),
    },
    {
      id: MetricFrequency.MONTHLY,
      label: intl.formatMessage({ id: 'metrics.monthly' }),
    },
    {
      id: MetricFrequency.QUARTERLY,
      label: intl.formatMessage({ id: 'metrics.quarterly' }),
    },
    {
      id: MetricFrequency.ANNUALLY,
      label: intl.formatMessage({ id: 'metrics.annually' }),
    },
    {
      id: MetricFrequency.NONE,
      label: intl.formatMessage({ id: 'metrics.none' }),
    },
  ]
}

const DELETE_METRIC_THRESHOLD_IN_DAYS = 2
export const isCreationDateOlderThanTwoDays = (createdAt) => {
  const now = dayjs()
  const updateDate = dayjs(createdAt)
  return now.diff(updateDate, 'day') >= DELETE_METRIC_THRESHOLD_IN_DAYS
}

export const isMetricEditable = (metric?: Metric) => {
  return !!(metric && !isCreationDateOlderThanTwoDays(metric.createdAt))
}

export const getShareValuesColumns = ({
  intl,
  onRemoveValue,
}: {
  intl: IntlShape
  onRemoveValue: (value: DataPoint) => void
}) => [
  {
    label: intl.formatMessage({ id: 'metrics.date' }),
    align: 'left',
    flex: 1.2,
    cellRenderer: ({ rowData }: { rowData: DataPoint }) => {
      return dayjs(rowData.date).format('DD MMM YYYY')
    },
  },
  {
    label: intl.formatMessage({ id: 'metrics.value' }),
    align: 'left',
    flex: 1.2,
    cellRenderer: ({ rowData }: { rowData: DataPoint }) => {
      return <ValueWrapper>{displayThousands(rowData.value)}</ValueWrapper>
    },
  },
  {
    align: 'left',
    flex: 0.5,
    cellRenderer: ({ rowData }: { rowData: DataPoint }) => {
      return (
        <TimesIconWrapper>
          <FontAwesomeIcon
            onClick={() => onRemoveValue(rowData)}
            icon={['fal', 'times']}
          />
        </TimesIconWrapper>
      )
    },
  },
]

export const getGrowthPercentage = (value: number, previousValue: number) => {
  if (previousValue === 0) {
    return 0
  }

  const percentage = ((value - previousValue) / previousValue) * 100
  const formattedPercentage = withDecimals(percentage, 0)

  return `${formattedPercentage}%`
}

export const getMetricsDataPointColumns = ({
  intl,
  currentGroupId,
  archiveDataPoints,
  unArchiveDataPoints,
  editDataPoint,
  isFounder,
  isClient,
  deleteDataPoints,
  isActiveTab,
  metric,
}: {
  intl: IntlShape
  currentGroupId: string
  archiveDataPoints: (dataPoints: DataPoint[]) => void
  unArchiveDataPoints: (dataPoints: DataPoint[]) => void
  editDataPoint: ({
    dataPoint,
    readOnlyMode,
  }: {
    readOnlyMode: boolean
    dataPoint: DataPoint
  }) => void
  deleteDataPoints: (dataPoints: DataPoint[]) => void
  isFounder: boolean
  isClient: boolean
  isActiveTab: boolean
  metric?: Metric
}) => {
  const isCalculatedMetric = metric?.source === MetricSources.System

  return [
    {
      label: intl.formatMessage({ id: 'showMetrics.date' }),
      align: 'left',
      flex: 0.6,
      sortKey: 'date',
      padding: '0 0.6rem 0 0.2rem',
      cellRenderer: ({ rowData }) => {
        const date = dayjs(rowData.date).format('MMM DD, YYYY')
        if (currentGroupId !== rowData?.groupId) {
          return (
            <>
              {isActiveTab && <TooltipGlobalStyles id={rowData.id} />}
              <Tooltip
                id={rowData.id}
                class="data-point-tooltip"
                place="left"
                event="mouseover mouseenter"
                eventOff="mouseleave mouseout scroll mousewheel blur"
                maxWidth="32.7rem"
                backgroundColor={
                  isCalculatedMetric
                    ? theme.colors.purple
                    : theme.colors.primaryBlue
                }
                parentTooltipStyles={{
                  transform: 'translate(320px, -50px)',
                }}
                text={
                  <FormattedMessage
                    values={{
                      br: <br />,
                      header: (
                        <b>
                          {intl.formatMessage({
                            id: isCalculatedMetric
                              ? 'metrics.valueAddedBySystem'
                              : 'metrics.valueAddedByFounderHeader',
                          })}
                        </b>
                      ),
                    }}
                    id={
                      isCalculatedMetric
                        ? 'metrics.valueAddedBySystemDesc'
                        : 'metrics.valueAddedByFounderDesc'
                    }
                  />
                }
              >
                <div data-iscapture="true">
                  <Triangle
                    color={isCalculatedMetric ? 'purple' : 'primaryBlue'}
                  />
                </div>
              </Tooltip>
              <DateWrapper>{date}</DateWrapper>
            </>
          )
        }
        return <DateContainer>{date}</DateContainer>
      },
    },
    {
      label: intl.formatMessage({ id: 'showMetrics.value' }),
      align: 'left',
      flex: 1,
      sortKey: 'value',
      padding: '0',
      cellRenderer: ({ rowData }) => {
        return (
          <FMVCell>
            <MetricValue
              color={color('darkGray')({ theme })}
              fontWeight={400}
              showInOneColumn={isFounder}
            >
              {rowData.value.toLocaleString()}
              {rowData.growth !== undefined && !rowData.archived && (
                <GrowthIndicatorWrapper>
                  <GrowthIndicator
                    direction={rowData.growth ? 'up' : 'down'}
                    bold
                    padding="0"
                  />
                  <GrowthValue
                    growth={!!rowData.growth}
                    value={getGrowthPercentage(
                      rowData.value,
                      rowData.value - rowData.growthValue!
                    )}
                    bold
                    padding="0 0 0 .1rem"
                  />
                </GrowthIndicatorWrapper>
              )}
            </MetricValue>
          </FMVCell>
        )
      },
    },
    {
      label: intl.formatMessage({ id: 'showMetrics.sharedWith' }),
      align: 'left',
      flex: 0.7,
      hideColumnDataCells: !isFounder,
      hidden: !isFounder,
      padding: '0 0.6rem 0 0',
      sortKey: 'sharedGroups.length',
      cellRenderer: ({ rowData }) => (
        <MetricDataPointSharedGroupsCell
          currentGroupId={currentGroupId}
          dataPoint={rowData}
        />
      ),
    },
    {
      sortDisabled: true,
      id: '9',
      flex: 0,
      cellRenderer: ({ rowData }) => {
        const readOnlyMode = !!rowData?.sharedGroups?.length

        const isDataPointFromFounder =
          currentGroupId !== rowData?.groupId && !isCalculatedMetric
        const canEditValue =
          !rowData.archived && isFounder && !isCalculatedMetric
        const canRemoveValue =
          !isFounder && !isDataPointFromFounder && !isCalculatedMetric
        const isFounderOrClient = isFounder || isClient

        const removeValueComponent = (
          <OptionsDropdown.Item
            label={<FormattedMessage id="metrics.removeValue" />}
            icon={['fal', 'trash-alt']}
            color="#E75767"
            onSelectOption={() => {
              deleteDataPoints([rowData])
            }}
          />
        )

        return (
          <FloatColumnWrapper id={rowData.id}>
            <OptionsDropdown id={rowData.id} topOffset={-8}>
              {canEditValue ? (
                <OptionsDropdown.Item
                  label={<FormattedMessage id="metrics.editValue" />}
                  icon={['fal', 'pen']}
                  onSelectOption={() => {
                    editDataPoint({
                      readOnlyMode,
                      dataPoint: rowData,
                    })
                  }}
                />
              ) : null}

              {canRemoveValue ? (
                <>
                  <ReadOnlyTooltip
                    key="editDataPoint"
                    id={`editDataPointDropdown_${rowData.id}`}
                    text={intl.formatMessage({
                      id: 'readOnlyMode.editMetricValues',
                    })}
                    place="left"
                    leftOffset={-10}
                  >
                    <OptionsDropdown.Item
                      label={<FormattedMessage id="metrics.editValue" />}
                      icon={['fal', 'pen']}
                      onSelectOption={() => {
                        editDataPoint({
                          readOnlyMode,
                          dataPoint: rowData,
                        })
                      }}
                    />
                  </ReadOnlyTooltip>
                  {removeValueComponent}
                </>
              ) : null}

              {!isFounder && isDataPointFromFounder
                ? removeValueComponent
                : null}

              {isFounderOrClient || isCalculatedMetric ? (
                <OptionsDropdown.Item
                  label={
                    rowData.archived ? (
                      <FormattedMessage id="metrics.moveToMainList" />
                    ) : (
                      <FormattedMessage id="metrics.archiveValue" />
                    )
                  }
                  icon={['fal', rowData.archived ? 'arrow-alt-up' : 'archive']}
                  onSelectOption={() => {
                    if (rowData.archived) {
                      unArchiveDataPoints([rowData])
                    } else {
                      archiveDataPoints([rowData])
                    }
                  }}
                />
              ) : null}
            </OptionsDropdown>
          </FloatColumnWrapper>
        )
      },
    },
  ] as Column<SelectableDataPoint>[]
}

const getDateFormatForFrequency = (frequency) => {
  if (frequency === MetricFrequency.WEEKLY) {
    return 'DD MMM YYYY'
  }
  return 'MMM YYYY'
}

export const sortMetricDataPoints = (dataPoints) =>
  sortBy(
    dataPoints,
    (m) => new Date(m.date),
    (m) => new Date(m.createdAt)
  )

export const getMetricChartData = <T,>(
  dataPoints: T[] = [],
  frequency?: MetricFrequency
): Array<{
  x: string
  y: number
}> => {
  return sortMetricDataPoints(dataPoints).map((dataPoint) => ({
    x: dayjs(dataPoint.date).format(getDateFormatForFrequency(frequency)),
    y: dataPoint.value,
  }))
}

export const groupMetricsByName = (metrics) => {
  return chain(metrics)
    .filter((metric) => metric.dataPoints?.length > 0)
    .groupBy((metric) => metric.name?.toLowerCase())
    .mapValues((metricsForName) => ({
      id: metricsForName[0].id,
      metric: metricsForName[0].name,
      data: metricsForName,
    }))
    .values()
    .value()
}

export const groupMilestonesByState = ({
  milestones = [],
  metricId,
}: {
  milestones?: Milestone[]
  metricId: string
}) => {
  const reachedMilestones: Milestone[] = []
  const expiredMilestones: Milestone[] = []
  const activeMilestones: Milestone[] = []
  const investorsMilestones: Milestone[] = []

  milestones.forEach((milestone) => {
    const milestoneIsExpired = new Date() >= new Date(milestone.date)

    if (milestoneIsExpired) {
      if (milestone.percentage >= 100) {
        reachedMilestones.push(milestone)
      } else {
        expiredMilestones.push(milestone)
      }
    } else {
      const isAnInvestorMilestone = milestone.metricId !== metricId
      if (isAnInvestorMilestone) {
        investorsMilestones.push(milestone)
      } else {
        activeMilestones.push(milestone)
      }
    }
  })

  return {
    activeMilestones,
    reachedMilestones,
    expiredMilestones,
    investorsMilestones,
  }
}

export const calculateDaysToNextUpdate = (frequency, nextDateToUpdate) => {
  if (!frequency || !nextDateToUpdate) {
    return Infinity
  }
  const now = dayjs()
  const limitDate = dayjs(nextDateToUpdate).endOf('day')
  const diffDays = dayjs(limitDate).diff(now, 'days', true)
  return diffDays
}

export const undoDeleteMilestone = ({ queryClient, metricId, milestone }) => {
  queryClient.setQueryData(
    metricsKeys.getMilestones(metricId),
    (milestones: Milestone[]) => {
      return [...milestones, milestone]
    }
  )
}

export const deleteMilestone = ({ queryClient, metricId, milestone }) => {
  queryClient.setQueryData(
    metricsKeys.getMilestones(metricId),
    (milestones: Milestone[]) => {
      return milestones.filter((m) => m.id !== milestone.id)
    }
  )
}

export const getActionDropdownOptions = (intl) => {
  return [
    { label: intl.messages['metrics.createMetric'], id: 'CREATE_METRIC' },
    {
      label: intl.messages['metrics.addToExistingMetric'],
      id: 'ADD_TO_EXISTING_METRIC',
    },
  ]
}

export const metricIsReceivingDataFromFounder = (metric) => {
  return (
    metric?.receiverMetricLink?.receiveData &&
    (metric?.receiverMetricLink?.state === LinkedMetricState.SHARE_ACCEPTED ||
      metric?.receiverMetricLink?.state === MetricStates.REQUEST_ACCEPTED)
  )
}

export const populateMetricGrowth = (dataPoints: SelectableDataPoint[]) => {
  return dataPoints.map<SelectableDataPoint>((dp, index) => {
    if (index === dataPoints.length - 1) {
      return {
        ...dp,
        growth: undefined,
        growthValue: undefined,
      }
    }

    const growthValue = dp.value - dataPoints[index + 1].value

    return growthValue === 0
      ? {
          ...dp,
          growth: undefined,
          growthValue: undefined,
        }
      : {
          ...dp,
          growth: growthValue > 0,
          growthValue,
        }
  })
}

export const populateDataPoints = (metricValues) => {
  const sortedDataPoints = sortMetricDataPoints(metricValues)
  return populateMetricGrowth(sortedDataPoints)
}

export const getIndexToInsertCompanyName = ({
  companyNames,
  companyNameToInsert,
  sortField,
  sortDirection,
}) => {
  const sortedWithNewItem = companyNames
    .concat(companyNameToInsert)
    .map((name) => name?.toLowerCase())
    .sort()

  if (sortField === MetricSortBy.NAME && sortDirection === SortDirection.DESC) {
    sortedWithNewItem.reverse()
  }
  return sortedWithNewItem.findIndex(
    (companyName) => companyName === companyNameToInsert.toLowerCase()
  )
}

export const getSharedMetricCompany = (sharedMetric) => {
  if (sharedMetric?.senderGroup?.type === GroupTypes.FUND_MANAGER) {
    const fundCompany = sharedMetric.senderGroup.companyData?.find(
      (company) => company.fund
    )
    return fundCompany
  }
  return sharedMetric?.senderGroup?.companyData?.[0]
}

export const shouldCompanySupportMetrics = (company, currentGroupId) => {
  return (
    company?.groupId !== currentGroupId && company?.group?.id !== currentGroupId
  )
}

export const shouldHoldingSupportMetrics = (holding, currentGroupId) => {
  return (
    holding?.groupId !== currentGroupId &&
    holding?.group?.id !== currentGroupId &&
    holding.holdingType === HoldingType.COMPANY
  )
}
