/* eslint-disable react/style-prop-object */
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import Input from 'ui/Input'
import Button from 'ui/Button'
import Separator from 'ui/Separator'
import SelectDatePicker from 'components/TimeSelect/SelectDatePicker'
import Dropdown from 'ui/Dropdown'
import BoxGroupsAndUsersChips from 'components/BoxGroupsAndUsersChips'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'
import DropdownEntity from 'components/GroupsUsersAddInput/DropdownEntity'
import { useGroupsQuery } from 'utils/hooks/queries/useGroupQuery'
import Drawer from 'components/Drawer'

import useAddMetricValue from './useAddMetricValue'
import { ShareWithWrapper, Form } from '../EditMetric/EditMetric.styles'

const AddMetricValue = ({ closeDrawer, isOpen }) => {
  const {
    metric,
    handleSubmit,
    register,
    errors,
    onDateChange,
    date,
    isFounder,
    handleAddGroup,
    handlePressEnter,
    dropdownRef,
    handleRemoveGroup,
    currentGroups = [],
    readOnlyMode,
    dataPoint,
    isEditing,
    loadGroups,
    founderUnverified,
  } = useAddMetricValue(closeDrawer)

  const intl = useIntl()

  const { data: fetchedSharedGroups } = useGroupsQuery(dataPoint?.sharedGroups)

  const displayGroups = useMemo(() => {
    const sharedGroups =
      fetchedSharedGroups?.map((group) => ({
        ...group,
        canDelete: false,
      })) ?? []

    const groups = currentGroups
      ? [...sharedGroups, ...currentGroups]
      : [...sharedGroups]

    return groups
  }, [currentGroups, fetchedSharedGroups])

  return (
    <Drawer
      isOpen={isOpen}
      headerTitle={`${
        isEditing
          ? intl.formatMessage({ id: 'metrics.editValue' })
          : intl.formatMessage({ id: 'metrics.addValue' })
      } - ${metric?.name}`}
      onCloseDrawer={closeDrawer}
    >
      <Form onSubmit={handleSubmit}>
        <Drawer.Content contentPadding="1.5rem 2.4rem">
          <Input
            type="number"
            name="metricValue"
            ref={register}
            error={errors.metricValue}
            label={intl.formatMessage({ id: 'metrics.metricValue' })}
            placeholder={intl.formatMessage({ id: 'metrics.addValue' })}
            readOnlyMode={readOnlyMode}
            readOnlyValue={dataPoint?.value.toString() ?? ''}
            step="any"
          />

          <Separator space="1.8rem" />

          <SelectDatePicker
            id="date"
            name="date"
            handleChange={onDateChange}
            placeholder={intl.formatMessage({ id: 'metrics.metricDate' })}
            label={intl.formatMessage({ id: 'metrics.metricValueDate' })}
            onlyMonthYearPicker={false}
            register={register}
            value={date}
            error={errors.date}
            dateFormat="dd MMM, yyyy"
            maxDate={new Date()}
            readOnlyMode={readOnlyMode}
          />

          <Separator space="1.8rem" />

          {isFounder && (
            <ShareWithWrapper>
              <ReadOnlyTooltip
                place="left"
                text={intl.formatMessage({ id: 'updates.readOnlyMetrics' })}
                disabled={founderUnverified}
                topOffset={-10}
              >
                {({ disabled }) => (
                  <Dropdown
                    async
                    ref={dropdownRef}
                    onPressEnter={handlePressEnter}
                    type="input"
                    icon={['far', 'plus']}
                    iconFontSize="1.8rem"
                    clearOnSelect
                    dropdownOptionPadding="0"
                    maxListHeight="30rem"
                    label={intl.formatMessage({ id: 'metrics.sharedWith' })}
                    formatSelectedOptionComponent={(option) => (
                      <DropdownEntity
                        entity={option.value}
                        isSelected={false}
                      />
                    )}
                    getOption={(entity) => ({
                      value: {
                        type: entity.type,
                        name: entity.name,
                        handle: entity.handle,
                        logo: entity.logo?.url,
                      },
                      name: entity.name,
                      id: entity.id,
                    })}
                    placeholder={intl.formatMessage({
                      id: 'metrics.sharedWithPlaceholder',
                    })}
                    loadOptions={loadGroups}
                    onSelectOption={handleAddGroup}
                    disabled={disabled}
                  />
                )}
              </ReadOnlyTooltip>
              <BoxGroupsAndUsersChips
                items={displayGroups?.map((item) => ({
                  ...item,
                  fromMetric: true,
                }))}
                handleDelete={handleRemoveGroup}
              />
            </ShareWithWrapper>
          )}
        </Drawer.Content>

        <Drawer.Footer>
          <Button secondary type="button" onClick={closeDrawer}>
            <FormattedMessage id="general.cancel" />
          </Button>

          <Button primary type="submit">
            <FormattedMessage id="general.save" />
          </Button>
        </Drawer.Footer>
      </Form>
    </Drawer>
  )
}

export default AddMetricValue
