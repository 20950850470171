import { useHistory } from 'react-router-dom'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { isArray } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { SidebarSection } from 'utils/functions/sideBar'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { setInvestmentNavigationOptionSelected } from 'reducers/breadcrumbSlice'

import {
  AngleIcon,
  Expandable,
  SideBarItemContainer,
  SidebarItemIcon,
  SidebarItemTitle,
  ItemWrapper,
  StyledIcon,
  ItemTitle,
} from './SideBarItem.styles'

interface SidebarItemPros {
  item: SidebarSection
  primarySidebarOpen: boolean
  toggleSideBar: () => void
  isEmbeddedItem?: boolean
  isSelected?: boolean
}

const SideBarItem: React.FC<SidebarItemPros> = ({
  item,
  primarySidebarOpen,
  toggleSideBar,
  isEmbeddedItem,
  isSelected,
}) => {
  const dispatch = useAppDispatch()

  const subSectionRouteIsActive = useMemo(
    () => !!item.subSections?.some((sub) => !!sub.isSelected),
    [item.subSections]
  )
  const { current: isAFontAwesomeIcon } = useRef(isArray(item.icon))

  const [isExpanded, setIsExpanded] = useState(subSectionRouteIsActive)

  const history = useHistory()
  const hasSubSections = !!item.subSections?.length

  useEffect(() => {
    setIsExpanded(subSectionRouteIsActive)
  }, [subSectionRouteIsActive])

  const onClickSidebarItem = () => {
    item.breadcrumbSetter?.()
    dispatch(setInvestmentNavigationOptionSelected(null))

    if (primarySidebarOpen && !hasSubSections) {
      toggleSideBar()
    }

    if (hasSubSections) {
      setIsExpanded((prev) => !prev)
      if (item.url && !isExpanded && !subSectionRouteIsActive) {
        history.push(item.url)
      }
    } else {
      history.push(item.url!)
    }
  }

  return (
    <div>
      <SideBarItemContainer
        $isSelected={item.isSelected || isSelected}
        isEmbeddedItem={isEmbeddedItem}
        onClick={onClickSidebarItem}
      >
        <ItemWrapper>
          <ItemTitle>
            {!!item.icon && (
              <>
                {isAFontAwesomeIcon && (
                  <SidebarItemIcon icon={item.icon as IconProp} />
                )}
                {!isAFontAwesomeIcon && <StyledIcon>{item.icon}</StyledIcon>}
              </>
            )}
            <SidebarItemTitle>{item.text}</SidebarItemTitle>
          </ItemTitle>
          {item.rightComponent || null}
        </ItemWrapper>
        {hasSubSections && (
          <AngleIcon
            size="3x"
            icon={['fal', 'angle-down']}
            isExpanded={isExpanded}
          />
        )}
      </SideBarItemContainer>

      {hasSubSections && (
        <Expandable isExpanded={isExpanded}>
          {item.subSections?.map((subSection) => (
            <SideBarItem
              key={subSection.text}
              item={subSection}
              primarySidebarOpen={primarySidebarOpen}
              toggleSideBar={toggleSideBar}
              isEmbeddedItem
            />
          ))}
        </Expandable>
      )}
    </div>
  )
}

export default SideBarItem
