import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { useGroupsQuery } from 'utils/hooks/queries/useGroupQuery'
import { getCurrentGroupId } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { maxSize } from 'utils/constants/breakpoint'
import { LinkedMetricState } from 'utils/types/metricsV2'
import { useHistory } from 'react-router-dom'
import { useContainerWidth } from './useContainerWidth'
import { useDisplayMode } from './useDisplayMode'
import { useMetricData } from './useMetricData'

export const useProfileMetric = ({
  initialMetricData,
  linkTo,
  defaultDisplayMode,
  includeDataPoints = true,
}) => {
  const { metric, isLoading, groupedMilestones, dataPoints } = useMetricData({
    initialMetricData,
    includeDataPoints,
  })
  const { displayMode, onToggleDisplayMode, onSelectDisplayMode } =
    useDisplayMode(defaultDisplayMode)
  const { width, dataPointsContainerRef } = useContainerWidth()
  const history = useHistory()

  const { matches: isMobile } = useMediaQuery(maxSize.sm)
  const currentGroupId = useAppSelector(getCurrentGroupId)

  const { data: sharedGroups } = useGroupsQuery(
    metric?.senderLinks
      ?.filter(
        (link) =>
          link.state !== LinkedMetricState.UNSHARED &&
          link.state !== LinkedMetricState.SHARE_DENIED &&
          link.state !== LinkedMetricState.REQUEST_DENIED &&
          link.senderGroupId === currentGroupId
      )
      .map((link) => link.receiverGroupId!)
  )

  const showFounderDataPulse =
    metric?.receiverMetricLink?.receiveData &&
    (metric?.receiverMetricLink?.state === LinkedMetricState.SHARE_ACCEPTED ||
      metric?.receiverMetricLink?.state === LinkedMetricState.REQUEST_ACCEPTED)

  const redirectToLogs = () => {
    history.push(linkTo, { activeTab: 2 })
  }

  return {
    metric,
    isLoading,
    showFounderDataPulse,
    sharedGroups: sharedGroups ?? [],
    isMobile,
    dataPoints,
    dataPointsContainerRef,
    width,
    activeMilestones: groupedMilestones.activeMilestones,
    displayMode,
    onToggleDisplayMode,
    onSelectDisplayMode,
    redirectToLogs,
  }
}
