export default {
  title: 'Files & Content',
  finished: 'Finished',
  error: 'Error',
  canceled: 'Upload canceled',
  secondsLeft: 'Less than {count} secs. left',
  lessThanMinute: 'Less than 1 min. left',
  lessThanNMinutes: '{count} mins. left',
  editColumns: 'Edit Columns',
  loadingFiles: 'Loading files...',
  generalInformation: 'General Information',
  download: 'Download',
  preview: 'Preview',
  groupManaged: 'Group Managed',
  groupManagedTooltip:
    'If you set a file as group managed, \nall group members can see and use\n the content as their own.',
  delete: 'Delete',
  search: 'Search...',
  onlyUnreconciled: 'Unreconciled files & contents',
  showPublicFiles: 'Include publicly shared files & contents',
  unreconciledFiles: 'Unreconciled Files',
  addedSharedBy: 'Added / Shared by',
  addedBySwitch: 'Added by',
  addedBy: 'Added by: {userName}',
  sharedByGroup: 'Shared by: {groupName}',
  sharedBy: 'Shared by',
  groupMembers: 'Group members',
  recentlyUsedGroups: 'Recently used groups',
  searchGroupMembers: 'Search group members...',
  searchGroups: 'Search groups...',
  others: 'Others',
  clear: 'Clear',
  deleteFile: 'Delete file',
  fileDeletedSuccessfully: 'File deleted successfully',
  deleteFileConfirmation: 'Are you sure you want to delete "{fileName}"?',
  cannotUndoDelete: 'Be aware that you cannot undo this action.',
  errorDeletingFile:
    'An unexpected error occurred while deleting the file, please try again later',
  errorChangingGroupOwned:
    'An unexpected error occurred while changing the file, please try again later',
  orderBy: {
    name: 'Name',
    firstDate: 'First file added',
    lastDate: 'Last file added',
    largestFileFirst: 'Largest file first',
    smallestFileFirst: 'Smallest file first',
  },
  unreconciledFile: `Unreconciled file {notUsedFile}\nAdded by {userName}`,
  unreconciledFileWithoutOwner: `Unreconciled file {notUsedFile}`,
  notUsedFile: '(Not used file)',
  fileSharedBy: 'File shared by {groupName}',
  errorToLoad:
    'The file failed to load. Reload the page, or please come back later.',
  errorToDownload:
    'An error occurred when trying to download the content, please try again later',
  details: {
    unreconciled: 'unreconciled',
    title: 'File details',
    size: 'Size',
    date: 'Date',
    fileFormat: 'File format',
    sharedBy: 'Shared by',
    about: 'About',
    addedBy: 'Added by',
    includeInUpdateTitle: 'included in the following updates',
    showMore: 'Show {count} more',
    showLess: 'Show less',
    noPreviewAvailable: 'There is no preview available for "{name}".',
  },
  dropFiles: 'Drop files to upload them to Clockwork Universe',
  uploadingFiles: 'Uploading files...',
  date: 'Date: ',
  from: 'From: ',
  to: 'To: ',
  properties: 'Properties',
  attachments: 'Attachments',
  emailDetails: 'Email details',
  connectedAccount: 'Connected account',
  deleteModal: {
    file: {
      title: 'Delete file',
      confirmation: 'Are you sure you want to delete "{fileName}"?',
      groupMembersWillLoseAccess:
        "All members of this group won't be able to access this file anymore.",
    },
    content: {
      title: 'Delete',
      confirmation: 'Are you sure you want to delete "{fileName}"?',
      groupMembersWillLoseAccess:
        "All members of this group won't be able to access this content anymore.",
      deleteRelatedFiles:
        'Delete <tooltip>{count} related files</tooltip> with this email content.',
      filesCannotBeDeleted:
        '{files} cannot be deleted because it is being used on an update. All other associated {contentOr} files will be deleted.',
      contentOr: 'content or',
    },
  },
  notUsedFiles: 'Not used files & contents',
  publiclySharedFiles: 'Publicly Shared Files',
  UpdateType: 'Update Type',
  filesTypes: 'File Types',
  filter: 'Filter',
  filters: 'Filters',
  clearAll: 'Clear all',
  oneFilter: '1 Filter',
  appliedFilters: '{filtersCount} applied {filters}',
  clearFilter: 'Clear filter',
  gridViewSelector: {
    medium: 'Medium',
    large: 'Large',
  },
  filesList: {
    name: 'Name',
    size: 'Size',
    date: 'Date',
    time: 'Time',
    tags: 'Tags',
    about: 'About',
    sector: 'Sectors & Industries',
    addedSharedBy: 'Added/Shared by',
    sharedBy: 'Shared by',
  },
  fileExtensionLabels: {
    images: 'Images',
    pdf: 'PDFs',
    documents: 'Documents',
    spreadsheets: 'Spreadsheets',
    presentations: 'Presentations',
    videos: 'Videos',
    audios: 'Audios',
    emailsContents: 'Emails Contents',
    others: 'Others',
  },

  tableColumns: {
    totalNumberOfFiles: 'Total # of files',
    totalNumberOfPDFs: 'Total # of PDFs',
    totalNumberOfImages: 'Total # of Images',
    totalNumberOfDocuments: 'Total # of documents',
    totalNumberOfSpreadsheets: 'Total # of Spreadsheets',
    totalNumberOfPresentations: 'Total # of Presentations',
    totalNumberOfAudios: 'Total # of audios',
    totalNumberOfVideos: 'Total # of videos',
    totalNumberOfCompressedFiles: 'Total # of compressed files',
  },
}
