import React, { useCallback, useState } from 'react'
import isEqual from 'lodash/isEqual'
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  LabelList,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import theme, { DataVisualizationColor } from 'utils/theme'
import Card from 'components/Card'
import Separator from 'ui/Separator'
import { Nullable } from 'utils/types/common'
import { color } from 'utils/functions/colors'
import { displayThousands } from 'utils/functions/number'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'
import { IndexMetric } from 'utils/types/metricsV2'
import { formattedDate } from 'utils/functions/date'
import { ReactComponent as Traveller } from 'assets/images/chart-traveller.svg'

import {
  BarChartConfig,
  ChartMetric,
  LineChartConfig,
  CHART_MODE,
  ChartPeriods,
} from './types'
import ComposedChart from '../ComposedChart'

import GeneratingMetrics from './components/GeneratingMetrics'
import { Graph } from './components/Tooltip/Tooltip'
import CustomBar from './components/CustomBar'
import PeriodSelector from './components/PeriodSelector'
import TooltipContent from './components/Tooltip'
import DateRange from './components/DateRange'
import useMultiViewChart from './useMultiViewChart'
import MetricsList from './components/MetricsList'
import ChartTypeSelector from './components/ChartTypeSelector'
import QuarterAndYearAxis from './components/QuarterAndYearAxis'
import useChartConfig, { InitialChartConfig } from './useChartConfig'

import * as Styles from './MultiViewChart.styles'

export interface Config {
  lineChartsConfig?: Record<string, LineChartConfig>
  barChartsConfig?: Record<string, BarChartConfig>
}

interface MultiViewChartProps {
  chartMetrics: ChartMetric[]
  colors: Record<string, DataVisualizationColor>
  hasLegend?: boolean
  hasMetricsList?: boolean
  isLoading: boolean
  isRemoveMetricDisabled?: boolean
  isResizable?: boolean
  originalMetrics: IndexMetric[]
  initialConfig?: InitialChartConfig
  onRemoveMetric?: (metric: IndexMetric) => void
  onClearAll?: () => void
}

const MultiViewChart: React.FC<MultiViewChartProps> = ({
  chartMetrics,
  colors,
  hasLegend = false,
  hasMetricsList = true,
  isLoading,
  isRemoveMetricDisabled,
  isResizable = false,
  originalMetrics,
  initialConfig,
  onRemoveMetric,
  onClearAll,
}) => {
  const [activeBar, setActiveBar] = useState<Nullable<Graph>>(null)
  const { matches: isSmallScreen } = useMediaQuery(Media.MAX_XL)

  const {
    refreshChartKey,
    groupingDataCriteria,
    dateRange,
    metrics,
    initialIndexes,
    brushRangeIndexes,
    cardPadding,
    xDateAxisInterval,
    onChangeGroupingDataCriteria,
    onChangePeriod,
    updateRangeOnYearlyDayChange,
    updateRangeOnQuarterlyDayChange,
    getArrayOfQuartersBasedOnDate,
    getArrayOfYearsBasedOnDate,
  } = useMultiViewChart(chartMetrics)

  const {
    chartConfig,
    chartModeButtonValue,
    config,
    isSomeMetricVisible,
    yAxisConfig,
    onChangeAllChartsMode,
    onChangeChartMode,
    onChangeChartModeButtonValue,
    onChangeVisibilityMode,
  } = useChartConfig({
    initialConfig,
    chartMetrics,
    colors,
    groupingDataCriteria,
  })

  const tickStyles = {
    fill: color('lightGray')({ theme }),
    fontSize: theme.fontSizes.small,
  }

  const renderCustomizedLabel = ({ value, x, y }) => {
    if (!value) return null

    const formattedValue = `${value.toFixed(2)}x`
    return (
      <text
        x={x}
        y={y}
        dy={12}
        dx={20}
        fill={theme.colors.softLightGray}
        fontSize={12}
        fontWeight={600}
        fontFamily="Lato"
        textAnchor="middle"
      >
        {formattedValue}
      </text>
    )
  }

  const onActiveHandler = useCallback(
    (active: Nullable<Graph>) => {
      if (!isEqual(activeBar, active)) {
        setActiveBar(active)
      }
    },
    [activeBar]
  )

  if (!metrics.length) return null

  const DateTick = ({ x, y, payload }) => {
    const date = formattedDate(payload.value, 'MMM. DD,')
    const year = formattedDate(payload.value, 'YYYY')

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill={color('lightGray')({ theme })}
          fontSize={theme.fontSizes.small}
          fontFamily="Lato"
        >
          {date}
        </text>
        <text
          x={0}
          y={0}
          dy={30}
          textAnchor="middle"
          fill={color('lightGray')({ theme })}
          fontSize={theme.fontSizes.small}
          fontFamily="Lato"
        >
          {year}
        </text>
      </g>
    )
  }

  return (
    <Styles.ClassSelectorWrapper>
      <Card isExpandable={false} padding={cardPadding}>
        <Card.Body>
          {isLoading ? (
            <GeneratingMetrics />
          ) : (
            <Styles.Wrapper>
              {hasMetricsList && (
                <MetricsList
                  chartMode={chartConfig}
                  colors={colors}
                  metrics={originalMetrics}
                  isRemoveMetricDisabled={isRemoveMetricDisabled}
                  onChangeChartMode={onChangeChartMode}
                  onChangeVisibilityMode={onChangeVisibilityMode}
                  onClearAll={onClearAll}
                  onRemove={onRemoveMetric}
                />
              )}
              <Styles.ChartWrapper>
                <Styles.Header>
                  <PeriodSelector
                    activeCriteria={groupingDataCriteria}
                    onChangeCriteria={onChangeGroupingDataCriteria}
                  />
                  <Styles.Period>
                    <DateRange
                      startDate={dateRange.start}
                      endDate={dateRange.end}
                      getArrayOfYearsBasedOnDate={getArrayOfYearsBasedOnDate}
                      getArrayOfQuartersBasedOnDate={
                        getArrayOfQuartersBasedOnDate
                      }
                      periodConfig={groupingDataCriteria}
                      updateRangeOnYearlyDayChange={
                        updateRangeOnYearlyDayChange
                      }
                      updateRangeOnQuarterlyDayChange={
                        updateRangeOnQuarterlyDayChange
                      }
                    />
                    <ChartTypeSelector
                      mode={chartModeButtonValue}
                      onChange={(selectedMode: CHART_MODE) => {
                        onChangeChartModeButtonValue(selectedMode)
                        onChangeAllChartsMode(selectedMode)
                      }}
                    />
                  </Styles.Period>
                </Styles.Header>

                <ComposedChart
                  data={metrics}
                  isResizable={isResizable}
                  key={refreshChartKey}
                >
                  <CartesianGrid
                    stroke={color('veryLightGray')({ theme })}
                    vertical={false}
                  />
                  {Object.keys(config.barChartsConfig || {}).map((key) => (
                    <Bar
                      dataKey={key}
                      key={key}
                      {...config.barChartsConfig?.[key]}
                      unit="k"
                      shape={(barProps) => (
                        <CustomBar {...barProps} activeBar={activeBar} />
                      )}
                    />
                  ))}

                  {Object.keys(config.lineChartsConfig || {}).map((key) => (
                    <Line
                      dataKey={key}
                      key={key}
                      {...config.lineChartsConfig?.[key]}
                      unit="x"
                      label={<LabelList content={renderCustomizedLabel} />}
                      dot={false}
                      connectNulls
                    />
                  ))}

                  {yAxisConfig.map((axis) => (
                    <YAxis
                      tickLine={false}
                      axisLine={false}
                      yAxisId={axis.id}
                      ticks={axis.ticks}
                      orientation={axis.placement}
                      domain={axis.domain}
                      hide={!axis.isVisible}
                      tick={tickStyles}
                      width={isSomeMetricVisible ? 60 : 0}
                      tickFormatter={displayThousands}
                    />
                  ))}

                  <XAxis
                    axisLine={false}
                    dataKey="quarter"
                    dy={0}
                    height={50}
                    tick={(props) => (
                      <QuarterAndYearAxis
                        {...props}
                        metrics={metrics}
                        brushRange={brushRangeIndexes ?? initialIndexes}
                      />
                    )}
                    tickFormatter={(value) => value && `Q${value}`}
                    tickLine={false}
                    xAxisId={0}
                    padding={{ left: 40, right: 30 }}
                    hide={
                      !isSomeMetricVisible ||
                      groupingDataCriteria !== ChartPeriods.QUARTERLY
                    }
                    interval={0}
                  />

                  <XAxis
                    allowDuplicatedCategory={false}
                    axisLine={false}
                    dataKey="year"
                    dy={10}
                    height={50}
                    tick={tickStyles}
                    tickLine={false}
                    type="category"
                    xAxisId={1}
                    padding={{ left: 40, right: 20 }}
                    hide={
                      !isSomeMetricVisible ||
                      groupingDataCriteria !== ChartPeriods.YEARLY
                    }
                    interval={xDateAxisInterval}
                  />

                  <XAxis
                    axisLine={false}
                    dataKey="date"
                    dy={10}
                    height={50}
                    tick={DateTick}
                    tickLine={false}
                    xAxisId={2}
                    padding={{ left: 40, right: 40 }}
                    hide={
                      !isSomeMetricVisible ||
                      groupingDataCriteria !== ChartPeriods.ALL
                    }
                    interval={xDateAxisInterval}
                  />

                  <Tooltip
                    content={
                      <TooltipContent
                        metrics={originalMetrics}
                        onActive={onActiveHandler}
                      />
                    }
                    wrapperStyle={{
                      borderRadius: '0.8rem',
                      outline: 'none',
                    }}
                  />

                  {hasLegend && (
                    <Legend
                      verticalAlign="top"
                      align={isSmallScreen ? 'left' : 'right'}
                      height={isSmallScreen ? 80 : 40}
                      iconSize={10}
                      formatter={(value: string) => (
                        <Styles.CustomLegend>{value}</Styles.CustomLegend>
                      )}
                      wrapperStyle={{
                        width: 'fit-content',
                        top: isSmallScreen ? 20 : -28,
                      }}
                    />
                  )}

                  <Brush
                    padding={{ left: 6, right: 6, bottom: 1 }}
                    alwaysShowText
                    height={29}
                    stroke="#E9E9E9"
                    strokeWidth={1}
                    strokeOpacity={1}
                    traveller={<Traveller />}
                    travellerWidth={10}
                    className="brush"
                    opacity={0.5}
                    onChange={onChangePeriod as any}
                    startIndex={
                      brushRangeIndexes?.startIndex ?? initialIndexes.startIndex
                    }
                    endIndex={
                      brushRangeIndexes?.endIndex ?? initialIndexes.endIndex
                    }
                  >
                    {chartModeButtonValue === CHART_MODE.BAR ? (
                      <BarChart>
                        {Object.keys(config.barChartsConfig || {}).map(
                          (key) => (
                            <Bar
                              dataKey={key}
                              key={key}
                              {...config.barChartsConfig?.[key]}
                              unit="k"
                              shape={(barProps) => (
                                <CustomBar
                                  {...barProps}
                                  activeBar={activeBar}
                                />
                              )}
                              opacity={0.5}
                              fillOpacity={0.5}
                              fill="gray"
                              isAnimationActive={false}
                            />
                          )
                        )}
                      </BarChart>
                    ) : (
                      <LineChart>
                        {Object.keys(config.lineChartsConfig || {}).map(
                          (key) => (
                            <Line
                              dataKey={key}
                              key={key}
                              {...config.lineChartsConfig?.[key]}
                              unit="x"
                              stroke="#CBCACA"
                              fill="#CBCACA"
                              strokeWidth={1}
                              dot={false}
                              isAnimationActive={false}
                              connectNulls
                            />
                          )
                        )}
                      </LineChart>
                    )}
                  </Brush>
                </ComposedChart>
              </Styles.ChartWrapper>
            </Styles.Wrapper>
          )}
        </Card.Body>
      </Card>
      <Separator space="2.4rem" />
    </Styles.ClassSelectorWrapper>
  )
}

export default MultiViewChart
