import React, { useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import InputLabel from 'ui/InputLabel'
import InputError from 'ui/InputError'
import { isEnterKeyCode, isEscCode } from 'utils/functions/keyboardEvents'
import { getNumber } from 'ui/Dropdown/helpers'
import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'

import {
  InputContainer,
  StyledInput,
  InputWrapper,
  ErrorIcon,
  IconsContainer,
  InputIcon,
  Prefix,
  Value,
  SubLabel,
  LabelContainer,
} from './Input.styles'

const Input = React.forwardRef(
  (
    {
      label,
      isOptional,
      optionalLabel,
      error,
      large,
      icon,
      onPressEnter,
      disabled,
      prefix,
      readOnlyMode,
      readOnlyValue,
      showErrorMessage,
      iconFontSize,
      sensitive,
      className,
      iconColor,
      backgroundColor,
      onClickIcon,
      withCounter,
      counterSubtractNumber,
      customMaxCounterCharacters,
      simulateIconSpace,
      counterStyle,
      extraPaddingRight,
      inputCustomStyles,
      isCleanable,
      onPressEscape,
      type,
      ...props
    },
    ref
  ) => {
    const hasError = !!error.message
    const inputRef = useRef(null)
    const [contentVisible, setContentVisible] = useState(!sensitive)

    const handleClear = () => {
      inputRef.current.value = ''
      inputRef.current.focus()

      if (props.onChange) {
        props.onChange({ target: { value: '' } })
      }
    }

    const onKeyDown = useCallback(
      (event) => {
        if (isEnterKeyCode(event) && onPressEnter) {
          event.preventDefault()
          event.stopPropagation()
          onPressEnter(event)
        } else if (isEscCode(event)) {
          onPressEscape?.(event)
        }
      },
      [onPressEnter, onPressEscape]
    )

    const showError = hasError && showErrorMessage
    return (
      <InputContainer disabled={disabled} className={className}>
        <LabelContainer>
          {label && (
            <InputLabel
              marginLeft={large ? '1.6rem' : ''}
              isOptional={isOptional}
              optionalLabel={optionalLabel}
              large={large}
              customStyles={inputCustomStyles}
            >
              {label}
            </InputLabel>
          )}

          {props.subLabel && <SubLabel>{props.subLabel}</SubLabel>}
        </LabelContainer>

        <InputWrapper type={error.type || 'error'} large={large}>
          {prefix && <Prefix>{prefix}</Prefix>}
          {!readOnlyMode ? (
            <>
              <StyledInput
                data-testid={`input-${props.id}`}
                ref={ref || inputRef}
                hasError={hasError}
                error={error}
                large={large}
                onKeyDown={onKeyDown}
                icon={icon}
                disabled={disabled}
                prefix={prefix}
                novalidate
                backgroundColor={backgroundColor}
                placeholderOpacity={props.placeholderOpacity}
                width={props.width}
                height={props.height}
                {...props}
                type={sensitive && !contentVisible ? 'password' : type}
                simulateIconSpace={simulateIconSpace}
                extraPaddingRight={extraPaddingRight}
              />

              {withCounter &&
                getNumber(
                  counterSubtractNumber,
                  customMaxCounterCharacters || MAX_COUNTER_CHARACTERS,
                  counterStyle
                )}
            </>
          ) : (
            <Value>{readOnlyValue}</Value>
          )}
          {(hasError || icon || sensitive) && (
            <IconsContainer large={large}>
              {hasError && (
                <ErrorIcon
                  large={large}
                  hasCounter={withCounter}
                  icon={['fal', 'exclamation-triangle']}
                />
              )}
              {sensitive && (
                <InputIcon
                  id={`icon-sensitive-${props.id}`}
                  icon={contentVisible ? ['fal', 'eye-slash'] : ['fal', 'eye']}
                  fontSize="1.6rem"
                  onClick={() => setContentVisible((prev) => !prev)}
                  color={iconColor}
                />
              )}
              {isCleanable && inputRef.current?.value ? (
                <InputIcon
                  icon={['fal', 'times']}
                  fontSize={iconFontSize}
                  onClick={handleClear}
                />
              ) : (
                !sensitive &&
                icon && (
                  <InputIcon
                    fontSize={iconFontSize}
                    id={`icon-${props.id}`}
                    icon={icon}
                    onClick={(e) => {
                      onPressEnter?.(e)
                      onClickIcon?.(e)
                    }}
                    color={iconColor}
                  />
                )
              )}
            </IconsContainer>
          )}
        </InputWrapper>

        {showError && (
          <InputError type={error.type || 'error'} large={large}>
            {error?.message}
          </InputError>
        )}
      </InputContainer>
    )
  }
)

Input.propTypes = {
  large: PropTypes.bool,
  error: PropTypes.shape({
    type: PropTypes.oneOf(['error', 'warning']),
    message: PropTypes.string,
  }),
  label: PropTypes.string,
  subLabel: PropTypes.string,
  isOptional: PropTypes.bool,
  disabled: PropTypes.bool,
  optionalLabel: PropTypes.string,
  icon: PropTypes.array,
  onPressEnter: PropTypes.func,
  onPressEscape: PropTypes.func,
  onClickIcon: PropTypes.func,
  prefix: PropTypes.node,
  id: PropTypes.string,
  readOnlyMode: PropTypes.bool,
  showErrorMessage: PropTypes.bool,
  readOnlyValue: PropTypes.string,
  iconFontSize: PropTypes.string,
  sensitive: PropTypes.bool,
  className: PropTypes.string,
  iconColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  placeholderOpacity: PropTypes.number,
  backgroundColor: PropTypes.string,
  withCounter: PropTypes.bool,
  counterSubtractNumber: PropTypes.number,
  customMaxCounterCharacters: PropTypes.number,
  simulateIconSpace: PropTypes.bool,
  counterStyle: PropTypes.object,
  extraPaddingRight: PropTypes.string,
  inputCustomStyles: PropTypes.object,
  isCleanable: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
}

Input.defaultProps = {
  id: '',
  showErrorMessage: true,
  large: false,
  disabled: false,
  error: {},
  label: '',
  subLabel: '',
  isOptional: false,
  optionalLabel: '(optional)',
  icon: null,
  prefix: null,
  onPressEnter: null,
  onClickIcon: null,
  readOnlyMode: false,
  readOnlyValue: '',
  iconFontSize: '',
  sensitive: false,
  className: '',
  iconColor: '',
  backgroundColor: '',
  withCounter: false,
  counterSubtractNumber: 0,
  simulateIconSpace: false,
  counterStyle: {},
  extraPaddingRight: '',
  inputCustomStyles: {},
  isCleanable: false,
}

export default Input
