import { useQueryClient, type InfiniteData } from '@tanstack/react-query'
import { createContext, useCallback, useContext, useMemo, useRef } from 'react'

import GroupService from 'api/GroupService'
import { CHART_MODE } from 'components/Charts/MultiViewChart/types'
import { getCurrentGroupId, getUserId } from 'selectors/auth'
import { DELETED_METRIC_EVENT } from 'utils/constants/events'
import { useSelectedMetricsQuery } from 'utils/hooks/queries/useMetrics'
import { useGetUserGroup } from 'utils/hooks/queries/useUserGroupQuery'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useEventListener } from 'utils/hooks/useEventListener'
import { groupKeys } from 'utils/queries/groups'
import { metricsKeys } from 'utils/queries/metrics'
import { ReactFCWithChildren } from 'utils/types/common'
import { IndexMetric } from 'utils/types/metricsV2'
import { UserGroup } from 'utils/types/user'
import useMetricsColor from './useMetricsColor'
import { MAX_SANDBOX_METRICS, MetricSandboxContext } from './useMetricsSandbox'
import { useSelectedMetrics } from './useSelectedMetrics'

export type SelectedMetricsContextType = {
  metrics: IndexMetric[]
  onRemoveMetrics: (metrics: IndexMetric[]) => void
  onAddMetric: (metric: IndexMetric) => void
  onRemoveMetric: (metric: IndexMetric) => void
  onClearMetrics: () => void
}

const SelectedMetricsContext = createContext<SelectedMetricsContextType>({
  metrics: [],
  onRemoveMetrics: () => {},
  onAddMetric: () => {},
  onRemoveMetric: () => {},
  onClearMetrics: () => {},
})

export const useSelectedMetricsContext = () =>
  useContext(SelectedMetricsContext)

export const SelectedMetricsContextProvider: ReactFCWithChildren = ({
  children,
}) => {
  const queryClient = useQueryClient()
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const currentUserId = useAppSelector(getUserId)
  const defaultChartMode = CHART_MODE.LINE

  /* This ref is used to sync the selected metrics between the requests */
  const sourceOfTruthRef = useRef<Record<string, Date>>({})

  const { data: userGroup } = useGetUserGroup({
    includeSelectedMetrics: true,
    onSuccess: (newUserGroup: UserGroup) => {
      sourceOfTruthRef.current =
        newUserGroup.settings.metrics.selectedMetrics.reduce<
          Record<string, Date>
        >((acc, metricId) => ({ ...acc, [metricId]: new Date() }), {}) ?? {}
    },
  })

  const { metrics } = useSelectedMetricsQuery(
    {},
    {
      enabled: !!(Object.keys(sourceOfTruthRef.current) ?? []).length,
    },
    sourceOfTruthRef.current
  )

  const allMetrics = useMemo(() => {
    const currentAllMetrics = metrics?.pages.flatMap((page) => page.data) ?? []

    return Object.keys(sourceOfTruthRef.current)
      .map((metricId) =>
        currentAllMetrics.find((metric) => metric.id === metricId)
      )
      .filter((metric) => metric) as IndexMetric[]
  }, [metrics?.pages])

  const {
    selectedMetrics,
    isMetricInSandbox,
    changeMetricVisibility,
    getMetricColor,
    isMetricVisible,
  } = useMetricsColor({
    metrics: allMetrics,
  })

  const userGroupMetricsKey = useMemo(() => {
    return groupKeys.userGroup(currentUserId, currentGroupId, true)
  }, [currentGroupId, currentUserId])

  const selectedMetricsKey = useMemo(() => {
    return metricsKeys.getMetrics({ selected: true })
  }, [])

  const updateUserGroupQueryData = useCallback(
    (newSelectedMetrics: string[]) => {
      queryClient.setQueryData(userGroupMetricsKey, {
        ...userGroup,
        settings: {
          ...userGroup?.settings,
          metrics: {
            ...userGroup?.settings.metrics,
            selectedMetrics: newSelectedMetrics,
          },
        },
      })
    },
    [queryClient, userGroup, userGroupMetricsKey]
  )

  const removeSelectedMetricsFromQueryData = useCallback(
    (metricsToRemove: IndexMetric[]) => {
      queryClient.setQueryData<InfiniteData<{ data: IndexMetric[] }>>(
        selectedMetricsKey,
        (oldData) => {
          if (oldData) {
            const newMetrics = oldData.pages.map((page) => {
              const newData = page.data.filter((m) => {
                return !metricsToRemove.find((metric) => metric.id === m.id)
              })

              return {
                ...page,
                data: newData,
              }
            })

            return {
              ...oldData,
              pages: newMetrics,
            }
          }

          return oldData
        }
      )
    },
    [queryClient, selectedMetricsKey]
  )

  const updateSelectedMetricsQueryData = useCallback(
    (metric: IndexMetric) => {
      const isAddingNewMetric = sourceOfTruthRef.current[metric.id]

      queryClient.setQueryData<InfiniteData<{ data: IndexMetric[] }>>(
        selectedMetricsKey,
        (oldData) => {
          if (oldData) {
            let existsMetricOnPages = false

            const newMetrics = oldData.pages.map((page) => {
              const newData = page.data.filter((m) => {
                if (m.id === metric.id) {
                  existsMetricOnPages = true
                  return isAddingNewMetric
                }

                return true
              })

              return {
                ...page,
                data: newData,
              }
            })

            if (!existsMetricOnPages && isAddingNewMetric) {
              newMetrics[newMetrics.length - 1].data = [
                ...newMetrics[newMetrics.length - 1].data,
                metric,
              ]
            }

            return {
              ...oldData,
              pages: newMetrics,
            }
          }

          return oldData
        }
      )
    },
    [queryClient, selectedMetricsKey]
  )

  const updateUserGroupSelectedMetrics = useCallback(
    (newSelectedMetrics: string[]) => {
      if (userGroup) {
        GroupService.updateGroupUser(
          userGroup.id,
          {
            setting: {
              metrics: {
                selectedMetrics: newSelectedMetrics,
              },
            },
          },
          false
        )
      }
    },
    [userGroup]
  )

  const updateStates = useCallback(
    (newSelectedMetrics: string[], metric?: IndexMetric) => {
      if (userGroup) {
        updateUserGroupQueryData(newSelectedMetrics)
        updateUserGroupSelectedMetrics(newSelectedMetrics)
        if (metric) {
          updateSelectedMetricsQueryData(metric)
        }
      }
    },
    [
      updateSelectedMetricsQueryData,
      updateUserGroupQueryData,
      updateUserGroupSelectedMetrics,
      userGroup,
    ]
  )

  const onAddMetric = useCallback(
    (metric: IndexMetric) => {
      sourceOfTruthRef.current[metric.id] = new Date()
      const newSelectedMetrics = Object.keys(sourceOfTruthRef.current)
      updateStates(newSelectedMetrics, metric)
      changeMetricVisibility(metric.id, true)
    },
    [changeMetricVisibility, updateStates]
  )

  const onRemoveMetric = useCallback(
    (metric: IndexMetric) => {
      delete sourceOfTruthRef.current[metric.id]
      const newSelectedMetrics = Object.keys(sourceOfTruthRef.current)
      updateStates(newSelectedMetrics, metric)
    },
    [updateStates]
  )

  const onRemoveMetrics = useCallback(
    (metricsToRemove: IndexMetric[]) => {
      metricsToRemove.forEach((metric) => {
        delete sourceOfTruthRef.current[metric.id]
      })
      const newSelectedMetrics = Object.keys(sourceOfTruthRef.current)
      updateStates(newSelectedMetrics)
      removeSelectedMetricsFromQueryData(metricsToRemove)
    },
    [removeSelectedMetricsFromQueryData, updateStates]
  )

  const onClearMetrics = useCallback(() => {
    sourceOfTruthRef.current = {}
    updateStates([])
    removeSelectedMetricsFromQueryData(allMetrics)
  }, [allMetrics, removeSelectedMetricsFromQueryData, updateStates])

  const toggleMetricInSandbox = useCallback(
    (metric: IndexMetric) => {
      if (isMetricInSandbox(metric)) {
        onRemoveMetric(metric)
      } else {
        onAddMetric(metric)
      }
    },
    [isMetricInSandbox, onAddMetric, onRemoveMetric]
  )

  const isAddMetricToSandboxDisabled = useCallback(() => {
    return allMetrics.length >= MAX_SANDBOX_METRICS
  }, [allMetrics.length])

  const { hasMetrics, hasSelectedMetrics, isLoading, metricsCards } =
    useSelectedMetrics()

  useEventListener(
    DELETED_METRIC_EVENT,
    ({ metric }: { metric: IndexMetric }) => {
      if (metric) {
        toggleMetricInSandbox(metric)
      }
    }
  )

  const value = useMemo(
    () => ({
      isMetricInSandbox,
      changeMetricVisibility,
      getMetricColor,
      isMetricVisible,
      selectedMetrics,
      metrics: allMetrics,
      toggleMetricInSandbox,
      clearSandbox: onClearMetrics,
      removeMetrics: onRemoveMetrics,
      isAddMetricToSandboxDisabled,

      hasMetrics,
      hasSelectedMetrics,
      isLoading,
      metricsCards,
      defaultChartMode,
    }),
    [
      isMetricInSandbox,
      changeMetricVisibility,
      getMetricColor,
      isMetricVisible,
      selectedMetrics,
      allMetrics,
      toggleMetricInSandbox,
      onClearMetrics,
      onRemoveMetrics,
      isAddMetricToSandboxDisabled,
      hasMetrics,
      hasSelectedMetrics,
      isLoading,
      metricsCards,
      defaultChartMode,
    ]
  )

  return (
    <MetricSandboxContext.Provider value={value}>
      {children}
    </MetricSandboxContext.Provider>
  )
}
