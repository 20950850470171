import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import { MetricFrequency } from 'utils/constants/metrics'
import useMetricGroups from 'utils/hooks/useMetricGroups'
import { getCurrentGroupId } from 'selectors/auth'
import { isMetricEditable } from 'utils/functions/metrics'
import useMetricQuery from 'utils/hooks/useMetricQuery'
import { IFormContext } from 'components/Form/hooks'
import { getEditFounderMetricSchema } from 'utils/schemas/metrics'
import { useEditFounderMetricQuery } from 'utils/hooks/queries/useMetrics'
import { useIntl } from 'react-intl'
import { searchMatchesGroup } from 'utils/functions/updates'
import { LinkedMetricState, Metric } from 'utils/types/metricsV2'
import { useGroupsQuery } from 'utils/hooks/queries/useGroupQuery'
import { Group } from 'utils/types/user'

type MyRefType = {
  clear: () => void
  close: () => void
}

type SharedGroup = {
  id: string
  name: string
  canDelete: boolean
}

export interface EditFounderMetricFormType extends IFormContext {
  name: string
  frequency: string
  sharedGroups: SharedGroup[]
}

type GetInitialValuesProps = {
  metric?: Metric
  currentGroupId: string
  sharedGroups?: Group[]
}

const getInitialValues = ({
  metric,
  currentGroupId,
  sharedGroups,
}: GetInitialValuesProps) => {
  const defaultValues = {
    name: '',
    frequency: MetricFrequency.NONE,
    sharedGroups: [] as SharedGroup[],
  }

  defaultValues.name = metric?.name ?? ''
  defaultValues.frequency = metric?.frequency ?? MetricFrequency.NONE
  defaultValues.sharedGroups =
    sharedGroups
      ?.filter((group) => group.id !== currentGroupId)
      ?.map((group) => ({
        ...group,
        canDelete: true,
      })) ?? []

  return defaultValues
}

interface Props {
  closeDrawer: () => void
}

const useEditFounderMetric = ({ closeDrawer }: Props) => {
  const firstRender = useRef<boolean>(true)
  const dropdownRef = useRef<MyRefType>(null)
  const intl = useIntl()
  const { id: metricId } = useParams<{ id: string }>()
  const { metric, isLoading } = useMetricQuery({
    metricId,
  })

  const currentGroupId = useAppSelector(getCurrentGroupId)
  const metricEditable = isMetricEditable(metric)

  const clearDropdown = () => {
    dropdownRef.current?.clear()
    dropdownRef.current?.close()
  }

  const {
    setGroups,
    handleAddGroup,
    handleRemoveGroup,
    loadGroups,
    searchText: groupSearchText,
    groupIdsToAdd,
    groupIdsToRemove,
    currentGroups,
  } = useMetricGroups()

  const { data: receiverGroups } = useGroupsQuery(
    metric?.senderLinks
      ?.filter(
        (link) =>
          link.state !== LinkedMetricState.UNSHARED &&
          link.state !== LinkedMetricState.SHARE_DENIED &&
          link.state !== LinkedMetricState.REQUEST_DENIED &&
          link.receiverGroupId
      )
      .map((link) => link.receiverGroupId!),
    {
      onSuccess: (fetchedReceiverGroups: Group[]) => {
        setGroups(
          fetchedReceiverGroups
            .filter((group) => group.id !== currentGroupId)
            ?.map((metricGroup) => ({
              ...metricGroup,
              canDelete: true,
            }))
        )
      },
    }
  )

  const initialValues = getInitialValues({
    metric,
    currentGroupId,
    sharedGroups: receiverGroups,
  })

  const validationSchema = getEditFounderMetricSchema(intl)

  const { mutate: editMetricMutation, isLoading: isEditingMetric } =
    useEditFounderMetricQuery({
      metric,
      groupIdsToAdd,
      groupIdsToRemove,
      closeDrawer,
    })

  const onSelectGroup = (...params) => {
    handleAddGroup(...params)
    setTimeout(clearDropdown)
  }

  const handlePressEnter = async (inputValue, dropdownOptions) => {
    if (
      dropdownOptions.length === 1 &&
      searchMatchesGroup(dropdownOptions[0], inputValue)
    ) {
      onSelectGroup(null, null, dropdownOptions[0])
    }
  }

  useEffect(() => {
    if (!firstRender.current) return
    setGroups(initialValues?.sharedGroups)
    firstRender.current = false
  }, [initialValues.sharedGroups, setGroups])

  return {
    initialValues,
    metric,
    validationSchema,
    isLoading,
    editMetricMutation,
    isEditingMetric,
    metricEditable,
    currentGroups,
    groupSearchText,
    onSelectGroup,
    handlePressEnter,
    handleRemoveGroup,
    loadGroups,
  }
}

export default useEditFounderMetric
