import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'

import Tooltip from 'components/Tooltip'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import theme from 'utils/theme'
import { BaseMetric } from 'utils/types/metricsV2'

import {
  MAX_SANDBOX_METRICS,
  useMetricsSandbox,
} from '../SelectedMetrics/useMetricsSandbox'
import * as Styles from './ShowInChart.styles'

interface ShowInSandboxProps {
  showLabel?: boolean
  metric: BaseMetric
  className?: string
  isMetricVisible?: boolean
  onClick: () => void
}

const ShowInChartContent = ({
  metric,
  className,
  showLabel = true,
  isMetricVisible = false,
  onClick,
}: ShowInSandboxProps) => {
  const intl = useIntl()
  const { matches: isMobile } = useMediaQuery(maxSize.xsm)

  const {
    isMetricInSandbox,
    isAddMetricToSandboxDisabled,
    isMetricVisible: isMetricVisibleFn,
  } = useMetricsSandbox()

  const isMetricVisibleInChart = isMetricVisible || isMetricVisibleFn(metric.id)

  return (
    <Styles.ShowInChart
      disabled={!isMetricInSandbox(metric) && isAddMetricToSandboxDisabled()}
      className={className}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={isMetricVisibleInChart ? ['fal', 'eye'] : ['fal', 'eye-slash']}
        color={isMetricVisibleInChart ? undefined : theme.colors.lightGray}
      />

      {showLabel && !isMobile && (
        <Styles.ShowInChartLabel
          isMetricVisibleInChart={isMetricVisibleInChart}
        >
          {intl.formatMessage({ id: 'metrics.visibleOnChart' })}
        </Styles.ShowInChartLabel>
      )}
    </Styles.ShowInChart>
  )
}

const ShowInChart = (props: ShowInSandboxProps) => {
  const { metric } = props
  const intl = useIntl()
  const { isAddMetricToSandboxDisabled, isMetricInSandbox } =
    useMetricsSandbox()

  if (isAddMetricToSandboxDisabled() && !isMetricInSandbox(metric)) {
    return (
      <Tooltip
        text={intl.formatMessage(
          {
            id: 'metrics.showInSandboxDisabled',
          },
          {
            count: MAX_SANDBOX_METRICS,
          }
        )}
        backgroundColor="white"
        color={color('darkGray')({ theme })}
        place="bottom"
        tooltipStyle={{ maxWidth: '28.5rem' }}
        parentTooltipStyles={{
          border: `1px solid ${theme.colors.veryLightGray}`,
          padding: '0.6rem 0.8rem !important',
          boxShadow: 'box-shadow: 0px 0px 15px 0px rgba(16, 21, 39, 0.10)',
        }}
      >
        <ShowInChartContent {...props} />
      </Tooltip>
    )
  }

  return <ShowInChartContent {...props} />
}

export default ShowInChart
