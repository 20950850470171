import type { Compatible, EventHandlers, Uncertain } from '@silevis/reactgrid'
import { keyCodes } from '@silevis/reactgrid'
import debounce from 'lodash/debounce'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { scrollDropdownOptionIntoView } from 'utils/functions/dropdown'
import {
  isBackspaceKeyCode,
  isDownCode,
  isEnterKeyCode,
  isEscCode,
  isLeftCode,
  isRightCode,
  isUpCode,
} from 'utils/functions/keyboardEvents'
import { useEventListener } from 'utils/hooks/useEventListener'
import useOutsideClick from 'utils/hooks/useOutsideClick'
import { Nullable } from 'utils/types/common'
import { MetricsSpreadsheetEvents } from '../../useUpdateMetricsGrid'
import {
  Metric,
  MetricCell,
  UNKNOWN_METRIC_ID,
} from '../../../Spreadsheet/CellTemplates/MetricCellTemplate'

/*
 * Pessimistic amount of time (ms) it takes for the React Grid event handler
 * to handle the click event on the cell and is free to handle the synthetic
 * 'Enter' keyboard event
 */
const KEYBOARD_EVENT_DELAY = 150

/*
 * Height of the blue borders around the cell when it's being focused + a bit of margin.
 * Used to calculate the dropdown options top position
 */
const OPTIONS_OFFSET = 7

export interface MetricsSpreadsheetDropdownProps {
  cell: MetricCell
  isInEditMode: boolean
  initialMetrics: Metric[]
  loadMetrics: (query: string) => Promise<Metric[]>
  onCellChanged: (cell: Compatible<MetricCell>, commit: boolean) => void
  getCompatibleCell: (
    uncertainCell: Uncertain<MetricCell>
  ) => Compatible<MetricCell>
  eventHandler: MutableRefObject<EventHandlers | undefined>
}

export const useMetricsSpreadsheetDropdown = ({
  cell,
  isInEditMode,
  initialMetrics,
  loadMetrics,
  onCellChanged,
  getCompatibleCell,
  eventHandler,
}: MetricsSpreadsheetDropdownProps) => {
  const [isOpen, setIsOpen] = useState(isInEditMode)
  const [metrics, setMetrics] = useState<Metric[]>(initialMetrics)
  const [loadingMetrics, setLoadingMetrics] = useState(cell.loading)
  const [selectedMetric, setSelectedMetric] = useState<Metric | undefined>(
    cell.metric
  )
  const [highlightedMetric, setHighlightedMetric] = useState<
    Metric | undefined
  >(cell.metric)
  const [search, setSearch] = useState(
    cell.initialChar || (selectedMetric?.toCreate ? selectedMetric.name : '')
  )
  const [inputHeight, setInputHeight] = useState(0)
  const dropdownRef = useRef<Nullable<HTMLDivElement>>(null)
  const optionsRef = useRef(null)

  const toCreate = selectedMetric && selectedMetric.toCreate
  const fetchingMetric = selectedMetric && selectedMetric.fetchingMetric

  useLayoutEffect(() => {
    if (dropdownRef.current) {
      setInputHeight(dropdownRef.current.offsetHeight + OPTIONS_OFFSET)
    }
  }, [])

  useOutsideClick(dropdownRef, () => {
    onCellChanged(getCompatibleCell(cell), true)
    setIsOpen(false)
  })

  const fetchMetrics = useCallback(
    async (searchValue) => {
      setLoadingMetrics(true)
      const newMetrics = await loadMetrics(searchValue)

      if (!Array.isArray(newMetrics)) {
        throw new Error('loadMetrics prop must resolve to an array')
      }

      setMetrics(newMetrics)
      setLoadingMetrics(false)
    },
    [loadMetrics]
  )

  const debouncedHandleOnChange = useMemo(
    () => debounce(fetchMetrics, 300),
    [fetchMetrics]
  )

  const onSearchChange = useCallback(
    (event) => {
      const { value: searchValue } = event.target
      setSearch(searchValue)
      debouncedHandleOnChange(searchValue)
    },
    [debouncedHandleOnChange]
  )

  useEffect(() => {
    if (cell.initialChar) {
      debouncedHandleOnChange(cell.initialChar)
    }
  }, [cell.initialChar, debouncedHandleOnChange])

  useEffect(() => {
    setSelectedMetric(cell.metric)
    setHighlightedMetric(cell.metric)
  }, [cell.metric])

  useEventListener(
    MetricsSpreadsheetEvents.INITIAL_METRICS_FETCHED,
    (fetchedMetrics) => {
      setMetrics(fetchedMetrics)
      setLoadingMetrics(false)
    }
  )

  const stopPropagation = useCallback((e) => e.stopPropagation(), [])

  const onSelectOption = useCallback(
    (option?: Metric) => {
      if (option) {
        onCellChanged(
          getCompatibleCell({
            ...cell,
            metric: option,
          }),
          true
        )
      } else {
        onCellChanged(getCompatibleCell(cell), true)
      }

      setIsOpen(false)
    },
    [cell, onCellChanged, getCompatibleCell]
  )

  const onClickAddOption = useCallback(async () => {
    onCellChanged(
      getCompatibleCell({
        ...cell,
        metric: { id: UNKNOWN_METRIC_ID, name: search, toCreate: true },
      }),
      true
    )
  }, [search, onCellChanged, cell, getCompatibleCell])

  const handleDownCode = useCallback(
    (currentHighlightedIndex: number) => {
      if (!isOpen) {
        setIsOpen(true)
        return metrics[0]
      }

      if (currentHighlightedIndex + 1 < metrics.length) {
        return metrics[currentHighlightedIndex + 1]
      }

      return undefined
    },
    [isOpen, metrics]
  )

  const handleUpCode = useCallback(
    (currentHighlightedIndex: number) => {
      if (currentHighlightedIndex > 0) {
        return metrics[currentHighlightedIndex - 1]
      }

      return undefined
    },
    [metrics]
  )

  const getNextSelectedMetric = useCallback(
    (event) => {
      const currentHighlightedIndex = metrics.findIndex(
        (metric) => metric.id === highlightedMetric?.id
      )

      if (isDownCode(event)) {
        return handleDownCode(currentHighlightedIndex)
      }

      if (isUpCode(event)) {
        return handleUpCode(currentHighlightedIndex)
      }

      return undefined
    },
    [metrics, highlightedMetric, handleDownCode, handleUpCode]
  )

  const onKeyDown = useCallback(
    (event) => {
      if (isEscCode(event)) {
        event.preventDefault()
        event.stopPropagation()
        setIsOpen(false)
      } else if (isEnterKeyCode(event)) {
        event.preventDefault()
        event.stopPropagation()

        if (metrics.length === 0) {
          onSelectOption()
        } else {
          onSelectOption(highlightedMetric)
        }
      } else if (isDownCode(event) || isUpCode(event)) {
        event.preventDefault()
        event.stopPropagation()
        const nextSelectedOption = getNextSelectedMetric(event)

        if (nextSelectedOption) {
          scrollDropdownOptionIntoView(nextSelectedOption, optionsRef)
          setHighlightedMetric(nextSelectedOption)
        }
      }
    },
    [getNextSelectedMetric, highlightedMetric, onSelectOption, metrics]
  )

  const onInputKeyDown = useCallback((event) => {
    if (isLeftCode(event) || isRightCode(event) || isBackspaceKeyCode(event)) {
      event.stopPropagation()
    }
  }, [])

  /**
   * Sends a synthetic event to React Grid to simulate the Enter key press
   * so that the cell opens the dropdown
   */
  const onArrowClick = useCallback(() => {
    setTimeout(() => {
      const syntheticEvent = new KeyboardEvent('keydown', {
        keyCode: keyCodes.ENTER,
      })
      eventHandler.current?.keyDownHandler(syntheticEvent as any)
    }, KEYBOARD_EVENT_DELAY)
  }, [eventHandler])

  const forceAutoFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.target.focus()
    },
    []
  )

  return {
    selectedMetric,
    isOpen,
    toCreate,
    fetchingMetric,
    dropdownRef,
    inputHeight,
    optionsRef,
    stopPropagation,
    onKeyDown,
    search,
    onSearchChange,
    onInputKeyDown,
    onClickAddOption,
    loadingMetrics,
    metrics,
    highlightedMetric,
    onSelectOption,
    onArrowClick,
    forceAutoFocus,
  }
}
