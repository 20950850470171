import type { Compatible, EventHandlers, Uncertain } from '@silevis/reactgrid'
import { keyCodes } from '@silevis/reactgrid'
import debounce from 'lodash/debounce'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { scrollDropdownOptionIntoView } from 'utils/functions/dropdown'
import {
  isBackspaceKeyCode,
  isDownCode,
  isEnterKeyCode,
  isEscCode,
  isLeftCode,
  isRightCode,
  isUpCode,
} from 'utils/functions/keyboardEvents'
import { useEventListener } from 'utils/hooks/useEventListener'
import useOutsideClick from 'utils/hooks/useOutsideClick'
import { Nullable } from 'utils/types/common'
import { INITIAL_HOLDINGS_FETCHED } from 'components/TransactionsSpeadsheetImportModal/components/useTransactionsSpreadsheet'
import {
  HoldingCell,
  HoldingInfo,
  UnknownHoldingData,
} from '../CustomHoldingCellTemplate'

/*
 * Pessimistic amount of time (ms) it takes for the React Grid event handler
 * to handle the click event on the cell and is free to handle the synthetic
 * 'Enter' keyboard event
 */
const KEYBOARD_EVENT_DELAY = 150

/*
 * Height of the blue borders around the cell when it's being focused + a bit of margin.
 * Used to calculate the dropdown options top position
 */
const OPTIONS_OFFSET = 7

export interface SpreadsheetHoldingsDropdownProps {
  cell: HoldingCell
  isInEditMode: boolean
  initialHoldings: HoldingInfo[]
  loadHoldings: (query: string) => Promise<HoldingInfo[]>
  onCellChanged: (cell: Compatible<HoldingCell>, commit: boolean) => void
  getCompatibleCell: (
    uncertainCell: Uncertain<HoldingCell>
  ) => Compatible<HoldingCell>
  eventHandler: MutableRefObject<EventHandlers | undefined>
}

export const useSpreadsheetHoldingsDropdown = ({
  cell,
  isInEditMode,
  initialHoldings,
  loadHoldings,
  onCellChanged,
  getCompatibleCell,
  eventHandler,
}: SpreadsheetHoldingsDropdownProps) => {
  const [isOpen, setIsOpen] = useState(isInEditMode)
  const [holdings, setHoldings] = useState<HoldingInfo[]>(initialHoldings)
  const [loadingHoldings, setLoadingHoldings] = useState(cell.loading)
  const [selectedHolding, setSelectedHolding] = useState<
    HoldingInfo | undefined
  >(cell.holding)
  const [highlightedHoldings, setHighlightedHoldings] = useState<
    HoldingInfo | undefined
  >(cell.holding)
  const [search, setSearch] = useState(cell.initialChar)
  const [inputHeight, setInputHeight] = useState(0)
  const dropdownRef = useRef<Nullable<HTMLDivElement>>(null)
  const optionsRef = useRef(null)
  const fetchingHolding =
    selectedHolding && (selectedHolding as UnknownHoldingData).fetchingHolding
  const isInvalid = !!cell.error

  useLayoutEffect(() => {
    if (dropdownRef.current) {
      setInputHeight(dropdownRef.current.offsetHeight + OPTIONS_OFFSET)
    }
  }, [])

  useOutsideClick(dropdownRef, () => {
    onCellChanged(getCompatibleCell(cell), true)
    setIsOpen(false)
  })

  const fetchHoldings = useCallback(
    async (searchValue) => {
      setLoadingHoldings(true)
      const newHoldings = await loadHoldings(searchValue)

      if (!Array.isArray(newHoldings)) {
        throw new Error('loadHoldings prop must resolve to an array')
      }

      setHoldings(newHoldings)
      setLoadingHoldings(false)
    },
    [loadHoldings]
  )

  const debouncedHandleOnChange = useMemo(
    () => debounce(fetchHoldings, 300),
    [fetchHoldings]
  )

  const onSearchChange = useCallback(
    (event) => {
      const { value: searchValue } = event.target
      setSearch(searchValue)
      debouncedHandleOnChange(searchValue)
    },
    [debouncedHandleOnChange]
  )

  useEffect(() => {
    if (cell.initialChar) {
      debouncedHandleOnChange(cell.initialChar)
    }
  }, [cell.initialChar, debouncedHandleOnChange])

  useEffect(() => {
    setSelectedHolding(cell.holding)
    setHighlightedHoldings(cell.holding)
  }, [cell.holding])

  useEventListener(INITIAL_HOLDINGS_FETCHED, (fetchedHoldings) => {
    setLoadingHoldings(false)
    setHoldings(fetchedHoldings)
  })

  const stopPropagation = useCallback((e) => e.stopPropagation(), [])

  const onSelectOption = useCallback(
    (option?: HoldingInfo) => {
      if (option) {
        onCellChanged(
          getCompatibleCell({
            ...cell,
            holding: option,
          }),
          true
        )
      } else {
        onCellChanged(getCompatibleCell(cell), true)
      }

      setIsOpen(false)
    },
    [cell, onCellChanged, getCompatibleCell]
  )

  const handleDownCode = useCallback(
    (currentHighlightedIndex: number) => {
      if (!isOpen) {
        setIsOpen(true)
        return holdings[0]
      }

      if (currentHighlightedIndex + 1 < holdings.length) {
        return holdings[currentHighlightedIndex + 1]
      }

      return undefined
    },
    [isOpen, holdings]
  )

  const handleUpCode = useCallback(
    (currentHighlightedIndex: number) => {
      if (currentHighlightedIndex > 0) {
        return holdings[currentHighlightedIndex - 1]
      }

      return undefined
    },
    [holdings]
  )

  const getNextSelectedHolding = useCallback(
    (event) => {
      const currentHighlightedIndex = holdings.findIndex(
        (holding) => holding.id === highlightedHoldings?.id
      )

      if (isDownCode(event)) {
        return handleDownCode(currentHighlightedIndex)
      }

      if (isUpCode(event)) {
        return handleUpCode(currentHighlightedIndex)
      }

      return undefined
    },
    [holdings, highlightedHoldings, handleDownCode, handleUpCode]
  )

  const onKeyDown = useCallback(
    (event) => {
      if (isEscCode(event)) {
        event.preventDefault()
        event.stopPropagation()
        setIsOpen(false)
      } else if (isEnterKeyCode(event)) {
        event.preventDefault()
        event.stopPropagation()

        if (holdings.length === 0) {
          onSelectOption()
        } else {
          onSelectOption(highlightedHoldings)
        }
      } else if (isDownCode(event) || isUpCode(event)) {
        event.preventDefault()
        event.stopPropagation()
        const nextSelectedOption = getNextSelectedHolding(event)

        if (nextSelectedOption) {
          scrollDropdownOptionIntoView(nextSelectedOption, optionsRef)
          setHighlightedHoldings(nextSelectedOption)
        }
      }
    },
    [getNextSelectedHolding, highlightedHoldings, onSelectOption, holdings]
  )

  const onInputKeyDown = useCallback((event) => {
    if (isLeftCode(event) || isRightCode(event) || isBackspaceKeyCode(event)) {
      event.stopPropagation()
    }
  }, [])

  /**
   * Sends a synthetic event to React Grid to simulate the Enter key press
   * so that the cell opens the dropdown
   */
  const onArrowClick = useCallback(() => {
    setTimeout(() => {
      const syntheticEvent = new KeyboardEvent('keydown', {
        keyCode: keyCodes.ENTER,
      })
      eventHandler.current?.keyDownHandler(syntheticEvent as any)
    }, KEYBOARD_EVENT_DELAY)
  }, [eventHandler])

  const forceAutoFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.target.focus()
    },
    []
  )

  return {
    selectedHolding,
    isOpen,
    fetchingHolding,
    isInvalid,
    dropdownRef,
    inputHeight,
    optionsRef,
    stopPropagation,
    onKeyDown,
    search,
    onSearchChange,
    onInputKeyDown,
    loadingHoldings,
    holdings,
    highlightedHoldings,
    onSelectOption,
    onArrowClick,
    forceAutoFocus,
  }
}
