import { FormattedMessage } from 'react-intl'
import Card from 'components/Card'
import MetricHoldingAvatar from 'containers/Metrics/MetricHoldingAvatar'
import { IndexMetric } from 'utils/types/metricsV2'
import { maxSize } from 'utils/constants/breakpoint'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import MetricChart from 'components/ProfileMetrics/components/MetricChart'
import { DisplayMode } from 'utils/constants/metrics'
import BulkImportMetricValuesModal from 'containers/Metrics/BulkImportMetricValuesModal'
import MetricName from 'components/Charts/MultiViewChart/components/MetricName'
import MetricDataPoints from 'components/ProfileMetrics/components/MetricDataPoints/MetricDataPoints'
import { useProfileMetrics } from 'components/ProfileMetrics/useProfileMetrics'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getFounderCompany } from 'selectors/company'
import { useMetricData } from 'components/ProfileMetrics/components/ProfileMetric/useMetricData'
import { useDisplayMode } from 'components/ProfileMetrics/components/ProfileMetric/useDisplayMode'
import { useContainerWidth } from 'components/ProfileMetrics/components/ProfileMetric/useContainerWidth'
import { useHistory } from 'react-router-dom'
import * as Styles from './MetricCards.styles'
import SelectedMetricActions from './SelectedMetricActions'

interface SelectedHoldingMetricsCardProps {
  metric: IndexMetric
}

export const SelectedMetricCard = ({
  metric,
}: SelectedHoldingMetricsCardProps) => {
  const currentCompany = useAppSelector(getFounderCompany)
  const { matches: isMobile } = useMediaQuery(maxSize.xss)
  const history = useHistory()

  const {
    onRemoveMetric,
    onAddNewValue,
    onEditMetric,
    onSetMilestone,
    onEditMilestone,
    onViewMetricDetail,
    onHideImportCsvModal,
    onImportCsv,
    exportMetric,
    metricsRoute,
    metricBulkImportModal,
  } = useProfileMetrics({
    companyInfo: currentCompany,
  })

  const linkTo = `${metricsRoute}/${metric.id}`

  const {
    dataPoints,
    groupedMilestones: { activeMilestones },
  } = useMetricData({
    initialMetricData: metric,
    includeDataPoints: true,
  })

  const { displayMode, onSelectDisplayMode } = useDisplayMode(DisplayMode.CHART)
  const { width, dataPointsContainerRef } = useContainerWidth()

  const redirectToLogs = () => {
    history.push(linkTo, { activeTab: 2 })
  }

  return (
    <>
      <Card
        isExpandable={false}
        padding={isMobile ? '1.6rem' : '1.6rem 2.4rem'}
      >
        <Card.Header>
          <Styles.Title>
            <MetricHoldingAvatar
              metricSource={metric.source}
              subjectType={metric.subject.type}
              subjectId={metric.subject.id}
              size="default"
            />

            <MetricName
              onNameClick={() => onViewMetricDetail(metric?.id)}
              metric={metric}
              customStyle={{
                cursor: 'pointer',
              }}
              size="1.6rem"
            />
          </Styles.Title>

          <SelectedMetricActions
            metric={metric}
            displayMode={displayMode}
            activeMilestones={activeMilestones}
            onRemoveMetric={onRemoveMetric}
            onViewMetricDetail={onViewMetricDetail}
            onAddNewValue={onAddNewValue}
            onEditMetric={onEditMetric}
            onSetMilestone={onSetMilestone}
            onEditMilestone={onEditMilestone}
            onImportCsv={() => onImportCsv(metric)}
            exportMetric={exportMetric}
            redirectToLogs={redirectToLogs}
            onSelectDisplayMode={onSelectDisplayMode}
          />
        </Card.Header>

        {!dataPoints.length ? (
          <Styles.EmptyState>
            <FormattedMessage id="metrics.noMetricValues" />
          </Styles.EmptyState>
        ) : (
          <Card.Body ref={dataPointsContainerRef} bodyMargin="0">
            {!isMobile && displayMode === DisplayMode.VALUE && (
              <Styles.DataPointsContainer>
                {!!dataPoints.length && !!width && (
                  <MetricDataPoints dataPoints={dataPoints} width={width} />
                )}
              </Styles.DataPointsContainer>
            )}

            {!isMobile && displayMode === DisplayMode.CHART && (
              <Styles.ChartContainer>
                {!!dataPoints.length && <MetricChart dataPoints={dataPoints} />}
              </Styles.ChartContainer>
            )}
          </Card.Body>
        )}
      </Card>

      {metricBulkImportModal.metric && (
        <BulkImportMetricValuesModal
          show={metricBulkImportModal.show}
          metric={metricBulkImportModal.metric}
          onHide={onHideImportCsvModal}
        />
      )}
    </>
  )
}
