import { useMemo } from 'react'
import { SortDirection } from 'utils/constants'
import { IndexMetric } from 'utils/types/metricsV2'
import {
  getAnnualChartMetrics,
  getDateChartMetrics,
  getGroupedDataPoints,
  getQuarterlyChartMetrics,
} from '../MultiViewChart/utils/data'
import { sortByDate } from '../MultiViewChart/utils'
import { ChartMetric } from '../MultiViewChart/types'

interface MetricsData {
  annualMetrics: ChartMetric[]
  quarterlyMetrics: ChartMetric[]
  dateMetrics: ChartMetric[]
}

const useMetricsData = (allMetrics: IndexMetric[]): MetricsData => {
  return useMemo(() => {
    const { dataPointsByYear, dataPointsByQuarter, dataPointsByDate } =
      getGroupedDataPoints(allMetrics)

    const annualMetrics: ChartMetric[] = getAnnualChartMetrics(
      dataPointsByYear
    ).sort((metric1, metric2) =>
      sortByDate(metric1, metric2, SortDirection.ASC)
    )

    const quarterlyMetrics: ChartMetric[] = getQuarterlyChartMetrics(
      dataPointsByQuarter
    ).sort((metric1, metric2) =>
      sortByDate(metric1, metric2, SortDirection.ASC)
    )

    const dateMetrics: ChartMetric[] = getDateChartMetrics(
      dataPointsByDate
    ).sort((metric1, metric2) =>
      sortByDate(metric1, metric2, SortDirection.ASC)
    )

    return {
      annualMetrics,
      quarterlyMetrics,
      dateMetrics,
    }
  }, [allMetrics])
}

export default useMetricsData
