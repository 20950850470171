import { useCallback } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Nullable } from 'utils/types/common'
import { useAddToExistingMetricQuery } from 'utils/hooks/queries/useMetrics'
import NumbersService from 'api/NumbersService'
import { LinkedMetric } from 'utils/types/metricsV2'
import useMetricQuery from 'utils/hooks/useMetricQuery'

export interface AddToExistingMetricFormValues {
  id: Nullable<string>
  includeHistory: boolean
}

interface Props {
  closeDrawer: () => void
}

const useAddToExistingMetric = ({ closeDrawer }: Props) => {
  const { metricId: linkedMetricId } = useParams<{ metricId: string }>()
  const location = useLocation<{ metric: LinkedMetric }>()
  const linkedMetric = location?.state?.metric

  const initialValues: AddToExistingMetricFormValues = {
    id: null,
    includeHistory: true,
  }
  const { metric } = useMetricQuery({
    metricId: linkedMetric?.senderMetricId ?? '',
  })

  const loadMetrics = useCallback(
    async (metricName: string) => {
      if (metric) {
        const metrics =
          await NumbersService.getMetricsByCompanyIdThatAreNotReceivingData({
            metricName,
            companyId: metric.subject.id,
          })

        return metrics?.map((currMetric) => ({
          id: currMetric.id,
          label: currMetric.name,
        }))
      }

      return []
    },
    [metric]
  )

  const { mutate: addToExistingMetricMutation } = useAddToExistingMetricQuery({
    closeDrawer,
    linkedMetricId,
  })

  return {
    metric,
    initialValues,
    loadMetrics,
    addToExistingMetricMutation,
  }
}

export default useAddToExistingMetric
