/* eslint-disable class-methods-use-this */
import type { CellChange, Column, Row } from '@silevis/reactgrid'
import {
  Metric,
  MetricCell,
} from 'components/Spreadsheet/CellTemplates/MetricCellTemplate'
import {
  DEFAULT_CELL_HEIGHT,
  SpreadsheetBorders,
} from 'components/Spreadsheet/utils'
import {
  HoldingCell,
  UNKNOWN_HOLDING_ID,
} from 'components/TransactionsSpeadsheetImportModal/components/CustomHoldingCellTemplate'
import {
  CustomCell,
  CustomDateCell,
  CustomNumberCell,
  RowNumberCell,
} from 'components/Spreadsheet/types'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { Holding } from 'utils/types/company'
import {
  CELL_WIDTH,
  CellType,
  GridType,
  MetricsSpreadsheetEvents,
  MetricForHoldingFetched,
} from './useUpdateMetricsGrid'
import { getCellHeightFromAmountOfLines } from './utils/CellHeightCalculator'
import { MetricsSpreadsheetMode } from './MetricsSpreadsheetLogic'

const MODAL_EXTRA_ROWS = 4
const MODAL_EXTRA_COLUMNS = 2
const ROW_NUMBER_CELL_WIDTH = 42

export class MetricsSpreadsheetBuilder {
  private metricColumnIndex: number

  private holdingColumnIndex: number

  private mode: MetricsSpreadsheetMode

  private holding?: Holding

  /**
   * If provided, these metrics will be preloaded in the spreadsheet.
   * For example when opening the modal as a founder.
   */
  private preloadedMetricRows: Metric[]

  constructor(
    mode: MetricsSpreadsheetMode,
    metricColumnIndex: number,
    holdingColumnIndex: number,
    preloadedMetricRows: Metric[],
    holding?: Holding
  ) {
    this.mode = mode
    this.metricColumnIndex = metricColumnIndex
    this.holdingColumnIndex = holdingColumnIndex
    this.preloadedMetricRows = preloadedMetricRows
    this.holding = holding
  }

  getMetricColumnIndex = (): number => this.metricColumnIndex

  getHoldingColumnIndex = (): number => this.holdingColumnIndex

  getMode = (): MetricsSpreadsheetMode => this.mode

  getSpreadsheet = (fetchingMetrics?: boolean): GridType => {
    const spreadsheet = this.getHeader()
    const rowsCount = this.preloadedMetricRows.length || MODAL_EXTRA_ROWS

    spreadsheet.push(this.createFirstRow(!!fetchingMetrics))

    for (let i = 1; i < rowsCount; i++) {
      const metric = this.preloadedMetricRows[i]

      spreadsheet.push(this.createRow(spreadsheet, metric))
    }

    for (let i = 0; i < MODAL_EXTRA_COLUMNS; i++) {
      spreadsheet.forEach((row, index) => {
        const cell = this.createColumnCell(index)
        const newRow = [...row, cell]
        spreadsheet[index] = newRow
      })
    }

    return spreadsheet
  }

  getHeader = (): GridType => {
    const spreadsheet: GridType = [
      [
        {
          type: 'rowNumber',
          rowNumber: 0,
          rowIndex: 0,
          width: ROW_NUMBER_CELL_WIDTH,
          isHeader: true,
          errors: new Set(),
          warnings: new Set(),
        },
        {
          type: 'text',
          text: '',
          nonEditable: true,
          rowIndex: 0,
          style: {
            border: {
              ...SpreadsheetBorders.NoLeftBorder,
              ...SpreadsheetBorders.NoTopBorder,
              ...SpreadsheetBorders.ThickRightBorder,
              ...SpreadsheetBorders.ThickBottomBorder,
            },
          },
        },
        {
          type: 'date',
          date: new Date(),
          maxDate: new Date(),
          maxDateErrorMsgId: 'metrics.update.noFutureDatesAllowed',
          centered: true,
          rowIndex: 0,
          style: {
            border: {
              ...SpreadsheetBorders.NoTopBorder,
              ...SpreadsheetBorders.NoRightBorder,
              ...SpreadsheetBorders.ThickBottomBorder,
            },
          },
        },
      ],
    ]

    if (this.mode === MetricsSpreadsheetMode.MULTIPLE_HOLDINGS) {
      spreadsheet[0].splice(1, 0, {
        type: 'text',
        text: '',
        nonEditable: true,
        rowIndex: 1,
        style: {
          border: {
            ...SpreadsheetBorders.NoLeftBorder,
            ...SpreadsheetBorders.NoTopBorder,
            ...SpreadsheetBorders.NoRightBorder,
            ...SpreadsheetBorders.ThickBottomBorder,
          },
        },
      })
    }

    return spreadsheet
  }

  createFirstRow = (fetchingMetrics: boolean): CellType[] => {
    const firstRow: CellType[] = [
      {
        type: 'rowNumber',
        rowNumber: 1,
        rowIndex: 1,
        width: ROW_NUMBER_CELL_WIDTH,
        errors: new Set(),
        warnings: new Set(),
      },
      {
        type: 'dropdown',
        initialMetrics: this.preloadedMetricRows,
        loading: fetchingMetrics,
        rowIndex: 1,
        metric: this.preloadedMetricRows[0],
        style: {
          border: {
            ...SpreadsheetBorders.NoLeftBorder,
            ...SpreadsheetBorders.ThickRightBorder,
          },
        },
      },
      {
        type: 'number',
        value: NaN,
        centered: true,
        rowIndex: 1,
        style: {
          border: {
            ...SpreadsheetBorders.NoRightBorder,
          },
        },
      },
    ]

    if (this.mode === MetricsSpreadsheetMode.MULTIPLE_HOLDINGS) {
      firstRow.splice(1, 0, {
        type: 'holdingDropdown',
        initialHoldings: [],
        holding: this.holding,
        loading: false,
        rowIndex: 1,
        placeholderId: 'spreadsheet.metrics.selectFrom',
        style: {
          border: {
            ...SpreadsheetBorders.NoLeftBorder,
            ...SpreadsheetBorders.NoRightBorder,
          },
        },
      })
    }

    return firstRow
  }

  /**
   * Creates a new row for the spreadsheet. If metric is provided, it will be used to populate the metric cell.
   * Note: Spreadsheet must have the header row and the first row before calling this method.
   */
  createRow = (spreadsheet: GridType, metric?: Metric): CellType[] => {
    const dateColumnsSize =
      spreadsheet[0].length -
      (this.mode === MetricsSpreadsheetMode.MULTIPLE_HOLDINGS ? 3 : 2)
    const { initialMetrics } = spreadsheet[1][
      this.metricColumnIndex
    ] as MetricCell
    const row: (CustomNumberCell | MetricCell | HoldingCell | RowNumberCell)[] =
      [
        {
          type: 'rowNumber',
          rowNumber: spreadsheet.length,
          rowIndex: spreadsheet.length,
          width: ROW_NUMBER_CELL_WIDTH,
          errors: new Set(),
          warnings: new Set(),
        },
        {
          type: 'dropdown',
          initialMetrics,
          rowIndex: spreadsheet.length,
          metric,
          style: {
            border: {
              ...SpreadsheetBorders.NoLeftBorder,
              ...SpreadsheetBorders.ThickRightBorder,
            },
          },
        },
      ]

    if (this.mode === MetricsSpreadsheetMode.MULTIPLE_HOLDINGS) {
      const { initialHoldings } = spreadsheet[1][
        this.holdingColumnIndex
      ] as HoldingCell

      row.splice(1, 0, {
        type: 'holdingDropdown',
        initialHoldings,
        loading: false,
        rowIndex: spreadsheet.length,
        holding: this.holding,
        placeholderId: 'spreadsheet.metrics.selectFrom',
        style: {
          border: {
            ...SpreadsheetBorders.NoLeftBorder,
            ...SpreadsheetBorders.NoRightBorder,
          },
        },
      })
    }

    new Array(dateColumnsSize).fill(0).forEach(() => {
      row.push({
        type: 'number',
        value: NaN,
        centered: true,
        rowIndex: spreadsheet.length,
        style: {
          border: {
            ...SpreadsheetBorders.NoRightBorder,
          },
        },
      })
    })

    return row
  }

  createColumnCell = (index: number) => {
    let cell: CustomNumberCell | CustomDateCell = {
      type: 'number',
      value: NaN,
      centered: true,
      rowIndex: index,
      style: {
        border: {
          ...SpreadsheetBorders.NoRightBorder,
        },
      },
    }

    if (index === 0) {
      cell = {
        type: 'date',
        centered: true,
        maxDate: new Date(),
        rowIndex: index,
        style: {
          border: {
            ...SpreadsheetBorders.NoTopBorder,
            ...SpreadsheetBorders.NoLeftBorder,
            ...SpreadsheetBorders.NoRightBorder,
            ...SpreadsheetBorders.ThickBottomBorder,
          },
        },
      }
    }

    return cell
  }

  getColumns = (spreadsheet: GridType): Column[] => {
    return spreadsheet[0].map((cell, index) => ({
      columnId: index,
      width: cell.width || CELL_WIDTH,
    }))
  }

  getRows = (spreadsheet: GridType): Row<CellType>[] => {
    return spreadsheet.map<Row<CellType>>((cells, idx) => {
      let rowHeight = DEFAULT_CELL_HEIGHT

      if (idx !== 0) {
        const metricCell = cells[this.metricColumnIndex] as MetricCell
        rowHeight = getCellHeightFromAmountOfLines(
          metricCell.metric?.name,
          metricCell.metric?.toCreate
        )
      }

      return {
        rowId: idx,
        cells: [...cells],
        height: rowHeight,
      }
    })
  }

  applyChangesToGrid = (
    changes: CellChange<CellType>[],
    currentSpreadsheet: GridType,
    fetchMetrics: (metricName: string, companyId: string) => Promise<Metric[]>,
    getHoldingsInitialMetrics: (companyId: string) => Metric[]
  ): GridType => {
    const rowsChanged: Set<number> = new Set()
    const newSpreadsheet = [...currentSpreadsheet]

    changes.forEach((change) => {
      const rowIndex = change.rowId as number
      const columnIndex = change.columnId as number

      if (rowIndex === 0 && columnIndex === 0) {
        return
      }

      const row = newSpreadsheet[rowIndex]

      if (!row) {
        newSpreadsheet[rowIndex] = this.createRow(newSpreadsheet)
      }

      if (!rowsChanged.has(rowIndex)) {
        rowsChanged.add(rowIndex)
      }

      const cellToEdit = newSpreadsheet[rowIndex][columnIndex]

      if (!cellToEdit) {
        newSpreadsheet.forEach((existingRow, index) => {
          const cell = this.createColumnCell(index)

          // eslint-disable-next-line no-param-reassign
          existingRow[columnIndex] = cell
        })
      } else if (this.mode === MetricsSpreadsheetMode.MULTIPLE_HOLDINGS) {
        if (columnIndex === this.metricColumnIndex) {
          const newMetricCell = change.newCell as MetricCell

          if (newMetricCell.metric?.name) {
            const metricName = newMetricCell.metric.name

            const holdingCell = newSpreadsheet[rowIndex][
              this.holdingColumnIndex
            ] as HoldingCell

            if (
              holdingCell.holding &&
              holdingCell.holding.id !== UNKNOWN_HOLDING_ID
            ) {
              newMetricCell.initialMetrics = getHoldingsInitialMetrics(
                holdingCell.holding.id
              )

              let metric = newMetricCell.initialMetrics.find(
                (m) => m.name === metricName
              )

              if (metric) {
                newMetricCell.metric = metric
              } else {
                fetchMetrics(
                  newMetricCell.metric.name,
                  holdingCell.holding.id
                ).then((metrics) => {
                  metric = metrics.find((m) => m.name === metricName)
                  dispatchEvent<MetricForHoldingFetched>(
                    MetricsSpreadsheetEvents.METRIC_FOR_HOLDING_FETCHED,
                    {
                      metric,
                      metricName,
                      rowIndex,
                    }
                  )
                })
              }
            } else {
              newMetricCell.metric.fetchingMetric = false
            }
          }
        } else if (columnIndex === this.holdingColumnIndex) {
          const newHoldingCell = change.newCell as HoldingCell
          const metricCell = newSpreadsheet[rowIndex][
            this.metricColumnIndex
          ] as MetricCell

          if (
            newHoldingCell.holding?.id &&
            newHoldingCell.holding.id !== UNKNOWN_HOLDING_ID
          ) {
            metricCell.initialMetrics = getHoldingsInitialMetrics(
              newHoldingCell.holding.id
            )
          }

          if (
            metricCell.metric?.name &&
            newHoldingCell.holding?.id &&
            newHoldingCell.holding.id !== UNKNOWN_HOLDING_ID
          ) {
            const metricName = metricCell.metric.name
            const holdingId = newHoldingCell.holding.id

            metricCell.initialMetrics = getHoldingsInitialMetrics(
              newHoldingCell.holding.id
            )

            let metric = metricCell.initialMetrics.find(
              (m) => m.name === metricName
            )

            if (metric) {
              metricCell.metric = metric
            } else {
              fetchMetrics(metricName, holdingId).then((metrics) => {
                metric = metrics.find((m) => m.name === metricName)
                dispatchEvent<MetricForHoldingFetched>(
                  MetricsSpreadsheetEvents.METRIC_FOR_HOLDING_FETCHED,
                  {
                    metric,
                    metricName,
                    rowIndex,
                  }
                )
              })
            }
          }
        }
      }

      newSpreadsheet[rowIndex][columnIndex] = { ...change.newCell, rowIndex }
    })

    // Add errors to the first cell of the row
    rowsChanged.forEach((rowIndex) => {
      const row = newSpreadsheet[rowIndex]

      row.forEach((cell, index) => {
        const customCell = cell as CustomCell

        const rowNumberCell = row[0] as RowNumberCell

        if (customCell.error && !customCell.disabled) {
          rowNumberCell.errors.add(index)
        } else {
          rowNumberCell.errors.delete(index)
        }
      })
    })

    return newSpreadsheet
  }

  isReadOnlyCell = (rowIndex: number, columnIndex: number): boolean => {
    if (this.mode === MetricsSpreadsheetMode.SINGLE_HOLDING) {
      return rowIndex === 0 && columnIndex === 0
    }

    return rowIndex === 0 && (columnIndex === 0 || columnIndex === 1)
  }
}
