import { Formik } from 'formik'
import { useIntl } from 'react-intl'

import Button from 'ui/Button'
import Drawer from 'components/Drawer/Drawer'
import useAddToExistingMetric, {
  AddToExistingMetricFormValues,
} from './useAddToExistingMetric'
import AddToExistingMetricForm from './AddToExistingMetricForm'
import { addMetricToExistingMetricSchema } from '../metricSchemas'

interface Props {
  isOpen: boolean
  closeDrawer: () => void
}

const AddMetricToExistingMetric = ({ closeDrawer, isOpen }: Props) => {
  const intl = useIntl()
  const { metric, initialValues, loadMetrics, addToExistingMetricMutation } =
    useAddToExistingMetric({
      closeDrawer,
    })

  return (
    <Formik<AddToExistingMetricFormValues>
      initialValues={initialValues}
      onSubmit={(values) => addToExistingMetricMutation(values)}
      validationSchema={addMetricToExistingMetricSchema}
      validateOnChange
    >
      {({ handleSubmit, values, isValid }) => (
        <Drawer
          isOpen={isOpen}
          headerTitle={intl.formatMessage(
            { id: 'metrics.addMetricToExistingMetric' },
            { metricName: metric?.name }
          )}
          onCloseDrawer={closeDrawer}
        >
          <AddToExistingMetricForm loadMetrics={loadMetrics} />

          <Drawer.Footer>
            <Button onClick={closeDrawer}>
              {intl.formatMessage({ id: 'common.cancel' })}
            </Button>
            <Button
              primary
              onClick={handleSubmit}
              disabled={!isValid || !values.id}
            >
              {intl.formatMessage({ id: 'common.save' })}
            </Button>
          </Drawer.Footer>
        </Drawer>
      )}
    </Formik>
  )
}

export default AddMetricToExistingMetric
