import { useMemo, useState } from 'react'
import {
  CHART_SCROLL_LIMITS,
  ChartPeriods,
} from 'components/Charts/MultiViewChart/types'
import useChartScroll from 'components/Charts/useChartScroll'
import { DataPoint } from 'utils/types/metricsV2'
import { BreakPointName, maxSize } from 'utils/constants/breakpoint'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { displayThousands } from 'utils/functions/number'

const FONT_SIZE_MULTIPLIER = 5.5

export const VISIBLE_METRICS_LIMIT: Record<BreakPointName, number> = {
  xxs: 2,
  xss: 2,
  xsm: 4,
  sm: 6,
  md: 4,
  mdXl: 4,
  mobile: 4,
  lg: 4,
  tabletLg: 5,
  xl: 7,
  desktopLg: 8,
  xxl: 10,
  xxxl: 12,
  xxxXl: 14,
}

export interface LineChartMargin {
  top: number
  left: number
  right: number
  bottom?: number
}

export interface XAxisPadding {
  left: number
  right: number
}

const useMetricChart = ({
  dataPoints,
  breakpoint,
  visibleMetricsLimit,
  lineChartMargin: lineChartMarginProp,
  calculateMarginLeft,
}: {
  dataPoints: DataPoint[]
  breakpoint: BreakPointName
  visibleMetricsLimit?: Record<BreakPointName, number>
  lineChartMargin?: LineChartMargin
  calculateMarginLeft?: boolean
}) => {
  const [isChartHovered, setIsChartHovered] = useState<boolean>(false)
  const { matches: isMobile } = useMediaQuery(maxSize.md)

  const {
    results: visibleMetrics,
    hasNext,
    hasPrevious,
    onPreviousPage,
    onNextPage,
  } = useChartScroll<DataPoint>({
    elements: dataPoints,
    visibleElements: visibleMetricsLimit
      ? visibleMetricsLimit[breakpoint]
      : VISIBLE_METRICS_LIMIT[breakpoint],
    limit: CHART_SCROLL_LIMITS[`${ChartPeriods.YEARLY}`],
  })

  const chartOpacity = useMemo(
    () => (isChartHovered ? 0.5 : 1),
    [isChartHovered]
  )

  const defaultLineChartMargin: LineChartMargin = useMemo(
    () =>
      isMobile
        ? { top: 10, bottom: 0, left: 0, right: 45 }
        : { top: 20, right: 80, left: 40 },
    [isMobile]
  )

  const xAxisPadding: XAxisPadding = useMemo(
    () => (isMobile ? { left: 10, right: 30 } : { left: 20, right: 20 }),
    [isMobile]
  )

  const yTicks = useMemo(() => {
    let currentMin = Number.MAX_VALUE
    let max = Number.NEGATIVE_INFINITY

    dataPoints.forEach(({ value }) => {
      if (currentMin > value) {
        currentMin = value
      }

      if (max < value) {
        max = value
      }
    })

    const min = Math.min(currentMin, 0)
    const median = (min + max) / 2

    return [min, median, max]
  }, [dataPoints])

  const lineChartMargin = useMemo(() => {
    const currentChartMargin = lineChartMarginProp || defaultLineChartMargin

    if (calculateMarginLeft) {
      const maxYAxisValueLength = Math.max(
        ...dataPoints.map((dataPoint) => {
          return (displayThousands(dataPoint.value) ?? 0).toString().length
        }),
        ...yTicks.map((tick) => displayThousands(tick).toString().length)
      )

      return {
        ...currentChartMargin,
        left:
          currentChartMargin.left + maxYAxisValueLength * FONT_SIZE_MULTIPLIER,
      }
    }

    return currentChartMargin
  }, [
    calculateMarginLeft,
    dataPoints,
    defaultLineChartMargin,
    lineChartMarginProp,
    yTicks,
  ])

  return {
    visibleMetrics,
    hasNext,
    hasPrevious,
    lineChartMargin,
    xAxisPadding,
    yAxisDomain: [yTicks[0], yTicks[2]],
    yTicks,
    chartOpacity,
    isChartHovered,
    onPreviousPage,
    onNextPage,
    setIsChartHovered,
  }
}

export default useMetricChart
