/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useEffect, useMemo } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'

import GroupService from 'api/GroupService'
import Loading from 'components/Loading'
import NoMatch from 'components/NoMatch'
import ProtectedRoute from 'components/ProtectedRoute'
import TermOfUse from 'components/TermsOfUse'
import AccountSetupProxy from 'containers/Auth/AccountSetupProxy'
import CancelSubscriptionFeedback from 'containers/CancelSubscriptionFeedback'
import IntegrationRedirect from 'containers/Integrations/IntegrationRedirect'
import WelcomePage from 'containers/Signup/WelcomePage'
import {
  setCurrentGroupRole,
  setCurrentUserGroupId,
  setGroupBeta,
  setUserGroups,
} from 'features/authSlice'
import MainLayout from 'layouts/MainLayout'
import { useGroupSelector } from 'layouts/SideBarComponents/GroupSelector/useGroupSelector'

import { GroupTypes } from 'utils/constants/groups'
import { useAuthentication } from 'utils/hooks/auth'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import useQueryString from 'utils/hooks/useQueryString'
import * as FullStory from 'utils/integrations/fullStory'

import { useAuth0 } from '@auth0/auth0-react'
import DevEnvironmentSelector from 'components/DevEnvironmentSelector'
import { SignupProvider } from 'containers/Signup/context/SignupProvider'
import SignupWizard from 'containers/Signup/SignupWizard'
import { setIsFundSelectedByDefault } from 'features/investorManagementSlice'
import { getRedirectUrl } from 'features/redirectSlice'
import AuthRoutes from 'routes/AuthRoutes'
import {
  getCurrentGroupData,
  getCurrentGroupId,
  getParentGroup,
  getUser,
  getUserConfirmationToken,
  getUserGroups,
  isActingAsClient,
  isActingAsFounder,
  isActingAsFundManager,
  isActingAsInvestorGroup,
  showTermsOfUse as showTermsOfuseAppSelector,
} from 'selectors/auth'
import { getIsFounderVerified } from 'selectors/company'
import { PROD_ENV } from 'utils/functions/env'
import { useGroupQuery } from 'utils/hooks/queries/useGroupQuery'
import { useQueryData } from 'utils/hooks/useQueryData'
import { SETTINGS_KEY } from 'utils/hooks/useSettings'
import { Settings } from 'utils/types/settings'
import ChatSetup from 'ChatSetup'
import RefreshAuth0Tokens from '../RefreshAuth0Tokens'
import InvestorGroupRoutes from './InvestorGroupRoutes'
import RedirectUrlRoute from './RedirectUrlRoute'
import SignupRoutes from './SignupRoutes'
import {
  autRoutes,
  accountSetupRoutes,
  redirectUrlRoutes,
  integrationRedirectRoutes,
  signUpRoutes,
  welcomeRoutes,
  cancelSubscriptionFeedbackRoutes,
} from './constant'
import { REDIRECT_URL } from './constant/redirectUrl'
import FounderGroupRoutes from './FounderGroupRoutes'
import FundManagerAndClientRoutes from './FundManagerAndClientRoutes'

const AppRoutes = () => {
  const dispatch = useAppDispatch()
  const location = useLocation<{
    userCredentials: string
  }>()
  const groupId = useQueryString('group_id')
  const displayTermsOfUse = useAppSelector(showTermsOfuseAppSelector)
  const { isAuthenticated } = useAuthentication()
  const isFundManager = useAppSelector(isActingAsFundManager)
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const currentGroup = useAppSelector(getCurrentGroupData)
  const userConfirmationToken = useAppSelector(getUserConfirmationToken)
  const isFounderVerified = useAppSelector(getIsFounderVerified)
  const isFounder = useAppSelector(isActingAsFounder)
  const isClient = useAppSelector(isActingAsClient)
  const currentUser = useAppSelector(getUser)
  const history = useHistory()
  const currentUserGroups = useAppSelector(getUserGroups)
  const { onSelectGroup } = useGroupSelector()
  const parentGroupId = useAppSelector(getParentGroup)?.id
  const redirectUrl = useAppSelector(getRedirectUrl)

  const settings = useQueryData<Settings>([SETTINGS_KEY])

  const { data: parentGroup } = useGroupQuery(parentGroupId!, {
    enabled: isInvestorGroup && !!parentGroupId,
  })

  const redirectUserToTermsOfUse =
    location.state?.userCredentials &&
    location.pathname.includes('terms-of-use')

  useEffect(() => {
    if (groupId && currentUserGroups?.[groupId]) {
      onSelectGroup(groupId)
      history.replace(location.pathname)
    }
  }, [groupId])

  useEffect(() => {
    if (isInvestorGroup && parentGroup) {
      dispatch(setIsFundSelectedByDefault(parentGroup.portfolioDefaultViewFund))
    }
  }, [parentGroup, isInvestorGroup])

  const fetchCurrentGroupRole = async () => {
    if (
      currentUser &&
      currentGroup &&
      currentGroup.type !== GroupTypes.INVESTOR_GROUP
    ) {
      const res = await GroupService.getUserGroup({
        userId: currentUser.id,
        groupId: currentGroup.id,
        isInvestorGroup: false,
      })
      const { id, role, beta } = res
      dispatch(
        setGroupBeta({
          groupId: currentGroup.id,
          isBeta: beta,
        })
      )
      dispatch(setCurrentUserGroupId(id))
      dispatch(setCurrentGroupRole(role))
    }
  }

  const fetchUserGroups = async () => {
    const userGroups = await GroupService.fetchUserGroups()
    if (userGroups.length > 0) {
      dispatch(setUserGroups(userGroups))
      fetchCurrentGroupRole()
    }
  }
  const { user: auth0User } = useAuth0()

  useEffect(() => {
    if (isAuthenticated && auth0User?.email_verified) {
      fetchUserGroups()
      if (currentGroupId && currentUserGroups?.[currentGroupId]) {
        fetchCurrentGroupRole()
      }
    }
  }, [
    isAuthenticated,
    Object.keys(currentUserGroups || {}).length,
    currentGroupId,
  ])

  useEffect(() => {
    if (
      currentUser &&
      currentGroup &&
      FullStory.isInitialized() &&
      settings?.privacy?.sharingUsageData
    ) {
      FullStory.identifyUser(currentUser, currentGroup)
    }
  }, [currentUser, currentGroup])

  const shouldShowSignupWizard = useMemo(() => {
    if (currentUser) {
      const hasNoGroups = Object.keys(currentUserGroups || {}).length === 0
      const hasNoName =
        currentUser.firstName === null && currentUser.lastName === null

      return hasNoGroups || hasNoName
    }

    return false
  }, [currentUser, currentUserGroups])

  if (redirectUserToTermsOfUse || displayTermsOfUse) {
    const userCredentials = userConfirmationToken

    return (
      <TermOfUse
        userCredentials={
          userCredentials ? { token: userCredentials } : currentUser
        }
      />
    )
  }

  return (
    <Switch>
      {!PROD_ENV && (
        <Route exact path="/env">
          <DevEnvironmentSelector />
        </Route>
      )}

      <Route path={autRoutes}>
        <AuthRoutes />
      </Route>

      <ChatSetup>
        <RefreshAuth0Tokens>
          <Switch>
            <Route path={accountSetupRoutes}>
              <AccountSetupProxy />
            </Route>

            <Route path={redirectUrlRoutes}>
              <RedirectUrlRoute />
            </Route>

            <ProtectedRoute path={integrationRedirectRoutes}>
              <IntegrationRedirect />
            </ProtectedRoute>

            <Route path={signUpRoutes}>
              <SignupRoutes />
            </Route>

            <Route exact path={welcomeRoutes}>
              <WelcomePage />
            </Route>

            <Route exact path={cancelSubscriptionFeedbackRoutes}>
              <CancelSubscriptionFeedback />
            </Route>

            <MainLayout
              isFounderVerified={isFounderVerified}
              position={shouldShowSignupWizard ? 'relative' : 'initial'}
            >
              <Suspense fallback={<Loading />}>
                {redirectUrl && <Redirect from="/" to={REDIRECT_URL} />}

                {(() => {
                  if (shouldShowSignupWizard)
                    return (
                      <SignupProvider>
                        <SignupWizard />
                      </SignupProvider>
                    )

                  return (
                    <Switch>
                      {!isAuthenticated && (
                        <Redirect exact from="/" to="/sign-in" />
                      )}

                      {isFounder ? (
                        <Redirect exact from="/" to="/dashboard/updates" />
                      ) : (
                        <Redirect exact from="/" to="/investments" />
                      )}

                      {isInvestorGroup && <InvestorGroupRoutes />}
                      {isFounder && <FounderGroupRoutes />}

                      {(isFundManager || isClient) && (
                        <FundManagerAndClientRoutes />
                      )}
                      <Redirect to="/" />
                      <Route path="/">
                        <NoMatch />
                      </Route>
                    </Switch>
                  )
                })()}
              </Suspense>
            </MainLayout>
          </Switch>
        </RefreshAuth0Tokens>
      </ChatSetup>
    </Switch>
  )
}

export default AppRoutes
