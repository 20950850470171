import React, { useCallback, useId, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { getInitials } from 'utils/functions/user'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { maxSize } from 'utils/constants/breakpoint'
import NumbersService from 'api/NumbersService'
import Toast from 'components/Toast'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip'
import { SkeletonInvitationsItem } from 'components/Skeletons/Metrics/SkeletonInvitationsCard/SkeletonInvitationsCard'
import { useMetricsRoute } from 'utils/hooks/useMetricsRoute'
import {
  useHideMetricInvitation,
  useInvalidateMetricsInvitationsQueries,
} from 'utils/hooks/metrics/useMetricsInvitationsQueries'
import { LinkedMetric, LinkedMetricState } from 'utils/types/metricsV2'
import useMetricQuery from 'utils/hooks/useMetricQuery'
import { useGroupQuery } from 'utils/hooks/queries/useGroupQuery'
import * as Styles from './InvitationRow.styles'

const ReadOnlyButton = ({ children }) => {
  const id = useId()
  const intl = useIntl()

  return (
    <ReadOnlyTooltip
      id={`invitation_row_${id}`}
      text={intl.formatMessage({ id: 'readOnlyMode.addMetrics' })}
      disabled={undefined}
    >
      {children}
    </ReadOnlyTooltip>
  )
}

interface PendingInvitationRowProps {
  invitation: LinkedMetric
  isPendingInvitation?: boolean
}

const PendingInvitationRow: React.FC<PendingInvitationRowProps> = ({
  invitation,
  isPendingInvitation,
}) => {
  const isSharedMetric =
    invitation.state === LinkedMetricState.SHARED ||
    invitation.state === LinkedMetricState.SHARE_ACCEPTED
  const groupId = useMemo(
    () =>
      isSharedMetric ? invitation.senderGroupId : invitation.receiverGroupId,
    [invitation, isSharedMetric]
  )
  const { data: group, isLoading: isLoadingGroup } = useGroupQuery(
    groupId ?? ''
  )
  const { metric: receiverMetric, isLoading: isLoadingReceiverMetric } =
    useMetricQuery({
      metricId: invitation.receiverMetricId ?? '',
    })
  const { metric: senderMetric, isLoading: isLoadingSenderMetric } =
    useMetricQuery({
      metricId: invitation.senderMetricId ?? '',
    })
  const isLoading =
    isLoadingGroup ||
    (isLoadingReceiverMetric && invitation.receiverMetricId) ||
    (isLoadingSenderMetric && invitation.senderMetricId)

  const { invalidateInvitationsQueries } =
    useInvalidateMetricsInvitationsQueries()
  const { hideMetricInvitation, unhideMetricInvitation } =
    useHideMetricInvitation()
  const { matches: isMobile } = useMediaQuery(maxSize.lg)
  const { matches: showInvitationHistoryInARow } = useMediaQuery(maxSize.sm)
  const showActionButtonsOrStatusAsDesktop = isPendingInvitation
    ? !isMobile
    : !showInvitationHistoryInARow

  const intl = useIntl()
  const history = useHistory()
  const metricsRoute = useMetricsRoute()

  const updateMetricStatus = useCallback(
    async (linkedMetric: LinkedMetric, state: LinkedMetricState) => {
      try {
        await NumbersService.processALinkedMetric({
          id: linkedMetric.id,
          state,
        })
        invalidateInvitationsQueries()
      } catch (error) {
        Toast.displayIntl('metrics.updateMetricError', 'error')
      }
    },
    [invalidateInvitationsQueries]
  )

  const onApproveMetric = useCallback(() => {
    history.push(`${metricsRoute}/${invitation.id}/approve-metric`, {
      linkedMetric: invitation,
    })
  }, [history, metricsRoute, invitation])

  const onDenyMetric = useCallback(() => {
    hideMetricInvitation(invitation.id)

    Toast.displayAction({
      message: intl.formatMessage(
        { id: 'metrics.metricDenied' },
        { metricName: receiverMetric?.name, groupName: group?.name }
      ),
      type: 'action',

      action: () => {
        unhideMetricInvitation(invitation.id)
      },
      afterClose: () =>
        updateMetricStatus(invitation, LinkedMetricState.REQUEST_DENIED),
    })
  }, [
    hideMetricInvitation,
    invitation,
    intl,
    receiverMetric?.name,
    group?.name,
    unhideMetricInvitation,
    updateMetricStatus,
  ])

  const redirectToCreateNewMetric = useCallback(() => {
    history.push(`${metricsRoute}/new`, {
      linkedMetric: invitation,
    })
  }, [history, metricsRoute, invitation])

  const redirectToAddMetricToExistingMetric = useCallback(() => {
    history.push(
      `${metricsRoute}/${invitation.id}/add-metric-data-to-existing-metric`,
      {
        metric: invitation,
      }
    )
  }, [history, metricsRoute, invitation])

  const getStatusMessageResourceId = () => {
    if (isSharedMetric) {
      return 'metrics.invitations.addedTo'
    }

    if (invitation.state === LinkedMetricState.REQUEST_DENIED) {
      return 'metrics.invitations.accessDenied'
    }

    return 'metrics.invitations.groupAddedTo'
  }

  const renderInvitationHistoryStatus = () => {
    return (
      <Styles.RightContainer isInvitationHistoryStatus>
        <Styles.Status>
          <FormattedMessage
            id={getStatusMessageResourceId()}
            values={{
              metricName: isSharedMetric
                ? receiverMetric?.name
                : senderMetric?.name,
            }}
          />
        </Styles.Status>
      </Styles.RightContainer>
    )
  }

  const renderActionButtonsOrStatus = () => {
    if (invitation.state === LinkedMetricState.REQUESTED) {
      return (
        <Styles.RightContainer largeMargin>
          <ReadOnlyButton>
            <Styles.Button
              secondary
              icon={['fal', 'check']}
              onClick={onApproveMetric}
            >
              <FormattedMessage id="metrics.invitations.approve" />
            </Styles.Button>
          </ReadOnlyButton>
          <ReadOnlyButton>
            <Styles.Button
              secondary
              icon={['fal', 'minus-hexagon']}
              onClick={onDenyMetric}
            >
              <FormattedMessage id="metrics.invitations.denyAccess" />
            </Styles.Button>
          </ReadOnlyButton>
        </Styles.RightContainer>
      )
    }

    if (invitation.state === LinkedMetricState.SHARED) {
      return (
        <Styles.RightContainer>
          <ReadOnlyButton>
            <Styles.Button
              secondary
              icon={['fal', 'plus']}
              onClick={redirectToCreateNewMetric}
            >
              <FormattedMessage id="metrics.invitations.newMetric" />
            </Styles.Button>
          </ReadOnlyButton>
          <ReadOnlyButton>
            <Styles.Button
              secondary
              icon={['fal', 'share']}
              onClick={redirectToAddMetricToExistingMetric}
            >
              <FormattedMessage id="metrics.invitations.addToExisting" />
            </Styles.Button>
          </ReadOnlyButton>
        </Styles.RightContainer>
      )
    }

    return renderInvitationHistoryStatus()
  }

  if (isLoading) {
    return (
      <Styles.RowContainer>
        <SkeletonInvitationsItem />
      </Styles.RowContainer>
    )
  }

  return (
    <Styles.RowContainer isPendingInvitation={isPendingInvitation}>
      <Styles.RowContent>
        <Styles.Avatar
          image={group?.logo?.url}
          initials={getInitials(group?.name)}
          avatarStyle="avatarCircleLogs"
        />
        <Styles.MessageContainer>
          <FormattedMessage
            id={`metrics.invitations.${
              isSharedMetric ? 'founderSharingMetric' : 'requestingAccess'
            }`}
            values={{
              strong: (message) => (
                <Styles.GroupName>{message}</Styles.GroupName>
              ),
              groupName: group?.name,
              metricName: isSharedMetric
                ? senderMetric?.name
                : receiverMetric?.name,
              message: invitation.message ? `. “${invitation.message}”` : '',
            }}
          />
          <Styles.RequestDate>
            <FormattedMessage
              id={`metrics.invitations.${
                isSharedMetric ? 'received' : 'requestSent'
              }`}
              values={{
                date: dayjs(invitation.createdAt).format('MMM D, YYYY'),
              }}
            />
          </Styles.RequestDate>
        </Styles.MessageContainer>
        {showActionButtonsOrStatusAsDesktop && renderActionButtonsOrStatus()}
      </Styles.RowContent>
      {!showActionButtonsOrStatusAsDesktop && renderActionButtonsOrStatus()}
    </Styles.RowContainer>
  )
}

export default PendingInvitationRow
