import { store } from 'store'
import { isActingAsFounder } from 'selectors/auth'
import axiosClient from './httpClient'

export default class MetricService {
  static get isFounder() {
    return isActingAsFounder(store.getState())
  }

  static parseDataPointsCsvFile = async (file: File) => {
    const formData = new FormData()
    formData.append('[metrics_file]', file)
    const response = await axiosClient(true).post(
      `/metrics/${
        MetricService.isFounder ? 'founder_metrics' : 'investor_metrics'
      }/csv_parse`,
      formData
    )
    return response.data
  }
}
