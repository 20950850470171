import { Formik } from 'formik'
import { useIntl } from 'react-intl'

import CWLoader from 'components/CWLoader'
import Form from 'components/Form/Form'
import Drawer from 'components/Drawer'
import Button from 'ui/Button'

import { LoaderWrapper } from '../EditMetric.styles'
import useEditFounderMetric, {
  EditFounderMetricFormType,
} from './useEditFounderMetric'
import EditFounderMetricForm from './EditFounderMetricForm'

interface Props {
  isOpen: boolean
  closeDrawer: () => void
}

const EditFounderMetric = ({ closeDrawer, isOpen }: Props) => {
  const intl = useIntl()

  const {
    initialValues,
    metric,
    validationSchema,
    isLoading,
    editMetricMutation,
    isEditingMetric,
    metricEditable,
    currentGroups,
    groupSearchText,
    onSelectGroup,
    handlePressEnter,
    handleRemoveGroup,
    loadGroups,
  } = useEditFounderMetric({ closeDrawer })

  if (isLoading || !initialValues || !metric) {
    return (
      <LoaderWrapper>
        <CWLoader />
      </LoaderWrapper>
    )
  }

  return (
    <Drawer
      isOpen={isOpen}
      headerTitle={`${metric?.name} ${intl.formatMessage({
        id: 'metrics.settings',
      })}`}
      onCloseDrawer={closeDrawer}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => editMetricMutation(values)}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
      >
        {({ submitForm, isValid }) => (
          <Form<EditFounderMetricFormType> {...initialValues!}>
            <Drawer.Content contentPadding="2rem 2.4rem">
              <EditFounderMetricForm
                onSelectGroup={onSelectGroup}
                handlePressEnter={handlePressEnter}
                currentGroups={currentGroups}
                isMetricEditable={metricEditable}
                handleRemoveGroup={handleRemoveGroup}
                loadGroups={loadGroups}
                groupSearchText={groupSearchText}
              />
            </Drawer.Content>

            <Drawer.Footer>
              <Button
                secondary
                type="button"
                onClick={closeDrawer}
                disabled={isEditingMetric}
              >
                {intl.formatMessage({ id: 'metrics.cancel' })}
              </Button>

              <Button
                primary
                type="submit"
                disabled={!isValid || isEditingMetric}
                isLoading={isEditingMetric}
                onClick={submitForm}
              >
                {intl.formatMessage({ id: 'metrics.save' })}
              </Button>
            </Drawer.Footer>
          </Form>
        )}
      </Formik>
    </Drawer>
  )
}

export default EditFounderMetric
