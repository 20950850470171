import { useState } from 'react'
import { DisplayMode } from 'utils/constants/metrics'
import { ValueOf } from 'utils/types/common'

export const useDisplayMode = (
  defaultDisplayMode?: ValueOf<typeof DisplayMode>
) => {
  const [displayMode, setDisplayMode] = useState(
    defaultDisplayMode || DisplayMode.VALUE
  )

  const onToggleDisplayMode = () => {
    setDisplayMode(
      displayMode === DisplayMode.VALUE ? DisplayMode.CHART : DisplayMode.VALUE
    )
  }

  const onSelectDisplayMode = (mode: ValueOf<typeof DisplayMode>) => {
    setDisplayMode(mode)
  }

  return {
    displayMode,
    onToggleDisplayMode,
    onSelectDisplayMode,
  }
}
