/* eslint-disable react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define */
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import MetricService from 'api/MetricsService'
import NumbersService from 'api/NumbersService'
import Toast from 'components/Toast'
import { type FormikProps } from 'formik'
import { IMPORT_METRIC_EVENT } from 'utils/constants/events'
import { randomId } from 'utils/functions/number'
import { useBulkImportFileUpload } from 'utils/hooks/useBulkImportFileUpload'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import useMetricGroups from 'utils/hooks/useMetricGroups'
import { metricsKeys } from 'utils/queries/metrics'

export const SCREENS = {
  UPLOAD_FILE: 'UPLOAD_FILE',
  IMPORT_DATAPOINTS: 'IMPORT_DATAPOINTS',
}

export const useBulkImportMetricValues = (metric, isModalOpen, onHideModal) => {
  const queryClient = useQueryClient()
  const [currentScreen, setCurrentScreen] = useState(SCREENS.UPLOAD_FILE)
  const [loading, setLoading] = useState(false)
  const [countImported, setCountImported] = useState(0)
  const [hasParsedCsv, setHasParsedCsv] = useState(false)
  const intl = useIntl()
  const [errorMsg, setErrorMsg] = useState('')

  const {
    handleAddGroup,
    handleAddGroupByHandle,
    handleRemoveGroup,
    loadGroups,
    currentGroups,
    reset,

    itemLists,
    handleDeleteItemList,
    handleEditItemList,
    handleAddItemList,
  } = useMetricGroups(metric?.receiverGroups)

  useEffect(() => {
    if (isModalOpen) {
      setErrorMsg('')
      setCurrentScreen(SCREENS.UPLOAD_FILE)
      setHasParsedCsv(false)
      reset()
    }
  }, [isModalOpen])

  const formRef = useRef<FormikProps<{}>>(null)
  const parseCSV = async (file) => {
    setIsImportingFile(true)
    try {
      const parsedDataPoints = await MetricService.parseDataPointsCsvFile(file)

      if (!parsedDataPoints?.length) {
        setErrorMsg(
          intl.formatMessage({ id: 'bulkImportModal.fileEmptyError' })
        )
        setIsImportingFile(false)
        setHasParsedCsv(false)
        return
      }

      formRef.current?.setFieldValue(
        'metricValues',
        parsedDataPoints.map((data) => ({ ...data, id: randomId() }))
      )
      setCountImported(parsedDataPoints.length)
      setCurrentScreen(SCREENS.IMPORT_DATAPOINTS)
      setHasParsedCsv(true)
      setIsImportingFile(false)
    } catch (error) {
      Toast.display(
        error.status === 400
          ? intl.formatMessage({ id: 'bulkImportModal.csvError' })
          : intl.formatMessage({ id: 'bulkImportModal.errorUploadingFile' }),
        'error'
      )
      setHasParsedCsv(true)
      setIsImportingFile(false)
    }
  }

  const {
    uploadFile,
    isDraggingOver,
    isImportingFile,
    setIsImportingFile,
    onUploadFile,
    onDrop,
    onDragOver,
    onDragLeave,
    onDragEnter,
  } = useBulkImportFileUpload(parseCSV)

  const onSubmit = async (values) => {
    if (!hasParsedCsv) {
      return
    }

    setLoading(true)
    try {
      await NumbersService.bulkCreateDataPoints(
        metric.id,
        values.metricValues.map((dataPoint) => ({
          date: new Date(dataPoint.dataPointDate),
          value: Number(dataPoint.dataPointValue),
          sharedGroups: currentGroups?.map((group) => group.id),
        }))
      )
      setLoading(false)
      onHideModal()
      queryClient.invalidateQueries(metricsKeys.getMetric(metric.id))
      dispatchEvent(IMPORT_METRIC_EVENT)
      queryClient.invalidateQueries(metricsKeys.getDataPoints(metric.id))
      queryClient.invalidateQueries(metricsKeys.getMilestones(metric.id))
    } catch (err) {
      setLoading(false)
      Toast.displayIntl('bulkImportModal.metricsCsvLoadError', 'error')
    }
  }

  return {
    intl,
    formRef,
    currentScreen,
    loading,
    countImported,
    uploadFile,
    isDraggingOver,
    isImportingFile,
    onUploadFile,
    onDrop,
    onDragOver,
    onDragLeave,
    onDragEnter,
    handleAddGroup,
    handleAddGroupByHandle,
    handleRemoveGroup,
    loadGroups,
    onSubmit,
    currentGroups,
    errorMsg,
    handleDeleteItemList,
    handleEditItemList,
    handleAddItemList,
    itemLists,
  }
}
