import { useLocation, useParams } from 'react-router-dom'

import { MetricsMode } from 'utils/types/metrics'
import { IndexMetric } from 'utils/types/metricsV2'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { metricsKeys } from 'utils/queries/metrics'
import {
  getFounderCompany,
  isCurrentCompanyFundProfile,
} from 'selectors/company'
import { isActingAsFounder } from '../../selectors/auth'
import { useObserveQuery } from './useObserveQuery'

export const useMetricsMode = (metricData?: Pick<IndexMetric, 'subject'>) => {
  const location = useLocation<{ isOrganizationMetric?: boolean }>()
  const founderCompany = useAppSelector(getFounderCompany)
  const { id: metricId } = useParams<{ id: string }>()
  const { data: metricCache } = useObserveQuery<IndexMetric | undefined>(
    metricsKeys.getMetric(metricId)
  )
  const metric = metricCache || metricData
  const isOwnCompanyMetric =
    metric?.subject?.id && metric.subject.id === founderCompany?.id
  const isFounder = useAppSelector(isActingAsFounder)
  const isCurrentCompanyTheFundCompany = useAppSelector(
    isCurrentCompanyFundProfile
  )

  const isOrganizationMetric = location.state?.isOrganizationMetric
  const isInHoldingsPage = location.pathname.startsWith('/companies')
  const isInGroupProfilePage = location.pathname.includes('group-profile')
  const isInFundCompanyProfile =
    (isInHoldingsPage || isInGroupProfilePage) && isCurrentCompanyTheFundCompany

  if (
    isFounder ||
    isOrganizationMetric ||
    isInFundCompanyProfile ||
    isOwnCompanyMetric
  )
    return MetricsMode.FOUNDER

  return MetricsMode.CLIENT
}
