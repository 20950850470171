import { SortDirection } from 'utils/constants'
import { MetricSortBy } from 'utils/constants/metrics'
import { MetricsCard } from 'utils/types/metrics'
import { IndexMetric } from 'utils/types/metricsV2'
import { InfiniteQueryData } from 'api/types'

type Sort = {
  sortBy?: MetricSortBy
  direction?: SortDirection
}

const getSortBy = (sortBy: MetricSortBy) => {
  if (sortBy === MetricSortBy.METRIZABLE_NAME) {
    return MetricSortBy.NAME
  }

  return sortBy
}

export const sortMetrics = (
  metrics: IndexMetric[],
  { sortBy, direction }: Sort
): IndexMetric[] => {
  if (sortBy) {
    return metrics.sort((a, b) => {
      const sortByField = getSortBy(sortBy)

      if (direction === SortDirection.ASC) {
        return a[sortByField] > b[sortByField] ? 1 : -1
      }

      return a[sortByField] < b[sortByField] ? 1 : -1
    })
  }

  return metrics
}

export const getMetricsGroupByMetricCards = (
  paginatedMetrics: InfiniteQueryData<IndexMetric>,
  { sortBy, direction }: Sort
): MetricsCard[] => {
  const groupedMetrics =
    paginatedMetrics?.pages.reduce<Record<string, MetricsCard>>(
      (acc, currPage) => {
        currPage?.data.forEach((metric) => {
          const key = metric.subject.id

          if (key) {
            if (!acc[key]) {
              // TODO const name = metric.subject.name

              const name = 'TODO'

              acc[key] = {
                id: key,
                name: name!,
                subjectId: key,
                subjectType: metric.subject.type,
                metricSource: metric.source,
                metrics: [metric],
              }
            } else {
              acc[key].metrics.push(metric)
            }
          }
        })
        return acc
      },
      {}
    ) ?? {}

  if (sortBy) {
    return Object.values(groupedMetrics).map<MetricsCard>(
      ({ metrics, ...rest }) => {
        const sortedMetrics = metrics.sort((a, b) => {
          const sortByField = getSortBy(sortBy)

          if (direction === SortDirection.ASC) {
            return a[sortByField] > b[sortByField] ? 1 : -1
          }

          return a[sortByField] < b[sortByField] ? 1 : -1
        })

        return { ...rest, metrics: sortedMetrics }
      }
    )
  }

  return Object.values(groupedMetrics)
}
