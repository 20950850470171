import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import GroupTooltips from 'components/GroupTooltips'
import MilestoneIndicator from 'components/Milestone/MilestoneIndicator'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'
import IconButton from 'ui/IconButton'
import { isActingAsFounder } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { displayThousands } from 'utils/functions/number'
import { useMetricsRoute } from 'utils/hooks/useMetricsRoute'
import { useRandomId } from 'utils/hooks/useRandomId'
import { Color } from 'utils/theme'
import { Milestone as MilestoneType, Metric } from 'utils/types/metricsV2'
import { useGroupQuery } from 'utils/hooks/queries/useGroupQuery'
import { CreatorLogoContainer } from './Milestone.styles'

interface MilestoneProps {
  milestone: MilestoneType
  metric: Metric
  isEditable: boolean
  mode?: 'dark' | 'light'
  progressColor?: Color
  isInvestorMilestone?: boolean
}

const Milestone = ({
  milestone,
  metric,
  isEditable = false,
  mode = 'light',
  progressColor = 'primaryBlue',
  isInvestorMilestone = false,
}: MilestoneProps) => {
  const intl = useIntl()
  const tooltipId = useRandomId()
  const isFounder = useAppSelector(isActingAsFounder)
  const history = useHistory()
  const metricsRoute = useMetricsRoute()
  const { data: milestoneGroup } = useGroupQuery(milestone.groupId ?? '', {
    enabled: isInvestorMilestone && !!milestone.groupId,
  })

  return (
    <MilestoneIndicator>
      <MilestoneIndicator.Title mode={mode}>
        {displayThousands(milestone.value)}
        {isEditable && (
          <ReadOnlyTooltip
            id={`editMilestoneDropdown_${tooltipId}`}
            text={intl.messages['readOnlyMode.editMilestone']}
            place="left"
            leftOffset={-10}
            maxWidth="25.4rem"
          >
            <IconButton
              icon="pen"
              border={false}
              onClick={() =>
                history.push(
                  `${metricsRoute}/${metric.id}/edit-milestone/${milestone.id}`
                )
              }
            />
          </ReadOnlyTooltip>
        )}
        {isInvestorMilestone && milestoneGroup && (
          <CreatorLogoContainer>
            <GroupTooltips groups={[milestoneGroup]} direction="left" />
          </CreatorLogoContainer>
        )}
      </MilestoneIndicator.Title>
      <MilestoneIndicator.Legend>
        {intl.formatMessage(
          { id: 'metrics.before' },
          {
            value: dayjs(milestone.date).format('DD MMMM YYYY'),
          }
        )}
      </MilestoneIndicator.Legend>

      <MilestoneIndicator.Progress
        color={progressColor}
        value={milestone.percentage}
      />

      {!isFounder && (
        <MilestoneIndicator.Groups metric={metric} shared={milestone.shared} />
      )}
    </MilestoneIndicator>
  )
}

export default Milestone
