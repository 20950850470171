import OptionsDropdown from 'ui/OptionsDropdown'
import * as Styles from './DateRange.styles'
import { DateType, Quarter } from '../../useDateRangeHelpers'

interface Props {
  startDate: string
  endDate: string
  quarterDateActive: {
    isStartDayActive: boolean
    isEndDayActive: boolean
  }
  handleQuarterDateActive: (isStart: boolean) => void
  handleResetDateActive: () => void
  getArrayOfQuartersBasedOnDate: (date: DateType) => Quarter[]
  updateRangeOnQuarterlyDayChange: ({
    date,
    isStart,
  }: {
    date: Date
    isStart: boolean
  }) => void
}

const getQuarter = (date: Date) => {
  const month = date.getMonth() + 1
  return Math.ceil(month / 3)
}

const formatQuarter = (date: Date) => {
  const quarter = getQuarter(date)
  const year = date.getFullYear()
  return `Q${quarter}, ${year}`
}

const QuarterlyRange = ({
  startDate,
  endDate,
  quarterDateActive,
  handleQuarterDateActive,
  handleResetDateActive,
  updateRangeOnQuarterlyDayChange,
  getArrayOfQuartersBasedOnDate,
}: Props) => {
  return (
    <Styles.DropdownsWrapper>
      <OptionsDropdown
        dropdownContentMinWidth="5rem"
        margin="1.2rem 0 0 0"
        contentRight="-3rem"
        onClick={() => handleQuarterDateActive(true)}
        onCloseDropdown={handleResetDateActive}
        attachToDocument={false}
        isScrollable
        title={
          <Styles.DateWrapper isActive={quarterDateActive.isStartDayActive}>
            {formatQuarter(new Date(startDate))}
          </Styles.DateWrapper>
        }
      >
        {getArrayOfQuartersBasedOnDate('end').map((quarter, idx) => (
          <OptionsDropdown.Item
            // eslint-disable-next-line react/no-array-index-key
            key={`${quarter.formattedDate}${idx}`}
            label={quarter.formattedDate}
            onSelectOption={() =>
              updateRangeOnQuarterlyDayChange({
                date: quarter.fullDate,
                isStart: true,
              })
            }
            isSelected={
              quarter.fullDate.getTime() === new Date(startDate).getTime()
            }
          />
        ))}
      </OptionsDropdown>

      <span>-</span>

      <OptionsDropdown
        dropdownContentMinWidth="5rem"
        margin="1.2rem 0 0 0"
        contentRight="-3rem"
        onClick={() => handleQuarterDateActive(false)}
        onCloseDropdown={handleResetDateActive}
        attachToDocument={false}
        isScrollable
        title={
          <Styles.DateWrapper isActive={quarterDateActive.isEndDayActive}>
            {formatQuarter(new Date(endDate))}
          </Styles.DateWrapper>
        }
      >
        {getArrayOfQuartersBasedOnDate('start').map((quarter, idx) => (
          <OptionsDropdown.Item
            // eslint-disable-next-line react/no-array-index-key
            key={`${quarter.formattedDate}${idx}`}
            label={quarter.formattedDate}
            onSelectOption={() =>
              updateRangeOnQuarterlyDayChange({
                date: quarter.fullDate,
                isStart: false,
              })
            }
            isSelected={
              quarter.fullDate.getTime() === new Date(endDate).getTime()
            }
          />
        ))}
      </OptionsDropdown>
    </Styles.DropdownsWrapper>
  )
}

export default QuarterlyRange
