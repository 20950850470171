import { useMemo } from 'react'
import { useGetDataPointsByMetricId } from 'utils/hooks/queries/useDataPointsQuery'
import useMetricQuery from 'utils/hooks/useMetricQuery'

export const useMetricData = ({
  initialMetricData,
  includeDataPoints = true,
}) => {
  const { metric, isLoading, groupedMilestones } = useMetricQuery({
    metricId: initialMetricData.id,
    metricData: {
      ...initialMetricData,
      dataPoints: initialMetricData.dataPoints.map((dp) => dp.id),
    },
    refetchOnMount: false,
  })

  const { data: allDataPoints } = useGetDataPointsByMetricId(
    { metricId: initialMetricData.id },
    {
      staleTime: 1000,
      enabled: includeDataPoints,
    }
  )

  const dataPoints = useMemo(
    () => allDataPoints?.filter((dp) => !dp.archived) || [],
    [allDataPoints]
  )

  return {
    metric,
    isLoading,
    groupedMilestones,
    dataPoints,
  }
}
