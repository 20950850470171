import axios from 'axios'
import humps from 'humps'
import { store } from 'store'
import { updateServiceWorker } from 'utils/serviceWorker'

import { showTermsOfUse, signOut } from 'features/authSlice'

import {
  ApiEnv,
  getCustomApiEnv,
  getDevEnvironment,
} from 'features/preferenceSlice'
import {
  TERMS_OF_USE_NOT_ACCEPTED,
  EMAIL_NOT_VERIFIED,
} from 'utils/constants/errors'
import history from 'utils/history'

const SESSION_EXPIRED = 'You need to sign in or sign up before continuing.'
const SIGNATURE_EXPIRED = 'Signature has expired'

// Prevents transforming to snake case the report params object of the accounting report payloads
const decamelizeAccountingReport = (object) => {
  const reportKey = object.quickbooks_report
    ? 'quickbooks_report'
    : 'xero_report'
  const originalReportData = object[reportKey]?.reportData
  const decamelized = humps.decamelizeKeys(object)
  delete decamelized[reportKey].report_data
  decamelized[reportKey].report_data = originalReportData
  return decamelized
}

const customDecamelize = (object) => {
  if (
    object &&
    typeof object === 'object' &&
    !(object instanceof FormData) &&
    !(object instanceof File)
  ) {
    if (object.quickbooks_report || object.xero_report) {
      return decamelizeAccountingReport(object)
    }

    return humps.decamelizeKeys(object)
  }

  return object
}

export const getApiUrl = (defaultApiUrl, customApiEnv) => {
  if (customApiEnv) {
    if (customApiEnv === ApiEnv.PRODUCTION) {
      return `https://api.cwuniverse.app`
    }
    return `https://${customApiEnv}.api.cwuniverse.app`
  }

  return defaultApiUrl
}

export const buildSessionHeaders = (
  userSession,
  overrideHeaders = {},
  isFormData = false
) => {
  let headers = {
    Accept: 'application/vnd.cwuniverse.v1+json',
    'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
    environment: getDevEnvironment(store.getState()),
  }

  const { 'access-token': overrideAccessToken, ...restOverrideHeaders } =
    overrideHeaders

  if (userSession) {
    const { accessToken, currentGroupId, uid, client } = userSession
    headers = {
      ...headers,
      client,
      uid,
      'group-id': currentGroupId,
      Authorization: `Bearer ${overrideAccessToken || accessToken}`,
      ...restOverrideHeaders,
    }
    if (!headers['group-id']) {
      delete headers['group-id']
    }
  } else {
    headers = {
      ...headers,
      Authorization: `Bearer ${overrideAccessToken}`,
      ...restOverrideHeaders,
    }
  }

  return headers
}

const axiosClient = (
  formData,
  overrideHeaders = {},
  additionalConfig,
  customErrorHandling
) => {
  const { userSession } = store.getState().auth
  const { serviceWorker } = store.getState()

  const headers = buildSessionHeaders(userSession, overrideHeaders, formData)

  const defaultApiUrl = process.env.REACT_APP_API_URL
  const customApi = getCustomApiEnv(store.getState())

  const client = axios.create({
    baseURL: getApiUrl(defaultApiUrl, customApi),
    headers,
    transformRequest: [
      (data) => customDecamelize(data),
      ...axios.defaults.transformRequest,
    ],
    transformResponse: [
      ...axios.defaults.transformResponse,
      (data) =>
        humps.camelizeKeys(data, (key, convert) => {
          return /^[a-z0-9_]+$/.test(key) ? convert(key) : key
        }),
    ],
    ...additionalConfig,
  })

  client.interceptors.response.use(undefined, ({ response }) => {
    if (!response) {
      throw new Error('Connection error')
    }

    if (response.status === 301) {
      // update service worker in case an endpoint has been moved
      updateServiceWorker(serviceWorker)
    }

    if (
      response.status === 401 &&
      response.data.errors?.[0] === TERMS_OF_USE_NOT_ACCEPTED
    ) {
      store.dispatch(
        showTermsOfUse({
          show: true,
        })
      )
    }

    if (
      response.status === 401 &&
      response.data.message.includes(EMAIL_NOT_VERIFIED)
    ) {
      history.replace('/signup/almost-done')
    }

    if (
      response.status === 401 &&
      (response.data.errors?.[0] === SESSION_EXPIRED ||
        response.data.errors === SESSION_EXPIRED ||
        response.data.message === SIGNATURE_EXPIRED)
    ) {
      store.dispatch(signOut())
    }

    if (!customErrorHandling) {
      let errMsg

      if (response.data?.errors && Array.isArray(response.data?.errors)) {
        errMsg = response.data?.errors[0]
      } else {
        errMsg = response.data?.error || JSON.stringify(response.data)
      }

      if (typeof errMsg === 'object') {
        errMsg = JSON.stringify(errMsg)
      }
      const error = new Error(errMsg)
      error.status = response.status
      throw error
    } else {
      throw response
    }
  })

  return client
}

export default axiosClient
