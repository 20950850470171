/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable consistent-return, react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState, useRef } from 'react'
import { useHistory, matchPath } from 'react-router-dom'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import classNames from 'classnames'

import TabItem from './TabItem'
import styles from './Tabs.module.scss'
import TabContent from './TabContent'
import { TabContext } from './useTabs'
import TabRouteContent from './TabRouteContent'

const Tabs = ({
  children,
  items,
  actions,
  onClickTab: onClickTabProp,
  initialIndex,
  withBorder,
  tabOffset,
  marginBottom,
  className,
  showTitles,
}) => {
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(initialIndex)
  const [tabItems, setTabItems] = useState(items)
  const tabsContainerRef = useRef(null)

  useEffect(() => {
    if (!isEqual(items, tabItems)) {
      setTabItems(items)
    }
  }, [items])

  useEffect(() => {
    if (typeof initialIndex !== 'undefined') {
      setActiveTab(initialIndex)
    }
  }, [initialIndex])

  const findActiveTab = useCallback(() => {
    if (!tabItems?.[0]?.urlPath) {
      setActiveTab(0)
      return
    }

    const currIndex = tabItems.findIndex((item) => {
      const isMatch = matchPath(
        `${history.location.pathname}${history.location.search}`,
        {
          path: item.urlPath,
          exact: false,
        }
      )
      return !!isMatch
    })

    setActiveTab(currIndex)
  }, [history.location.pathname, history.location.search, tabItems])

  useEffect(() => {
    if (typeof initialIndex !== 'number') {
      findActiveTab()
    }
  }, [tabItems, findActiveTab])

  const onClickTab = useCallback(
    (index, tabId) => {
      setActiveTab(index)
      if (onClickTabProp) {
        onClickTabProp(index, tabId)
      }
      const item = items[index]
      if (item.url) {
        history.push(item.url)
      }
      // Scroll the container to center the selected tab
      if (tabsContainerRef.current) {
        const tabItemElement = tabsContainerRef.current.children[index]
        const containerWidth = tabsContainerRef.current.clientWidth
        const tabItemWidth = tabItemElement.clientWidth
        const scrollLeft =
          tabItemElement.offsetLeft - (containerWidth - tabItemWidth) / 2
        tabsContainerRef.current.scrollTo({
          left: scrollLeft,
          behavior: 'smooth',
        })
      }
    },
    [history, items, onClickTabProp]
  )

  const tabsData = {
    activeTabId: items[activeTab]?.id,
    activeTab: items[activeTab],
    activeTabIndex: activeTab,
  }

  const renderChildren = () => {
    if (!tabsData.activeTab) {
      return null
    }
    if (children && typeof children === 'function') {
      return React.Children.map(
        children(tabsData),
        (child, tabIndex) =>
          child &&
          React.cloneElement(child || null, {
            ...tabsData,
            setActiveTab: () => setActiveTab(tabIndex),
          })
      )
    }

    if (children && Array.isArray(children)) {
      return React.Children.map(
        children.filter(Boolean),
        (child, tabIndex) =>
          child &&
          React.cloneElement(child, {
            ...tabsData,
            setActiveTab: () => setActiveTab(tabIndex),
          })
      )
    }
  }

  const renderActions = () => {
    if (!tabsData.activeTab) {
      return null
    }

    if (!actions.length) {
      return null
    }

    return actions.map((action) => {
      if (!action.tabId.includes(tabsData.activeTabId)) return null

      const actionComponent = action.component
      return React.cloneElement(actionComponent, {
        key: action.id,
      })
    })
  }

  return (
    <TabContext.Provider value={tabsData}>
      <div className={classNames(className, styles.tabs)}>
        <div
          ref={tabsContainerRef}
          className={classNames(
            styles.tabContainer,
            {
              [styles.tabContainerBorder]: withBorder,
            },
            className
          )}
          style={
            tabOffset !== undefined
              ? {
                  width: `calc(100% + ${2 * tabOffset}px)`,
                  transform: `translate(-${tabOffset}px)`,
                  padding: `0 ${tabOffset}px`,
                  marginBottom,
                }
              : {
                  marginBottom,
                }
          }
        >
          {items.map((item, index) => (
            <div
              id={`tabItem_${index}`}
              key={item.id}
              className={classNames('tabItem', styles.tabItemContainer)}
            >
              <TabItem
                {...item}
                isActive={activeTab === index}
                onClick={() => onClickTab(index, item.id)}
                showTitles={showTitles}
              />
            </div>
          ))}
        </div>
        {renderActions()}
        {renderChildren()}
      </div>
    </TabContext.Provider>
  )
}

Tabs.propTypes = {
  className: PropTypes.string,
  tabOffset: PropTypes.number,
  marginBottom: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      url: PropTypes.string,
      urlPath: PropTypes.string,
    })
  ).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      tabId: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
      ]).isRequired,
      id: PropTypes.string.isRequired,
      component: PropTypes.element.isRequired,
    })
  ),
  onClickTab: PropTypes.func,
  initialIndex: PropTypes.number,
  withBorder: PropTypes.bool,
  showTitles: PropTypes.bool,
}

Tabs.defaultProps = {
  actions: [],
  className: '',
  tabOffset: 0,
  onClickTab: null,
  initialIndex: 0,
  withBorder: false,
  showTitles: true,
}

Tabs.TabContent = TabContent
Tabs.RouteContent = TabRouteContent

export default Tabs
