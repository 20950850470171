import { useCallback, useEffect } from 'react'
import { Nullable } from 'utils/types/common'

const getEventName = (eventName) => {
  let name = eventName
  if (Array.isArray(name)) {
    name = JSON.stringify(name)
  }
  return name
}

export function useEventListener<TEventData = any>(
  eventName: string | string[],
  eventHandler: (detail: TEventData, event: any) => void,
  element: Nullable<HTMLElement> | Window = window
) {
  const handleEvent = useCallback(
    (event) => {
      eventHandler(event.detail, event)
    },
    [eventHandler]
  )

  useEffect(() => {
    const name = getEventName(eventName)

    element?.addEventListener(getEventName(name), handleEvent)

    return () => {
      element?.removeEventListener(getEventName(eventName), handleEvent)
    }
  }, [handleEvent, eventName, element])
}

export const dispatchEvent = <TEventData = any>(
  eventName,
  value: TEventData = {} as any,
  element = window
) => {
  const name = getEventName(eventName)
  const event = new CustomEvent(name, { detail: value })
  element.dispatchEvent(event)
}
