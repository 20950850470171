import PropTypes from 'prop-types'
import {
  ErrorMessage,
  HelpMessage,
  ModalContent,
  Wrapper,
} from 'components/TransactionsBulkImportModal/BulkImportModal.styles'
import Modal from 'components/Modal'
import { ReactComponent as DataEntry } from 'assets/images/import_csv.svg'

import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BtnUploadFile from 'components/BtnUploadFile/BtnUploadFile'
import Button from 'ui/Button'
import Loader from 'components/Loader'
import CWLoader from 'components/CWLoader'
import theme from 'utils/theme'
import { getBulkImportMetricValuesSchema } from 'utils/schemas/metrics'
import DATAPOINTS_TEMPLATE_URL from 'assets/metric_template.csv'

import { Form, Formik } from 'formik'
import ImportMetricValues from './ImportMetricValues'
import {
  LoaderWrapper,
  ModalFooter,
} from './BulkImportMetricValuesModal.styles'
import { SCREENS, useBulkImportMetricValues } from './useBulkImportMetricValues'

const BulkImportMetricValuesModal = ({ metric, show, onHide }) => {
  const {
    intl,
    formRef,
    currentScreen,
    loading,
    countImported,
    uploadFile,
    isDraggingOver,
    isImportingFile,
    onUploadFile,
    onDrop,
    onDragOver,
    onDragLeave,
    onDragEnter,
    handleAddGroup,
    handleAddGroupByHandle,
    handleRemoveGroup,
    currentGroups,
    loadGroups,
    onSubmit,
    errorMsg,
    handleDeleteItemList,
    handleEditItemList,
    handleAddItemList,
    itemLists,
  } = useBulkImportMetricValues(metric, show, onHide)
  return (
    <Formik
      innerRef={formRef}
      initialValues={{ metricValues: [] }}
      validationSchema={getBulkImportMetricValuesSchema(intl)}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {(formikProps) => (
        <Form>
          <Modal show={show} onHide={onHide}>
            <Modal.Header onHide={onHide}>
              <Modal.Title>
                <Modal.H2Title>
                  <FormattedMessage id="bulkImportDataPointsModal.title" />
                </Modal.H2Title>
              </Modal.Title>
            </Modal.Header>
            <ModalContent>
              {currentScreen === SCREENS.UPLOAD_FILE && (
                <Wrapper
                  onDrop={onDrop}
                  onDragLeave={onDragLeave}
                  onDragOver={onDragOver}
                  onDragEnter={onDragEnter}
                  isDraggingOver={isDraggingOver}
                >
                  <DataEntry width="20rem" />
                  <h3>
                    <FormattedMessage
                      id="bulkImportDataPointsModal.instructionsOne"
                      values={{
                        metricName: `${metric?.name}${
                          metric?.frequency
                            ? ` (${intl.formatMessage({
                                id: `metrics.${metric?.frequency}`,
                              })})`
                            : ''
                        }`,
                      }}
                    />
                  </h3>
                  <p className="instructions-two">
                    <FormattedMessage id="bulkImportDataPointsModal.instructionsTwo" />
                  </p>

                  <BtnUploadFile
                    acceptFiles={['.csv']}
                    onUploadFile={onUploadFile}
                  >
                    <FormattedMessage id="bulkImportDataPointsModal.uploadFile" />
                  </BtnUploadFile>

                  {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}

                  <HelpMessage>
                    <FontAwesomeIcon icon={['far', 'question-circle']} />
                    <FormattedMessage
                      id="bulkImportDataPointsModal.help"
                      values={{
                        here: (
                          <a download href={DATAPOINTS_TEMPLATE_URL}>
                            <b>download here</b>
                          </a>
                        ),
                      }}
                    />
                  </HelpMessage>
                </Wrapper>
              )}

              {currentScreen === SCREENS.IMPORT_DATAPOINTS && (
                <ImportMetricValues
                  formik={formikProps}
                  handleAddGroup={handleAddGroup}
                  handleAddGroupByHandle={handleAddGroupByHandle}
                  handleRemoveGroup={handleRemoveGroup}
                  loadGroups={loadGroups}
                  currentGroups={currentGroups}
                  countImported={countImported}
                  fileName={uploadFile?.name}
                  handleDeleteItemList={handleDeleteItemList}
                  handleEditItemList={handleEditItemList}
                  handleAddItemList={handleAddItemList}
                  itemLists={itemLists}
                  metric={metric}
                />
              )}
            </ModalContent>

            {isImportingFile && (
              <LoaderWrapper>
                <Loader
                  loader={<CWLoader color={theme.colors.primaryBlue} />}
                  text={intl.formatMessage(
                    { id: 'bulkImportModal.importingCSVFile' },
                    { fileName: uploadFile?.name }
                  )}
                  legend={intl.formatMessage({
                    id: 'bulkImportModal.importingCSVFileLegend',
                  })}
                />
              </LoaderWrapper>
            )}

            {currentScreen === SCREENS.IMPORT_DATAPOINTS && (
              <ModalFooter>
                <Button onClick={onHide} secondary>
                  {intl.formatMessage({ id: 'general.cancel' })}
                </Button>
                <Button
                  type="button"
                  primary
                  onClick={formikProps.submitForm}
                  disabled={loading}
                >
                  {intl.formatMessage({
                    id: 'bulkImportDataPointsModal.addValues',
                  })}
                </Button>
              </ModalFooter>
            )}
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

BulkImportMetricValuesModal.propTypes = {
  metric: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default BulkImportMetricValuesModal
