import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useRef } from 'react'

import NumbersService from 'api/NumbersService'
import Toast from 'components/Toast'
import { groupMilestonesByState } from 'utils/functions/metrics'
import { checkMetricPermissionError } from 'utils/functions/permissions'
import { metricsKeys } from 'utils/queries/metrics'
import { MetricsMode } from 'utils/types/metrics'
import { Metric } from 'utils/types/metricsV2'

import { useGetMilestonesByMetricId } from './queries/useMilestonesQuery'
import { useMetricsMode } from './useMetricsMode'

interface Props {
  metricId: string
  metricData?: Metric
  onError?: (error: { status: number }) => void
  onSuccess?: (metric: Metric) => void
  refetchOnMount?: boolean
}

const useMetricQuery = ({
  metricId,
  metricData,
  onError,
  onSuccess,
  refetchOnMount,
}: Props) => {
  const queryClient = useQueryClient()
  const metricsMode = useMetricsMode(metricData)
  const isFounder = useRef(metricsMode === MetricsMode.FOUNDER)

  useEffect(() => {
    isFounder.current = metricsMode === MetricsMode.FOUNDER
  }, [metricsMode])

  const getMetricById = useCallback(async () => {
    try {
      const metricResponse = await NumbersService.getMetricById(metricId)

      return metricResponse
    } catch (error) {
      if (!checkMetricPermissionError(error)) {
        Toast.displayIntl('metrics.errorGettingMetric', 'error')
      }

      onError?.(error)

      throw error
    }
  }, [metricId, onError])

  const {
    data: metric,
    refetch,
    isLoading,
    isFetching,
  } = useQuery<Metric | undefined>(
    metricsKeys.getMetric(metricId),
    getMetricById,
    {
      enabled: !!metricId,
      initialData: metricData,
      refetchOnMount: refetchOnMount ?? true,
      onSuccess,
    }
  )

  const setMetric = (data) =>
    queryClient.setQueryData(metricsKeys.getMetric(metricId), data)

  const { data: fetchedMilestones, isLoading: isLoadingMilestones } =
    useGetMilestonesByMetricId(metricId, {
      staleTime: 1000,
    })

  const groupedMilestones = groupMilestonesByState({
    milestones: fetchedMilestones,
    metricId,
  })

  const hasMilestones =
    !isLoadingMilestones && fetchedMilestones && fetchedMilestones?.length > 0

  return {
    metric,
    refetchMetric: refetch,
    isRefetching: isFetching,
    isLoading,
    groupedMilestones,
    hasMilestones,
    setMetric,
  }
}

export default useMetricQuery
