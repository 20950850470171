import { useQuery } from '@tanstack/react-query'
import NumbersService from 'api/NumbersService'
import { SortDirection } from 'utils/constants'
import { metricsKeys } from 'utils/queries/metrics'

export const useGetDataPointsByMetricId = (
  {
    metricId,
    sortDirection = SortDirection.ASC,
    isSystemMetric = false,
  }: {
    metricId: string
    sortDirection?: SortDirection
    isSystemMetric?: boolean
  },
  queryOptions = {}
) => {
  return useQuery(
    metricsKeys.getDataPoints(metricId),
    async () => {
      const dataPoints = await NumbersService.getDataPoints({
        metricId,
        sortDirection,
        isSystemMetric,
      })
      return dataPoints
    },
    {
      staleTime: 20 * 1000,
      ...queryOptions,
    }
  )
}
