import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'

import { getFrequencyNames, getMetricNames } from 'utils/functions/metrics'
import Input from 'ui/Input'
import Dropdown from 'ui/Dropdown'
import BoxGroupsAndUsersChips from 'components/BoxGroupsAndUsersChips'
import InputLabel from 'ui/InputLabel'
import Toggle from 'components/Toggle'
import DropdownEntity from 'components/GroupsUsersAddInput/DropdownEntity'

import {
  AutoSuggestPortfolioCompanies,
  Separator,
  OptionalSpan,
  Legend,
  DropdownContainer,
} from '../CreateMetric.styles'
import { useCreateInvestorMetricForm } from './useCreateInvestorMetricForm'

const CreateInvestorMetricForm: React.FC = () => {
  const intl = useIntl()
  const {
    nameField,
    frequencyField,
    companiesOrPortfoliosField,
    requestDataFromCompaniesField,
    messageField,
    isCreatingMetricFromSharedMetric,
    dropdownRef,
    boxGroupsAndUsersChips,
    clearDropdown,
    getSubOptionById,
    loadCompaniesOrPortfolios,
    handleDeleteItem,
    setFieldValue,
    handleBlur,
  } = useCreateInvestorMetricForm()

  return (
    <>
      <Dropdown
        id={nameField.name}
        name={nameField.name}
        error={nameField.buildError()}
        onBlur={handleBlur}
        label={intl.formatMessage({ id: 'metrics.title' })}
        options={getMetricNames(intl)}
        placeholder={intl.formatMessage({ id: 'metrics.titlePlaceholder' })}
        getOption={(option) => option}
        value={nameField.value}
        type="input"
        withGroupOptions
        onChange={nameField.onChange}
        onSelectOption={(_, value) => {
          const subOption = getSubOptionById(value, getMetricNames(intl))
          setFieldValue(nameField.name, subOption?.label)
        }}
        showIcon={false}
        showAngleIcon={false}
        withCounter
        simulateIconSpace
        counterStyle={{ right: '2rem', top: '1.2rem' }}
        errorIconCustomPaddingRight="2.5rem"
      />

      <Separator margin="1.8rem 0 0 0" />

      <Dropdown
        id={frequencyField.name}
        name={frequencyField.name}
        label={intl.formatMessage({ id: 'metrics.frequency' })}
        placeholder={intl.formatMessage({
          id: 'metrics.frequencyPlaceholder',
        })}
        onSelectOption={(_, value) => {
          setFieldValue(frequencyField.name, value)
        }}
        options={getFrequencyNames(intl)}
        error={frequencyField.buildError()}
        value={frequencyField.value}
      />

      <div id="metric-companies-portfolios">
        <AutoSuggestPortfolioCompanies>
          {!isCreatingMetricFromSharedMetric ? (
            <>
              <DropdownContainer>
                <Dropdown
                  id={companiesOrPortfoliosField.name}
                  name={companiesOrPortfoliosField.name}
                  error={companiesOrPortfoliosField.buildError()}
                  onSelectOption={(_, __, value) => {
                    setFieldValue(companiesOrPortfoliosField.name, [
                      ...companiesOrPortfoliosField.value,
                      value,
                    ])
                    setTimeout(clearDropdown)
                  }}
                  label={intl.formatMessage({
                    id: 'metrics.holdingsFundsOrPortfolios',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'metrics.selectHoldingFundOrPortfolio',
                  })}
                  loadOptions={loadCompaniesOrPortfolios}
                  ref={dropdownRef}
                  async
                  type="input"
                  icon={['far', 'plus']}
                  iconFontSize="1.8rem"
                  confirmOnClickIcon
                  highlightEnabled
                  maxListHeight="40rem"
                  dropdownOptionPadding="0"
                  hideGroupLabelIfEmpty
                  showLoadingIndicator
                  getOption={(entity) => ({
                    id: entity.id,
                    label: entity.name,
                    value: {
                      type: entity.type,
                      name: entity.name,
                      logo: entity.logo,
                      handle: entity.groupHandle,
                    },
                  })}
                  formatSelectedOptionComponent={(option) => (
                    <DropdownEntity entity={option.value} isSelected={false} />
                  )}
                  clearOnSelect
                  clearOnPressEnter={false}
                  withGroupOptions
                  highlightSelectedOption={false}
                />
              </DropdownContainer>
              <Separator margin="1rem 0 0 0" />
            </>
          ) : (
            <Legend>
              <FormattedMessage id="metrics.receivingDataFromCompany" />
            </Legend>
          )}

          <BoxGroupsAndUsersChips
            items={boxGroupsAndUsersChips}
            handleDelete={handleDeleteItem}
            canDelete={!isCreatingMetricFromSharedMetric}
          />
        </AutoSuggestPortfolioCompanies>
      </div>

      <Separator margin="1.8rem 0 0 0" />

      {!isCreatingMetricFromSharedMetric ? (
        <Toggle
          status={requestDataFromCompaniesField.value}
          blue
          id="requestDataFromCompanies"
          name="requestDataFromCompanies"
          onChangeStatus={(value) => {
            setFieldValue('requestDataFromCompanies', value)
          }}
          legend={intl.formatMessage({
            id: 'metrics.requestDataFromCompanies',
          })}
        />
      ) : null}

      <Separator margin="2.8rem 0 0 0" />

      {!isCreatingMetricFromSharedMetric && (
        <div id="metric-message">
          <InputLabel>
            <>
              <FormattedMessage id="metrics.message" />
              <OptionalSpan>
                <FormattedMessage id="general.optional" />
              </OptionalSpan>
            </>
          </InputLabel>
          <Input
            id="metric-message"
            name={messageField.name}
            value={messageField.value}
            onChange={messageField.onChange}
            error={messageField.buildError()}
            onBlur={handleBlur}
            placeholder={intl.formatMessage({
              id: 'metrics.addMessagePlaceholder',
            })}
          />
        </div>
      )}
    </>
  )
}

export default CreateInvestorMetricForm
