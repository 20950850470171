import humps from 'humps'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { MetricSources } from 'utils/types/metrics'
import { BaseMetric } from 'utils/types/metricsV2'

export const useMetricName = (metric?: BaseMetric) => {
  const intl = useIntl()
  const metricName = useMemo(() => {
    const isCalculatedMetric = metric?.source === MetricSources.System

    return isCalculatedMetric
      ? intl.formatMessage({
          id: `portfolios.${humps.camelize(metric.name)}`,
        })
      : metric?.name
  }, [intl, metric?.source, metric?.name])

  return { metricName }
}
