import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useQuery, type InfiniteData } from '@tanstack/react-query'

import { useMetrics } from 'containers/Metrics/MetricsList/useMetrics'
import { Metric as MetricCell } from 'components/Spreadsheet/CellTemplates/MetricCellTemplate'
import { companyKeys } from 'utils/queries/companies'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { Metric } from 'utils/types/metricsV2'
import { MetricSortBy } from 'utils/constants/metrics'
import { SortDirection } from 'utils/constants'
import { getFounderCompany } from 'selectors/company'
import {
  isActingAsClient,
  isActingAsFounder,
  isActingAsFundManager,
} from 'selectors/auth'
import {
  CelesteActionItemProps,
  CelesteActionType,
} from 'components/Celeste/components/CelesteActionItem/CelesteActionItem'
import { useMetricsRoute } from 'utils/hooks/useMetricsRoute'
import { MetricsSpreadsheetMode } from 'components/UpdateMetricsGrid/MetricsSpreadsheetLogic'
import { MetricsSpreadsheetBuilder } from 'components/UpdateMetricsGrid/MetricsSpreadsheetBuilder'

type OrderedCelesteActionItemProps = CelesteActionItemProps & {
  order: number
}

export const useCelesteMetrics = () => {
  const intl = useIntl()
  const metricsRoute = useMetricsRoute()
  const { exportAllMetrics } = useMetrics()

  const founderCompany = useAppSelector(getFounderCompany)
  const isFounder = useAppSelector(isActingAsFounder)
  const isFundManager = useAppSelector(isActingAsFundManager)
  const isClient = useAppSelector(isActingAsClient)

  const getGridForFounder = useCallback(
    (metricPages: InfiniteData<{ data: Metric[] }>) => {
      if (isFounder) {
        const metrics = metricPages?.pages
          .filter((page) => !!page)
          .map((page) => page.data)
          .flat()
        const gridMetrics =
          metrics?.map<MetricCell>((metric) => ({
            id: metric.id,
            name: metric.name,
            fetchingMetric: false,
            receiverGroups: metric.senderLinks
              ?.filter((senderLink) => senderLink.receiverGroupId)
              .map((senderLink) => ({
                id: senderLink.receiverGroupId!,
              })),
          })) ?? []

        const spreadsheetLogic = new MetricsSpreadsheetBuilder(
          MetricsSpreadsheetMode.SINGLE_HOLDING,
          0,
          1,
          gridMetrics
        )
        return spreadsheetLogic.getSpreadsheet()
      }

      return undefined
    },
    [isFounder]
  )

  const { data: grid, isLoading } = useQuery({
    queryKey: companyKeys.companyMetricsWithFilters({
      companyId: founderCompany?.id,
      metricName: '',
      sortBy: MetricSortBy.NAME,
      sortDirection: SortDirection.ASC,
    }),
    select: getGridForFounder,
  })

  const celesteGroupItems = useMemo(() => {
    const items: OrderedCelesteActionItemProps[] = exportAllMetrics
      ? [
          {
            order: 4,
            name: intl.formatMessage({ id: 'metrics.exportAll' }),
            actionType: CelesteActionType.EXPORT_ALL_METRICS,
            onClick: exportAllMetrics,
          },
        ]
      : []

    if (!isFundManager) {
      items.push({
        order: 1,
        name: intl.formatMessage({ id: 'metrics.addMetric' }),
        actionType: CelesteActionType.ADD_METRIC,
        url: `${metricsRoute}/new`,
      })
    }

    if (isFundManager) {
      items.push({
        order: 1,
        name: intl.formatMessage({ id: 'metrics.addHoldingMetric' }),
        actionType: CelesteActionType.ADD_METRIC,
        url: `${metricsRoute}/new`,
      })
      items.push({
        order: 2,
        name: intl.formatMessage({ id: 'metrics.addOrganizationMetric' }),
        actionType: CelesteActionType.ADD_METRIC,
        url: `${metricsRoute}/new`,
        routeState: {
          isOrganizationMetric: true,
        },
      })
    }

    if (isFounder) {
      items.push({
        order: 3,
        name: intl.formatMessage({ id: 'metrics.updateMetrics' }),
        actionType: CelesteActionType.UPDATE_METRICS,
        url: `${metricsRoute}/update-all`,
        routeState: {
          grid,
        },
        disabled: isLoading,
      })
    }

    if (isFundManager || isClient) {
      items.push({
        order: 3,
        name: intl.formatMessage({ id: 'metrics.updateMetrics' }),
        actionType: CelesteActionType.UPDATE_METRICS,
        url: `${metricsRoute}/update-all`,
      })
    }

    return [items.sort((a, b) => a.order - b.order)]
  }, [
    intl,
    exportAllMetrics,
    isFundManager,
    isFounder,
    isClient,
    grid,
    isLoading,
    metricsRoute,
  ])

  return {
    celesteGroupItems,
  }
}
