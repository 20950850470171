import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import MilestoneComponent from 'components/Milestone'
import { useMetricsRoute } from 'utils/hooks/useMetricsRoute'
import { useGetMilestonesByMetricId } from 'utils/hooks/queries/useMilestonesQuery'
import { groupMilestonesByState } from 'utils/functions/metrics'
import { Metric, Milestone } from 'utils/types/metricsV2'
import { Color } from 'utils/theme'
import { Subtitle } from '../ShowMetric/ShowMetric.styles'
import {
  ShowMetricMilestoneWrapper,
  MilestoneWrapper,
  TitleWrapper,
  MilestoneZeroStateWrapper,
} from './ShowMetricMilestones.styles'

type RenderMilestoneGroup = {
  title: string
  titleMode?: 'dark' | 'light'
  contentMode?: 'dark' | 'light'
  milestones: Milestone[]
  isEditable?: boolean
  displayZeroState?: boolean
  zeroStateType?: string
  progressColor?: Color
  isInvestorMilestone?: boolean
}

interface Props {
  metric: Metric
}

const ShowMetricMilestones = ({ metric }: Props) => {
  const intl = useIntl()
  const history = useHistory()
  const metricsRoute = useMetricsRoute()

  const { data: fetchedMilestones, isLoading: isLoadingMilestones } =
    useGetMilestonesByMetricId(metric?.id)

  const {
    activeMilestones,
    investorsMilestones,
    reachedMilestones,
    expiredMilestones,
  } = groupMilestonesByState({
    milestones: fetchedMilestones,
    metricId: metric?.id,
  })

  const showZeroState = !isLoadingMilestones && !fetchedMilestones?.length

  if (showZeroState) {
    return (
      <ZeroState
        callToActionText={intl.formatMessage({ id: 'metrics.addMilestone' })}
        type={ZeroStateType.MILESTONES}
        callToAction={() =>
          history.push(`${metricsRoute}/${metric.id}/set-milestone`)
        }
        isCallToActionPrimaryStyle
        padding="3rem 6rem"
        disableActionIfReadOnly
        readOnlyTooltipText={intl.formatMessage({
          id: 'readOnlyMode.setMilestone',
        })}
        readOnlyTooltipPlace="bottom"
        readOnlyTooltipMaxWidth="25.2rem"
      />
    )
  }

  const renderMilestoneGroup = ({
    title,
    titleMode = 'dark',
    contentMode = 'dark',
    milestones,
    isEditable = false,
    displayZeroState = false,
    zeroStateType = '',
    progressColor,
    isInvestorMilestone,
  }: RenderMilestoneGroup) => {
    if (!milestones.length) {
      return displayZeroState ? (
        <MilestoneZeroStateWrapper>
          <MilestoneWrapper>
            <TitleWrapper>
              <Subtitle mode={titleMode}>{title}</Subtitle>
            </TitleWrapper>
            <ZeroState
              callToActionText={intl.formatMessage({
                id: 'metrics.addMilestone',
              })}
              type={zeroStateType}
              callToAction={() =>
                history.push(`${metricsRoute}/${metric.id}/set-milestone`)
              }
              isCallToActionPrimaryStyle
              padding="0rem 4.8rem"
            />
          </MilestoneWrapper>
        </MilestoneZeroStateWrapper>
      ) : null
    }

    return (
      <ShowMetricMilestoneWrapper>
        <TitleWrapper>
          <Subtitle mode={titleMode}>{title}</Subtitle>
        </TitleWrapper>
        {milestones.map((milestone) => (
          <MilestoneWrapper key={milestone.id}>
            <MilestoneComponent
              isInvestorMilestone={isInvestorMilestone}
              milestone={milestone}
              metric={metric}
              isEditable={isEditable}
              mode={contentMode}
              progressColor={progressColor}
            />
          </MilestoneWrapper>
        ))}
      </ShowMetricMilestoneWrapper>
    )
  }

  return (
    <>
      {renderMilestoneGroup({
        title: intl.formatMessage({ id: 'metrics.activeMilestones' }),
        milestones: activeMilestones,
        isEditable: true,
        displayZeroState: true,
        zeroStateType: ZeroStateType.ACTIVE_MILESTONES,
      })}
      {renderMilestoneGroup({
        title: intl.formatMessage({
          id: 'metrics.createdByInvestorMilestones',
        }),
        titleMode: 'light',
        contentMode: 'light',
        milestones: investorsMilestones,
        progressColor: 'purple',
        isInvestorMilestone: true,
      })}
      {renderMilestoneGroup({
        title: intl.formatMessage({ id: 'metrics.reachedMilestones' }),
        contentMode: 'light',
        milestones: reachedMilestones,
        progressColor: 'green',
      })}
      {renderMilestoneGroup({
        title: intl.formatMessage({ id: 'metrics.expiredMilestones' }),
        contentMode: 'light',
        milestones: expiredMilestones,
        progressColor: 'lightGray',
      })}
    </>
  )
}

export default ShowMetricMilestones
