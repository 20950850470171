import { useQuery } from '@tanstack/react-query'
import NumbersService from 'api/NumbersService'
import { metricsKeys } from 'utils/queries/metrics'

import { Milestone } from 'utils/types/metricsV2'

export const useGetMilestonesByMetricId = (
  metricId: string,
  queryOptions = {}
) => {
  return useQuery<Milestone[]>(
    metricsKeys.getMilestones(metricId),
    async () => {
      const milestones = await NumbersService.getMilestonesByMetricId(metricId)
      return milestones
    },
    {
      enabled: !!metricId,
      staleTime: 1 * 60 * 1000,
      ...queryOptions,
    }
  )
}

export const useGetMilestoneById = (milestoneId: string, queryOptions) => {
  return useQuery(
    metricsKeys.getMilestone(milestoneId),
    async () => {
      const milestone = await NumbersService.getMilestoneById(milestoneId)

      return milestone
    },
    queryOptions
  )
}
