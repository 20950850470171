import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import GroupTooltips from 'components/GroupTooltips'
import PulseIndicator from 'components/PulseIndicator'
import { SkeletonAllMetricsItem } from 'components/Skeletons/Metrics/SkeletonAllMetricsCard/SkeletonAllMetricsCard'
import Tooltip from 'components/Tooltip'
import { useMetricsSandbox } from 'containers/Metrics/SelectedMetrics/useMetricsSandbox'
import { isActingAsFounder } from 'selectors/auth'
import { DisplayMode } from 'utils/constants/metrics'
import { getDropdownOptionsForStatus } from 'utils/functions/metrics'
import { useMetricName } from 'utils/hooks/metrics/useMetricName'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import theme from 'utils/theme'
import { MetricSources } from 'utils/types/metrics'

import { IndexMetric, Metric, Milestone } from 'utils/types/metricsV2'
import MetricChart from '../MetricChart'
import MetricDataPoints from '../MetricDataPoints/MetricDataPoints'
import * as Styles from './ProfileMetric.styles'
import { useProfileMetric } from './useProfileMetric'

interface ProfileMetricProps {
  initialMetricData: IndexMetric
  onRemoveMetric: (metric: IndexMetric) => void
  onAddNewValue: (metric: IndexMetric) => void
  onEditMetric: (metric: IndexMetric) => void
  onSetMilestone: (metric: IndexMetric) => void
  onEditMilestone: (metric: IndexMetric, milestone: Milestone) => void
  onViewMetricDetail: (metricId?: string) => void
  onImportCsv: () => void
  onExportCsv: (metric: IndexMetric) => Promise<void>
  linkTo: string
  isSandboxable?: boolean
}

const ProfileMetric: React.FC<ProfileMetricProps> = ({
  initialMetricData,
  onRemoveMetric,
  onAddNewValue,
  onEditMetric,
  onSetMilestone,
  onEditMilestone,
  onViewMetricDetail,
  onImportCsv,
  onExportCsv,
  linkTo,
  isSandboxable = false,
}) => {
  const intl = useIntl()
  const isFounder = useAppSelector(isActingAsFounder)

  const {
    metric,
    isLoading,
    showFounderDataPulse,
    sharedGroups,
    isMobile,
    dataPoints,
    dataPointsContainerRef,
    width,
    activeMilestones,
    displayMode,
    onToggleDisplayMode,
    redirectToLogs,
  } = useProfileMetric({
    initialMetricData,
    linkTo,
    defaultDisplayMode: DisplayMode.VALUE,
  })

  const { metricName } = useMetricName(metric)
  const {
    isMetricInSandbox,
    toggleMetricInSandbox,
    isAddMetricToSandboxDisabled,
  } = useMetricsSandbox()

  const isCalculatedMetric = metric?.source === MetricSources.System
  const isMetricInSandboxState = isMetricInSandbox(metric!)

  const showCheckbox = isSandboxable && !!dataPoints.length && !isFounder
  const reachedChartLimit =
    !isMetricInSandboxState && isAddMetricToSandboxDisabled()

  const checkboxContent = (
    <Styles.CustomCheckbox
      id={metric!.id}
      name={metric!.id}
      onChange={() => toggleMetricInSandbox(metric as Metric)}
      checked={isMetricInSandboxState}
      isMetricInSandboxState={isMetricInSandboxState}
      disabled={reachedChartLimit}
    />
  )

  const renderCheckbox = () => {
    if (reachedChartLimit) {
      return (
        <Tooltip
          id={`metric-limit-tooltip-${metric?.id}`}
          place="bottom"
          text={intl.formatMessage({
            id: 'metrics.showInSandboxDisabled',
          })}
          delayShow={500}
          backgroundColor="white"
          color={theme.colors.darkGray}
          tooltipStyle={{ maxWidth: '24rem' }}
          parentTooltipStyles={{
            border: `1px solid ${theme.colors.veryLightGray}`,
            padding: '0.6rem 0.8rem !important',
            boxShadow: 'box-shadow: 0px 0px 15px 0px rgba(16, 21, 39, 0.10);',
          }}
        >
          {checkboxContent}
        </Tooltip>
      )
    }

    return checkboxContent
  }

  return isLoading ? (
    <Styles.MetricContainer hasDataPoints={!!dataPoints.length}>
      <SkeletonAllMetricsItem />
    </Styles.MetricContainer>
  ) : (
    <Styles.MetricContainer hasDataPoints={!!dataPoints.length}>
      <Styles.Metric>
        <Styles.MetricMetadata>
          {showCheckbox && renderCheckbox()}

          <Styles.MetricNameContainer showCheckbox={!showCheckbox}>
            <Styles.MetricName
              className="metric-name fs-exclude"
              onClick={() => onViewMetricDetail(metric?.id)}
            >
              <Tooltip
                id={`metric-name-${metric?.id}`}
                place="bottom"
                text={metricName}
                delayShow={500}
              >
                {metricName}
              </Tooltip>
            </Styles.MetricName>
            {showFounderDataPulse && (
              <Styles.ReceivingDataWrapper>
                <PulseIndicator color={theme.colors.primaryBlue} />
                <FormattedMessage id="metrics.receivingData" />
              </Styles.ReceivingDataWrapper>
            )}
            {sharedGroups.length > 0 && (
              <Styles.GroupsContainer>
                <GroupTooltips
                  className="fs-exclude"
                  groups={sharedGroups}
                  small
                  direction="left"
                  maxGroupsToDisplay={3}
                />
              </Styles.GroupsContainer>
            )}
            {isCalculatedMetric && (
              <Styles.CalculatedMetricContainer>
                <Styles.LockIcon icon={['fal', 'lock']} />
                <Styles.Subtitle>
                  {intl.formatMessage({ id: 'metrics.cwUniverseCalculated' })}
                </Styles.Subtitle>
              </Styles.CalculatedMetricContainer>
            )}
          </Styles.MetricNameContainer>
        </Styles.MetricMetadata>

        {!isMobile && displayMode === DisplayMode.VALUE && (
          <Styles.DataPointsContainer ref={dataPointsContainerRef}>
            {!!dataPoints.length && !!width && (
              <MetricDataPoints dataPoints={dataPoints} width={width} />
            )}
          </Styles.DataPointsContainer>
        )}

        {!isMobile && displayMode === DisplayMode.CHART && (
          <Styles.ChartContainer>
            {!!dataPoints.length && <MetricChart dataPoints={dataPoints} />}
          </Styles.ChartContainer>
        )}

        <Styles.ToggleOption
          active={displayMode === DisplayMode.CHART}
          onClick={onToggleDisplayMode}
          disabled={!dataPoints.length || !width}
        >
          <FontAwesomeIcon icon={['fal', 'chart-mixed']} />
        </Styles.ToggleOption>

        <Styles.OptionsDropdown
          buttonType="square"
          alignSelf="flex-start"
          buttonSize="3.2rem"
        >
          {getDropdownOptionsForStatus({
            metricData: metric!,
            onRemoveMetric,
            onAddNewValue,
            onEditMetric,
            activeMilestones,
            onSetMilestone,
            onEditMilestone,
            onViewMetricDetail,
            intl,
            redirectToLogs,
            onImportCsv,
            onExportCsv,
          })}
        </Styles.OptionsDropdown>
      </Styles.Metric>

      {isMobile && !!dataPoints.length && displayMode === DisplayMode.VALUE && (
        <Styles.DataPointsContainer ref={dataPointsContainerRef}>
          {!!width && (
            <MetricDataPoints dataPoints={dataPoints} width={width} />
          )}
        </Styles.DataPointsContainer>
      )}

      {isMobile && displayMode === DisplayMode.CHART && (
        <Styles.ChartContainer>
          {!!dataPoints.length && <MetricChart dataPoints={dataPoints} />}
        </Styles.ChartContainer>
      )}
    </Styles.MetricContainer>
  )
}

export default ProfileMetric
