import { useEffect, useLayoutEffect, useState, useRef } from 'react'
import { useWindowResize } from 'utils/hooks/windowResize'

export const useContainerWidth = () => {
  const dataPointsContainerRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)

  useLayoutEffect(() => {
    setWidth(dataPointsContainerRef.current?.clientWidth || 0)
  }, [])

  useWindowResize(() => {
    setWidth(0)
    setTimeout(() => {
      setWidth(
        (currentWidth) =>
          dataPointsContainerRef.current?.clientWidth || currentWidth
      )
    }, 0)
  })

  useEffect(() => {
    if (dataPointsContainerRef.current?.clientWidth) {
      setWidth(dataPointsContainerRef.current.clientWidth)
    }
  }, [dataPointsContainerRef.current?.clientWidth])

  return {
    width,
    dataPointsContainerRef,
  }
}
