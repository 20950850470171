import { forwardRef } from 'react'

import styles from './InfiniteScrollRef.module.scss'

interface InfiniteScrollRefProps {
  offset?: string
}

const InfiniteScrollRef = forwardRef<any, InfiniteScrollRefProps>(
  ({ offset = '50rem' }, ref) => {
    return (
      <div id="end-of-page" className={styles.relative}>
        <div
          ref={ref}
          className={styles.absolute}
          style={{
            bottom: offset,
          }}
        />
      </div>
    )
  }
)

export default InfiniteScrollRef
