import { useIntl } from 'react-intl'
import { useState, useCallback, useMemo } from 'react'
import { type InfiniteData, useQueryClient } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'

import Toast from 'components/Toast'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { DELETED_METRIC_EVENT } from 'utils/constants/events'
import { getCurrentGroupId } from 'selectors/auth'
import { useMetricsRoute } from 'utils/hooks/useMetricsRoute'
import { metricsKeys } from 'utils/queries/metrics'
import { IndexMetric, Milestone } from 'utils/types/metricsV2'

import { useObserveQuery } from 'utils/hooks/useObserveQuery'
import NumbersService from 'api/NumbersService'

export const useMetrics = () => {
  const history = useHistory()
  const [removedMetricsIds, setRemovedMetricsIds] = useState<string[]>([])
  const intl = useIntl()
  const [loading, setLoading] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationEnabled, setPaginationEnabled] = useState(true)
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const queryClient = useQueryClient()
  const { data: allMetrics } = useObserveQuery<
    InfiniteData<{
      data: IndexMetric[]
      page: any
    }>
  >(metricsKeys.getMetrics({}))

  const metricsRoute = useMetricsRoute()

  const onClickMetric = useCallback(
    (metricData: IndexMetric) => {
      history.push(`${metricsRoute}/${metricData.id}`, {
        metricData,
      })
    },
    [history, metricsRoute]
  )

  const hasMetrics = useMemo(
    () => (allMetrics?.pages.flatMap((page) => page.data)?.length ?? 0) > 0,
    [allMetrics]
  )

  const onEditMetric = useCallback(
    (metricData: IndexMetric) => {
      history.push(`${metricsRoute}/${metricData.id}/edit`, {
        metricData,
      })
    },
    [history, metricsRoute]
  )

  const onAddNewValue = useCallback(
    (metricData: IndexMetric) => {
      history.push(`${metricsRoute}/${metricData.id}/add-value`)
    },
    [history, metricsRoute]
  )

  const onGoToCompanyPage = useCallback(
    (metricData: IndexMetric) => {
      history.push(`/companies/${metricData?.subject?.id}`)
    },
    [history]
  )

  const onViewMetricDetail = (metricId: string) => {
    history.push(`${metricsRoute}/${metricId}`)
  }

  const deleteMetric = useCallback(
    async (metricId: string) => {
      try {
        await NumbersService.deleteMetric(metricId)
        queryClient.invalidateQueries(metricsKeys.getMetrics())
      } catch (error) {
        Toast.displayIntl('metrics.deleteMetricError', 'error')
      }
    },
    [queryClient]
  )

  const onSetMilestone = useCallback(
    (metricData: IndexMetric) => {
      history.push(`${metricsRoute}/${metricData.id}/set-milestone`)
    },
    [history, metricsRoute]
  )

  const onEditMilestone = useCallback(
    (metricData: IndexMetric, milestone: Milestone) => {
      history.push(
        `${metricsRoute}/${metricData.id}/edit-milestone/${milestone.id}`
      )
    },
    [history, metricsRoute]
  )

  const onRemoveMetric = (metricData: IndexMetric) => {
    dispatchEvent(DELETED_METRIC_EVENT, {
      id: metricData.id,
      hidden: true,
      metric: metricData,
    })

    Toast.displayAction({
      message: intl.formatMessage(
        { id: 'metrics.toasts.metricDeleted' },
        { metricName: metricData.name }
      ),
      action: () => {
        dispatchEvent(DELETED_METRIC_EVENT, {
          id: metricData.id,
          hidden: false,
          metric: metricData,
        })
      },
      afterClose: () => deleteMetric(metricData.id),
      label: intl.formatMessage({ id: 'common.undoDelete' }),
    })
  }

  const exportAllMetrics = useCallback(async () => {
    try {
      await NumbersService.exportAllMetrics()
    } catch (err) {
      Toast.displayIntl('metrics.exportMetricsError', 'error')
    }
  }, [])

  const exportMetric = async (metric: IndexMetric) => {
    try {
      await NumbersService.exportMetric(metric.id, metric.name)
    } catch (err) {
      Toast.display(intl.messages['metrics.exportMetricsError'], 'error')
    }
  }

  return {
    loading,
    setLoading,
    onClickMetric,
    onEditMetric,
    onGoToCompanyPage,
    onRemoveMetric,
    onAddNewValue,
    firstLoad,
    setFirstLoad,
    paginationEnabled,
    setPaginationEnabled,
    currentPage,
    setCurrentPage,
    onSetMilestone,
    onEditMilestone,
    currentGroupId,
    onViewMetricDetail,
    removedMetricsIds,
    setRemovedMetricsIds,
    exportMetric,
    exportAllMetrics: hasMetrics ? exportAllMetrics : undefined,
  }
}
