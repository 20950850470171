import { useMutation, useQueryClient } from '@tanstack/react-query'
import NumbersService from 'api/NumbersService'
import { getCurrentGroupId } from 'selectors/auth'
import { metricsKeys } from 'utils/queries/metrics'
import { LinkedMetricState } from 'utils/types/metricsV2'
import { useAppSelector } from '../reduxToolkit'

export const useLinkedMetric = (linkedMetricId?: string) => {
  const queryClient = useQueryClient()
  const currentGroupId = useAppSelector(getCurrentGroupId)

  return useMutation(
    ({
      metricId,
      includeHistoricalData,
      newState,
    }: {
      metricId: string
      includeHistoricalData?: boolean
      newState: LinkedMetricState
    }) => {
      return NumbersService.processALinkedMetric({
        id: linkedMetricId!,
        state: newState,
        groupId: currentGroupId,
        metricId,
        historicalData: includeHistoricalData,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(metricsKeys.getPendingInvitations())
        queryClient.invalidateQueries(metricsKeys.getInvitationsHistory())
      },
    }
  )
}
