import React, { useEffect } from 'react'
import { displayThousands } from 'utils/functions/number'
import { Nullable } from 'utils/types/common'
import { IndexMetric } from 'utils/types/metricsV2'
import MetricName from '../MetricName'

import * as Styles from './Tooltip.styles'
import { MOIC_NAME } from '../../types'

interface GraphPayload {
  quarter?: string
  year: string
}

export interface Graph {
  color: string
  dataKey: string
  name: string
  payload: GraphPayload
  unit: string
  value: number
}

interface TooltipProps {
  active?: boolean
  payload?: Graph[]
  metrics?: IndexMetric[]
  onActive: (active: Nullable<Graph>) => void
}

const Tooltip: React.FC<TooltipProps> = ({
  active,
  payload: graphs,
  metrics,
  onActive,
}) => {
  const hasData = graphs && graphs[0]
  const quarter = hasData ? graphs[0].payload.quarter : ''
  const year = hasData ? graphs[0].payload.year : ''
  const date: string = quarter === '' ? year : `Q${quarter}, ${year}`

  const metricsById = metrics?.reduce(
    (acc, metric) => ({ ...acc, [metric.id]: metric }),
    {}
  )

  useEffect(() => {
    if (active && hasData && graphs[0]) {
      onActive(graphs[0])
    } else {
      onActive(null)
    }
  }, [active, graphs, hasData, onActive])

  return active && graphs && graphs.length ? (
    <Styles.Tooltip>
      <Styles.Date>{date}</Styles.Date>
      <Styles.List>
        {graphs?.map(({ color, name, value, dataKey }) => {
          const moicValue = name === MOIC_NAME
          const formattedValue = moicValue
            ? value.toFixed(2)
            : displayThousands(value)
          const metric = metricsById?.[dataKey]

          return (
            <Styles.Item key={metric.id}>
              <Styles.Metric>
                <Styles.DotReference fill={color} />
                <MetricName metric={metric} size="1.2rem" />
              </Styles.Metric>
              <Styles.Value>
                {moicValue ? `${formattedValue} x` : formattedValue}
              </Styles.Value>
            </Styles.Item>
          )
        })}
      </Styles.List>
    </Styles.Tooltip>
  ) : null
}

export default Tooltip
