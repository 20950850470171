import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from '@tanstack/react-query'
import { useLocation } from 'react-router-dom'

import Toast from 'components/Toast'
import useMetricGroups from 'utils/hooks/useMetricGroups'
import useEffectSkipFirstRender from 'utils/hooks/useEffectSkipFirstRender'
import { metricsKeys } from 'utils/queries/metrics'
import { DataPoint, Metric } from 'utils/types/metricsV2'
import NumbersService from 'api/NumbersService'

const useShareDataPoints = (closeDrawer) => {
  const queryClient = useQueryClient()
  const intl = useIntl()
  const location = useLocation<{
    dataPointsToShare?: DataPoint[]
    metric?: Metric
  }>()
  const { dataPointsToShare, metric } = location.state ?? {}
  const [dataPoints, setDataPoints] = useState<DataPoint[] | undefined>(
    dataPointsToShare
  )
  const [isValidForm, setIsValidForm] = useState(false)
  const [error, setError] = useState({})

  const {
    handleAddGroup,
    handleAddGroupByHandle,
    handleRemoveGroup,
    loadGroups,
    currentGroups,
    clear,
    searchText: groupSearchText,
  } = useMetricGroups()

  useEffect(() => {
    setDataPoints(dataPointsToShare)
  }, [dataPointsToShare])

  useEffectSkipFirstRender(() => {
    if (currentGroups?.length > 0) {
      setIsValidForm(true)
      setError({ message: '' })
    } else {
      setError({ message: intl.messages['metrics.enterAtLeastOneGroup'] })
      setIsValidForm(false)
    }
  }, [currentGroups, setIsValidForm, setError, intl])

  const onSubmitForm = async (event) => {
    event.preventDefault()
    try {
      if (dataPoints?.length && metric) {
        await NumbersService.bulkShareDataPoints({
          dataPointIds: dataPoints.map((dp) => dp.id),
          sharedGroups: currentGroups.map((group) => group.id),
        })

        queryClient.invalidateQueries(metricsKeys.getDataPoints(metric.id))

        Toast.displayIntl('metrics.valuesSharedSuccessfully')
      }
      closeDrawer()
    } catch {
      Toast.displayIntl('editMetric.error', 'error')
    }
  }

  const onRemoveValue = (dataPoint: DataPoint) => {
    setDataPoints((currDataPoints) =>
      currDataPoints?.filter((dp) => dp.id !== dataPoint.id)
    )
  }

  return {
    metric,
    onSubmitForm,
    handleAddGroup,
    handleAddGroupByHandle,
    handleRemoveGroup,
    loadGroups,
    currentGroups,
    clear,
    intl,
    dataPoints,
    onRemoveValue,
    isValidForm,
    error,
    groupSearchText,
  }
}

export default useShareDataPoints
