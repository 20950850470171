import type { IntlShape } from 'react-intl'
import type { LegendType } from 'recharts'
import type { CurveType } from 'recharts/types/shape/Curve'
import humps from 'humps'

import { color } from 'utils/functions/colors'
import { MetricSources } from 'utils/types/metrics'
import theme, { DataVisualizationColor } from 'utils/theme'

import { Metric } from 'utils/types/metricsV2'
import {
  BarChartConfig,
  CHART_MODE,
  ChartConfig,
  DEFAULT_CHART_Y_AXIS_ID,
  LineChartConfig,
} from '../types'

export const getChartBar = ({
  metricId,
  metricName,
  metricSource,
  intl,
  colors,
  config,
}: {
  metricId: string
  metricName: string
  metricSource: MetricSources
  intl: IntlShape
  colors: Record<string, DataVisualizationColor>
  config: ChartConfig
}): BarChartConfig => {
  const radius: number = 4
  const barSize: number = 8
  const legendType: LegendType = 'circle'
  const barColor: string = colors[metricId]
    ? color(colors[metricId], 1, 'dataVisualizationColors')({ theme })
    : ''

  return {
    radius,
    dataKey: metricId,
    barSize,
    fill: barColor,
    yAxisId: config.yAxisId || DEFAULT_CHART_Y_AXIS_ID,
    legendType,
    name:
      metricSource === MetricSources.Custom
        ? metricName
        : intl.formatMessage({
            id: `portfolios.${humps.camelize(metricName)}`,
          }),
  }
}

export const getChartLine = ({
  metricId,
  metricName,
  metricSource,
  intl,
  colors,
  config,
}: {
  metricId: string
  metricName: string
  metricSource: MetricSources
  intl: IntlShape
  colors: Record<string, DataVisualizationColor>
  config: ChartConfig
}): LineChartConfig => {
  const legendType: LegendType = 'line'
  const type: CurveType = 'linear'
  const lineColor: string = colors[metricId]
    ? color(colors[metricId], 1, 'dataVisualizationColors')({ theme })
    : ''
  const strokeDasharray: string =
    config.mode === CHART_MODE.DOTTED_LINE ? '4 4' : ''
  const name =
    metricSource === MetricSources.Custom
      ? metricName
      : intl.formatMessage({
          id: `portfolios.${humps.camelize(metricName)}`,
        })

  return {
    isAnimationActive: true,
    type,
    dataKey: metricId,
    yAxisId: config.yAxisId || DEFAULT_CHART_Y_AXIS_ID,
    legendType,
    name,
    strokeDasharray,
    stroke: lineColor,
    strokeWidth: 2,
  }
}

export const mockDataVisualizationColors = (
  metrics: Metric[]
): Record<string, DataVisualizationColor> => {
  const colors: Record<string, DataVisualizationColor> = {}
  const keys = Object.keys(theme.dataVisualizationColors)

  metrics.forEach((metric) => {
    const randomKey = keys[Math.floor(Math.random() * keys.length)]
    colors[metric.id] = randomKey as DataVisualizationColor
  })

  return colors
}
