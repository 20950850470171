import axios from 'axios'
import dayjs from 'dayjs'
import humps from 'humps'
import { store } from 'store'

import { signOut } from 'features/authSlice'
import { SortDirection } from 'utils/constants'
import { METRICS_PAGE_SIZE } from 'utils/constants/metrics'
import { shouldHoldingSupportMetrics } from 'utils/functions/metrics'
import { MetricsNormalizer } from 'utils/functions/normalizers/metricsNormalizer'

import { getCurrentGroupId } from 'selectors/auth'
import { downloadBlob } from 'utils/functions/files'
import { MetricsFilters } from 'utils/queries/metrics'
import {
  IndexMetricApi,
  LinkedMetricIndexApi,
  MetricApi,
} from 'utils/types/api/metrics'
import { MetricSources } from 'utils/types/metrics'
import {
  DataPoint,
  IndexMetric,
  LinkedMetric,
  LinkedMetricState,
  Metric,
  Milestone,
} from 'utils/types/metricsV2'
import { SubjectMatterType } from './UpdateService'

type SubjectData = {
  id: string
  type: string
  groupId?: string
}

type CreateMetric = {
  name: string
  frequency?: string
  groupId?: string
  linkData?: {
    state: string
    message?: string
    sharedGroupsIds?: string[]
  }
  subjectData?: SubjectData[]
}

const metricsWithLinksQuery: {
  aggregation: {}[]
} = {
  aggregation: [
    {
      $lookup: {
        from: 'link',
        localField: 'metric_string_id',
        foreignField: 'receiver_metric_id',
        pipeline: [{ $sort: { created_at: -1 } }, { $limit: 1 }],
        as: 'receiver_links',
      },
    },
    {
      $lookup: {
        from: 'link',
        localField: 'metric_string_id',
        foreignField: 'sender_metric_id',
        as: 'sender_links',
      },
    },
    {
      $addFields: {
        sender_metric_id: {
          $map: {
            input: '$receiver_links',
            as: 'r',
            in: { $toObjectId: '$$r.sender_metric_id' },
          },
        },
      },
    },
    {
      $lookup: {
        from: 'metric',
        localField: 'sender_metric_id',
        foreignField: '_id',
        as: 'sender_metrics',
      },
    },
    {
      $addFields: {
        receiver_metric_id: {
          $map: {
            input: '$sender_links',
            as: 'r',
            in: { $toObjectId: '$$r.receiver_metric_id' },
          },
        },
      },
    },
    {
      $lookup: {
        from: 'metric',
        localField: 'receiver_metric_id',
        foreignField: '_id',
        as: 'receiver_metrics',
      },
    },
  ],
}

export type CreateMetricResponse = {
  _id: string
  dataPoints?: string[]
  formula?: string
  formulaArgs?: string[]
  metadata: {
    calculationType?: string
    importUrl?: string
    importParams?: {
      date: string
      max: number
      count: number
    }
    template?: boolean
    importHeaders?: any[]
  }
  name: string
  permissions: { entityId: string; read: boolean; write: boolean }[]
  subjectId: string
  subjectType: string
}

type CreateMetricData = {
  name: string
  frequency?: string
  requestDataFromCompanies?: boolean
  message?: string
  companiesOrPortfolios?: {
    id: string
    classType: string
    portfolioCompanies?: any[]
    groupId?: string
    name: string
  }[]
  sharedGroups?: { id: string }[]
}

const keysToIgnoreDecamelize = [
  'foreignField',
  'localField',
  '$toObjectId',
  '$addFields',
  '$toString',
  '$addToSet',
  '$toLower',
]

const customDecamelize = (object) => {
  if (
    object &&
    typeof object === 'object' &&
    !(object instanceof FormData) &&
    !(object instanceof File)
  ) {
    return humps.decamelizeKeys(object, (key, convert) =>
      keysToIgnoreDecamelize.includes(key) ? key : convert(key)
    )
  }
  return object
}

class NumbersService {
  static axiosClient = () => {
    const { userSession } = store.getState().auth

    const headers = {
      Authorization: `Bearer ${userSession?.accessToken}`,
      'Auth-Entity': userSession?.currentGroupId,
    }

    const client = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      headers,
      transformRequest: [
        (data) => customDecamelize(data),
        ...(axios.defaults.transformRequest as any[]),
      ],
      transformResponse: [
        ...(axios.defaults.transformResponse as any[]),
        (data) =>
          humps.camelizeKeys(data, (key, convert) => {
            return /^[a-z0-9_]+$/.test(key) ? convert(key) : key
          }),
      ],
    })

    client.interceptors.response.use(undefined, ({ response }) => {
      if (!response) {
        throw new Error('Connection error')
      }

      if (response.status === 401) {
        store.dispatch(signOut())
      }
    })

    return client
  }

  static async createMetric(data: CreateMetricData) {
    let metric: CreateMetric
    const currentGroupId = getCurrentGroupId(store.getState())

    if (data.companiesOrPortfolios) {
      const sharedGroupsIds = data?.sharedGroups?.map((group) => group.id) || []
      const state =
        sharedGroupsIds.length > 0
          ? LinkedMetricState.SHARED
          : LinkedMetricState.REQUESTED

      const subjectData: SubjectData[] = [
        ...data.companiesOrPortfolios
          .filter(
            (portfolioCompany) =>
              portfolioCompany.classType === SubjectMatterType.COMPANY
          )
          .map((element) => ({
            id: element.id,
            type: element.classType,
            groupId: element.groupId,
            metadata: {
              subjectName: element.name,
              source: MetricSources.Custom,
            },
          })),
        ...data.companiesOrPortfolios
          .map((element) =>
            (
              element.portfolioCompanies?.filter((portfolioCompany) =>
                shouldHoldingSupportMetrics(
                  portfolioCompany.holding,
                  currentGroupId
                )
              ) || []
            ).map((portfolioCompany) => ({
              id: portfolioCompany.holding?.id,
              type: portfolioCompany.holding?.classType,
              groupId: portfolioCompany.holding?.groupId,
              metadata: {
                subjectName: portfolioCompany.holding?.name,
                source: MetricSources.Custom,
              },
            }))
          )
          .flat(),
      ]
        .filter(Boolean)
        .filter((element) => element !== undefined)

      metric = {
        name: data.name,
        frequency: data.frequency?.toLowerCase(),
        groupId: currentGroupId,
        ...((sharedGroupsIds.length ||
          (state === LinkedMetricState.REQUESTED &&
            data.requestDataFromCompanies)) && {
          linkData: {
            state,
            sharedGroupsIds,
            message: data.message,
          },
        }),
        subjectData: subjectData as SubjectData[],
      }

      const response = await this.axiosClient().post<MetricApi[]>(
        '/api/numbers/metrics/create',
        metric
      )
      const normalizedResponse = response.data.map((metricApi) =>
        MetricsNormalizer.metric(metricApi)
      )
      return normalizedResponse
    }

    return undefined
  }

  static async deleteMetric(metricId: string) {
    return this.axiosClient().delete(`/api/numbers/metrics/${metricId}`)
  }

  static async exportMetric(metricId: string, metricName: string) {
    const response = await this.axiosClient().get(
      `/api/numbers/metrics/${metricId}/export`
    )

    const fileName = `metric-${metricName}-${dayjs().format('hhmmss')}.csv`

    downloadBlob(fileName, new File([response.data], fileName))
  }

  static exportAllMetrics = async () => {
    const response = await this.axiosClient().get(
      `/api/numbers/metrics/export_all`
    )
    const fileName = `metrics-${dayjs().format('hhmmss')}.csv`

    downloadBlob(fileName, new File([response.data], fileName))
  }

  static async editDataPoint(dataPoint: DataPoint) {
    return this.axiosClient().put(`/api/numbers/data_points/${dataPoint.id}`, {
      $set: {
        timestamp: dataPoint.date,
        value: Number(dataPoint.value),
        sharedGroups: dataPoint.sharedGroups,
      },
    })
  }

  static async getMetrics({
    page = 1,
    pageSize = METRICS_PAGE_SIZE,
    metricName = '',
    sortDirection,
    subjectId,
    metricIds,
    sortBy,
    includeLinkedMetrics = true,
  }: MetricsFilters & {
    page?: number
    pageSize?: number
    sortDirection?: SortDirection
    includeLinkedMetrics?: boolean
  }): Promise<IndexMetric[]> {
    const getMetricsWithLinksQuery = includeLinkedMetrics
      ? [...metricsWithLinksQuery.aggregation]
      : []
    const { userSession } = store.getState().auth
    const { currentGroupId } = userSession
    const direction = sortDirection === SortDirection.ASC ? 1 : -1
    const filters: {}[] = [
      {
        $or: [
          { name: { $regex: metricName, $options: 'i' } },
          { 'metadata.alias': { $regex: metricName, $options: 'i' } },
          { 'metadata.subject_name': { $regex: metricName, $options: 'i' } },
        ],
      },
      { group_id: currentGroupId },
    ]

    const defaultSortKey = sortBy || 'metadata.subject_name'

    if (subjectId) {
      filters.push({
        subject_id: subjectId,
      })
    }

    if (metricIds) {
      filters.push({
        $expr: {
          $in: [
            '$_id',
            metricIds.map((metricId) => ({ $toObjectId: metricId })),
          ],
        },
      })
    }

    getMetricsWithLinksQuery.unshift(
      {
        $match: {
          $and: filters,
        },
      },
      {
        $addFields: {
          metric_string_id: { $toString: '$_id' },
        },
      }
    )

    getMetricsWithLinksQuery.push(
      {
        $lookup: {
          from: 'data_point',
          localField: 'data_points',
          foreignField: '_id',
          as: 'data_point_objects',
        },
      },

      {
        $addFields: {
          sortField: { $toLower: `$${defaultSortKey}` },
        },
      },
      {
        $sort: {
          sortField: direction || 1,
          _id: 1,
        },
      },
      { $skip: (page - 1) * pageSize },
      { $limit: pageSize }
    )

    const { data: metrics } = await this.axiosClient().post<
      any,
      { data: IndexMetricApi[] }
    >(`/api/numbers/metrics/get`, {
      aggregation: getMetricsWithLinksQuery,
    })

    return MetricsNormalizer.metrics(metrics)
  }

  static async getMetricById(metricId: string): Promise<Metric | undefined> {
    const getMetricByIdQuery = [...metricsWithLinksQuery.aggregation]

    getMetricByIdQuery.unshift(
      {
        $match: {
          $expr: { $eq: ['$_id', { $toObjectId: metricId }] },
        },
      },
      {
        $addFields: {
          metric_string_id: metricId,
        },
      }
    )

    const { data: metric } = await this.axiosClient().post<
      any,
      { data: MetricApi[] }
    >(`/api/numbers/metrics/get`, {
      aggregation: getMetricByIdQuery,
    })

    if (metric.length) {
      return MetricsNormalizer.metric(metric[0])
    }

    return undefined
  }

  static async updateMetric(
    metricId: string,
    metric: Partial<Metric>
  ): Promise<Metric> {
    const { data } = await this.axiosClient().put(
      `/api/numbers/metrics/${metricId}`,
      {
        $set: {
          name: metric.name,
          frequency: metric.frequency,
        },
      }
    )

    return MetricsNormalizer.metric(data)
  }

  static async addDataPoint({
    metricId,
    dataPoint,
    sharedGroupsIds,
  }: {
    metricId: string
    dataPoint: Omit<DataPoint, 'id'>
    sharedGroupsIds?: string[]
  }): Promise<DataPoint> {
    const currentGroupId = getCurrentGroupId(store.getState())
    const { data: newDataPoint } = await this.axiosClient().post(
      `/api/numbers/data_points/create`,
      {
        metricId,
        value: dataPoint.value,
        timestamp: dataPoint.date,
        sharedGroups: sharedGroupsIds,
        groupId: currentGroupId,
      }
    )

    return MetricsNormalizer.dataPoints([newDataPoint])[0]
  }

  static getDataPoints = async ({
    metricId,
    sortDirection = SortDirection.ASC,
    isSystemMetric,
  }: {
    metricId: string
    sortDirection?: SortDirection
    isSystemMetric?: boolean
  }): Promise<DataPoint[]> => {
    const direction = sortDirection === SortDirection.ASC ? 1 : -1
    const { data: dataPoints } = await this.axiosClient().post(
      `/api/numbers/data_points/get`,
      {
        aggregation: [
          {
            $match: {
              metricId,
            },
          },
          {
            $sort: {
              timestamp: direction,
            },
          },
        ],
      }
    )
    return MetricsNormalizer.dataPoints(dataPoints, isSystemMetric)
  }

  static archiveDataPoints = async (
    dataPointIds: string[],
    isArchived: boolean
  ) => {
    await this.axiosClient().post(`/api/numbers/data_points/bulk_update`, {
      dataPoints: dataPointIds,
      updateData: { $set: { 'metadata.archived': isArchived } },
    })
  }

  static bulkShareDataPoints = async ({
    dataPointIds,
    sharedGroups,
  }: {
    dataPointIds: string[]
    sharedGroups: string[]
  }) => {
    await this.axiosClient().post(`/api/numbers/data_points/bulk_update`, {
      dataPoints: dataPointIds,
      updateData: {
        $addToSet: {
          sharedGroups: { $each: sharedGroups },
        },
      },
    })
  }

  static deleteDataPoint = async (dataPointId: string) => {
    await this.axiosClient().delete(`/api/numbers/data_points/${dataPointId}`)
  }

  static addMilestone = async (
    metricId: string,
    milestoneData: Partial<Milestone>
  ) => {
    const { data: milestone } = await this.axiosClient().post(
      `/api/numbers/milestones/create`,
      {
        metricId,
        timestamp: milestoneData.timestamp,
        ...milestoneData,
      }
    )
    return milestone
  }

  static getMilestonesByMetricId = async (
    metricId: string
  ): Promise<Milestone[]> => {
    const { data: milestones } = await this.axiosClient().post(
      `/api/numbers/milestones/get`,
      {
        query: {
          metricId,
        },
      }
    )

    return MetricsNormalizer.milestones(milestones)
  }

  static getMilestoneById = async (milestoneId: string): Promise<Milestone> => {
    const { data: milestone } = await this.axiosClient().get(
      `/api/numbers/milestones/${milestoneId}`
    )

    return MetricsNormalizer.milestones([milestone])[0]
  }

  static editMilestone = async (milestoneId: string, data: any) => {
    const { data: milestone } = await this.axiosClient().put(
      `/api/numbers/milestones/${milestoneId}`,
      {
        $set: {
          value: data.value,
          timestamp: data.timestamp,
          shared: data.shared,
          notifyValueReached: data.notifyValueReached,
          notifyPercentageReached: data.notifyPercentageReached,
        },
      }
    )
    return milestone
  }

  static deleteMilestone = async (milestoneId: string) => {
    await this.axiosClient().delete(`/api/numbers/milestones/${milestoneId}`)
  }

  static bulkCreateDataPoints = async (
    metricId: string,
    dataPoints: Pick<DataPoint, 'date' | 'value' | 'sharedGroups'>[]
  ) => {
    const currentGroupId = getCurrentGroupId(store.getState())
    const { data } = await this.axiosClient().post(
      `/api/numbers/metrics/${metricId}/import`,
      {
        data_points: dataPoints.map((dataPoint) => ({
          groupId: currentGroupId,
          value: dataPoint.value,
          timestamp: dataPoint.date,
          sharedGroups: dataPoint.sharedGroups,
        })),
      }
    )
    return data
  }

  static getLinkedMetrics = async ({
    status,
    page = 1,
    pageSize = METRICS_PAGE_SIZE,
    receiverGroupId,
    senderGroupId,
  }: {
    status: LinkedMetricState[]
    page?: number
    pageSize?: number
    receiverGroupId?: string
    senderGroupId?: string
  }): Promise<LinkedMetric[]> => {
    const filters: {}[] = [{ state: { $in: status } }]

    if (receiverGroupId) {
      filters.push({ receiverGroupId })
    }

    if (senderGroupId) {
      filters.push({ senderGroupId })
    }

    const { data: linkedMetrics } = await this.axiosClient().post<
      any,
      { data: LinkedMetricIndexApi[] }
    >(`/api/numbers/links/get`, {
      aggregation: [
        {
          $match: {
            $and: filters,
          },
        },
        { $skip: (page - 1) * pageSize },
        { $limit: pageSize },
      ],
    })

    return MetricsNormalizer.linkedMetricsIndex(linkedMetrics)
  }

  static processALinkedMetric = async ({
    id,
    ...payload
  }: {
    state: LinkedMetricState
    id: string
    groupId?: string
    metricId?: string
    historicalData?: boolean
  }) => {
    await this.axiosClient().post(`/api/numbers/links/${id}/process`, {
      ...payload,
    })
  }

  static getMetricsWithoutLinksForAClient = async ({
    senderGroupId,
    receiverGroupId,
    metricName = '',
  }: {
    senderGroupId: string
    receiverGroupId: string
    metricName?: string
  }): Promise<IndexMetric[]> => {
    const { data: metrics } = await this.axiosClient().post<
      any,
      { data: IndexMetricApi[] }
    >(`/api/numbers/metrics/get`, {
      aggregation: [
        {
          $match: {
            groupId: senderGroupId,
            name: { $regex: metricName, $options: 'i' },
          },
        },
        {
          $lookup: {
            from: 'link',
            let: { fmid: { $toString: '$_id' } },
            pipeline: [
              {
                $match: {
                  $expr: {
                    $and: [
                      {
                        $eq: ['$sender_metric_id', '$$fmid'],
                      },
                      {
                        $ne: ['$receiver_group_id', receiverGroupId],
                      },
                      {
                        $not: {
                          $in: [
                            '$state',
                            [
                              LinkedMetricState.UNSHARED,
                              LinkedMetricState.SHARE_DENIED,
                            ],
                          ],
                        },
                      },
                    ],
                  },
                },
              },
            ],
            as: 'm_ref',
          },
        },
        {
          $addFields: {
            metric_string_id: { $toString: '$_id' },
          },
        },
        {
          $lookup: {
            from: 'link',
            localField: 'metric_string_id',
            foreignField: 'sender_metric_id',
            as: 'not_linked',
          },
        },
        {
          $match: {
            $or: [{ not_linked: { $eq: [] } }, { m_ref: { $ne: [] } }],
          },
        },
      ],
    })

    return MetricsNormalizer.metrics(metrics.map((metric) => metric))
  }

  static getMetricsByCompanyIdThatAreNotReceivingData = async ({
    metricName = '',
    companyId,
  }: {
    metricName?: string
    companyId: string
  }) => {
    const currentGroupId = getCurrentGroupId(store.getState())
    const { data: metrics } = await this.axiosClient().post<
      any,
      { data: IndexMetricApi[] }
    >('/api/numbers/metrics/get', {
      aggregation: [
        {
          $match: {
            subject_id: companyId,
            name: { $regex: metricName, $options: 'i' },
            groupId: currentGroupId,
          },
        },

        {
          $lookup: {
            from: 'link',
            let: { imid: { $toString: '$_id' } },
            pipeline: [
              {
                $match: {
                  $expr: {
                    $and: [
                      { $eq: ['$receiver_metric_id', '$$imid'] },
                      {
                        $or: [
                          { $eq: ['$sender_metric_id', null] },

                          { $eq: ['$state', LinkedMetricState.UNSHARED] },
                        ],
                      },
                    ],
                  },
                },
              },
            ],

            as: 'm_ref',
          },
        },
        {
          $addFields: {
            metric_string_id: { $toString: '$_id' },
          },
        },
        {
          $lookup: {
            from: 'link',
            localField: 'metric_string_id',
            foreignField: 'receiver_metric_id',
            as: 'not_linked',
          },
        },
        {
          $match: {
            $or: [{ not_linked: { $eq: [] } }, { m_ref: { $ne: [] } }],
          },
        },
      ],
    })

    return metrics
  }

  static async sendRequestToFounder({
    senderGroupId,
    receiverMetricId,
  }: {
    senderGroupId: string
    receiverMetricId: string
  }) {
    const currentGroupId = getCurrentGroupId(store.getState())
    const { data: linkedMetric } = await this.axiosClient().post<
      any,
      {
        data: LinkedMetricIndexApi
      }
    >(`/api/numbers/links/create`, {
      receiverMetricId,
      senderGroupId,
      receiverGroupId: currentGroupId,
      state: LinkedMetricState.REQUESTED,
    })

    return MetricsNormalizer.linkedMetricsIndex([linkedMetric])[0]
  }

  static async shareMetric({
    senderMetricId,
    receiverGroupId,
  }: {
    senderMetricId: string
    receiverGroupId: string
  }) {
    const currentGroupId = getCurrentGroupId(store.getState())
    const { data: linkedMetric } = await this.axiosClient().post<
      any,
      {
        data: LinkedMetricIndexApi
      }
    >(`/api/numbers/links/create`, {
      senderMetricId,
      senderGroupId: currentGroupId,
      receiverGroupId,
      state: LinkedMetricState.SHARED,
    })

    return MetricsNormalizer.linkedMetricsIndex([linkedMetric])[0]
  }

  static async updateReceiveData(linkId: string, receiveData: boolean) {
    await this.axiosClient().put(`/api/numbers/links/${linkId}`, {
      $set: {
        receiveData,
      },
    })
  }

  static async updateMetrics({ subjectId, newName }) {
    await this.axiosClient().post(`/api/numbers/metrics/bulk_update`, {
      query: {
        subject_id: subjectId,
      },
      update: {
        $set: { 'metadata.subject_name': newName },
      },
    })
  }
}

export default NumbersService
