import { FormattedMessage, useIntl } from 'react-intl'

import Separator from 'ui/Separator'
import InfiniteScroll from 'components/InfiniteScrollRef/InfiniteScroll'
import SkeletonSelectMetrics from 'components/Skeletons/Metrics/SkeletonSelectMetrics'
import { METRICS_PAGE_SIZE } from 'utils/constants/metrics'
import { HoldingMetricOption } from './MetricOption'
import { useMetricsSelector } from './useMetricsSelector'

import * as Styles from './MetricsSelector.styles'

export interface MetricsSelectorFilters {
  subjectId?: string
}

interface Props {
  disabled?: boolean
  filters?: MetricsSelectorFilters
}

const MetricsSelector = ({ disabled, filters }: Props) => {
  const intl = useIntl()
  const {
    metrics,
    iconConfig,
    search,
    inputRef,
    containerRef,
    showMenu,
    isLoading,
    onFocus,
    onChange,
    onClickOutside,
    fetchNextMetricsPage,
    onIconClick,
  } = useMetricsSelector({ filters })

  return (
    <Styles.Container ref={containerRef}>
      <Styles.Input
        disabled={disabled}
        ref={inputRef}
        value={search}
        onChange={onChange}
        onPressEscape={onClickOutside}
        placeholder={
          disabled
            ? ''
            : intl.formatMessage({
                id: 'metrics.metricsSelect.placeholder',
              })
        }
        onFocus={onFocus}
        icon={disabled ? undefined : iconConfig.icon}
        iconFontSize={iconConfig.size ?? '1.6rem'}
        onClickIcon={onIconClick}
      />
      <Styles.MenuContainer id="metrics-menu" visible={showMenu}>
        {showMenu && (
          <>
            {metrics.map((metric) => {
              return <HoldingMetricOption metric={metric} key={metric.id} />
            })}
            {!isLoading && metrics.length === 0 && (
              <Styles.ZeroState>
                <FormattedMessage id="metrics.metricsSelect.noMetrics" />
              </Styles.ZeroState>
            )}
            {isLoading && (
              <div style={{ height: '23rem' }}>
                <Separator height="0.8rem" />
                <SkeletonSelectMetrics />
                <Separator height="0.8rem" />
                <SkeletonSelectMetrics />
                <Separator height="0.8rem" />
                <SkeletonSelectMetrics />
                <Separator height="0.8rem" />
                <SkeletonSelectMetrics />
                <Separator height="0.8rem" />
                <SkeletonSelectMetrics />
              </div>
            )}
          </>
        )}
        <InfiniteScroll
          enabled
          fetchNextPage={fetchNextMetricsPage}
          pageSize={METRICS_PAGE_SIZE}
        />
      </Styles.MenuContainer>
    </Styles.Container>
  )
}

export default MetricsSelector
