/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams, useLocation } from 'react-router-dom'
import Drawer from 'components/Drawer'
import { useGroupQuery } from 'utils/hooks/queries/useGroupQuery'

import Toast from 'components/Toast'
import Button from 'ui/Button'
import Dropdown from 'ui/Dropdown'
import Checkbox from 'components/Checkbox'
import Separator from 'ui/Separator'
import { useLinkedMetric } from 'utils/hooks/queries/useLinkedMetric'
import { LinkedMetric, LinkedMetricState } from 'utils/types/metricsV2'
import NumbersService from 'api/NumbersService'

import { CTA, CheckboxWrapper } from './AddGroupToExistingMetric.styles'

interface Props {
  closeDrawer: () => void
  isOpen: boolean
}

const AddGroupToExistingMetric = ({ closeDrawer, isOpen }: Props) => {
  const [form, setForm] = useState({
    metric: {
      label: '',
      value: '',
      id: undefined,
    },
    includeHistory: true,
  })
  const intl = useIntl()
  const { linkedMetricId } = useParams<{
    linkedMetricId: string
  }>()
  const { state } = useLocation<{
    linkedMetric: LinkedMetric
  }>()
  const { mutateAsync: acceptLinkedMetric } = useLinkedMetric(linkedMetricId)
  const { data: receiverGroup } = useGroupQuery(
    state?.linkedMetric?.receiverGroupId ?? ''
  )

  const loadMetrics = useCallback(
    async (inputSearch = '') => {
      const { linkedMetric } = state

      if (linkedMetric?.receiverGroupId && linkedMetric?.senderGroupId) {
        const metrics = await NumbersService.getMetricsWithoutLinksForAClient({
          metricName: inputSearch,
          receiverGroupId: linkedMetric.receiverGroupId,
          senderGroupId: linkedMetric.senderGroupId,
        })

        return metrics.map((metric) => ({
          id: metric.id,
          label: metric.name,
        }))
      }

      return []
    },
    [state?.linkedMetric]
  )

  const onChangeCheckbox = (event) => {
    setForm((currForm) => ({
      ...currForm,
      includeHistory: event?.target?.checked,
    }))
  }

  const onChangeMetric = (_, __, value) => {
    setForm((currForm) => ({ ...currForm, metric: value }))
  }

  const onConfirm = async () => {
    try {
      await acceptLinkedMetric({
        metricId: form.metric.id!,
        newState: LinkedMetricState.REQUEST_ACCEPTED,
        includeHistoricalData: form.includeHistory,
      })

      closeDrawer()
    } catch (error) {
      Toast.display(
        intl.formatMessage({ id: 'metrics.errorLinkingMetric' }),
        'error'
      )
    }
  }

  const disableForm = !form.metric?.id

  return (
    <Drawer
      isOpen={isOpen}
      headerTitle={intl.formatMessage(
        { id: 'metrics.addGroupNameTo' },
        { groupName: receiverGroup?.name }
      )}
      onCloseDrawer={closeDrawer}
    >
      <Drawer.Content contentPadding="2rem 2.4rem">
        <Dropdown
          loadOptions={loadMetrics}
          onSelectOption={onChangeMetric}
          label={intl.formatMessage({ id: 'metrics.selectMetric' })}
          placeholder={intl.formatMessage({ id: 'general.select' })}
          async
          type="input"
          getOption={(entity) => ({ label: entity.label, id: entity.id })}
        />
        <Separator space="1.8rem" />

        <CheckboxWrapper>
          <Checkbox
            id="includeHistory"
            name="includeHistory"
            onChange={onChangeCheckbox}
            label={intl.formatMessage({ id: 'metrics.includeHistoryData' })}
            checked={form.includeHistory}
          />
        </CheckboxWrapper>
      </Drawer.Content>
      <Drawer.Footer>
        <CTA>
          <Button onClick={closeDrawer}>
            {intl.formatMessage({ id: 'common.cancel' })}
          </Button>

          <Button
            autoWidth
            primary
            blue
            onClick={onConfirm}
            disabled={disableForm}
          >
            {intl.formatMessage({ id: 'common.save' })}
          </Button>
        </CTA>
      </Drawer.Footer>
    </Drawer>
  )
}

export default AddGroupToExistingMetric
