import Chip from 'components/Chip'
import ChipLoader from 'components/loaders/chipLoader'
import { isGroup } from 'utils/functions/groups'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PortfolioIcon, StyledGroupLogo } from 'components/Chip/Chip.styles'
import { Image, Nullable } from 'utils/types/common'
import { ChipsList, ChipsListItem } from './BoxGroupsAndUserChips.styles'

interface Item {
  type: string
  name: string
  id: string
  logo?: Image
  email?: string
  firstName?: string
  lastName?: string
  fromMetric?: boolean
  primaryLogo?: string
  canDelete?: boolean
  unreachableEmailAddress?: boolean
  user?: {
    unreachableEmailAddress: boolean
  }
}

interface Props {
  items: Item[]
  handleDelete?: (id: string) => void
  ownedByGroup?: Nullable<string>
  loading?: boolean
  canDelete?: boolean
}

const BoxGroupsAndUsersChips = ({
  items = [],
  handleDelete,
  ownedByGroup = '',
  loading,
  canDelete = true,
}: Props) => {
  const getItemText = ({
    type,
    email,
    firstName,
    lastName,
    name,
    fromMetric,
  }: Item) => {
    if (isGroup(type) || fromMetric) {
      return name
    }

    if (name) {
      return name
    }

    if (!firstName || type === 'email') {
      return email
    }

    return `${firstName} ${lastName}`
  }

  const getImageComponent = ({ type, name, logo, primaryLogo }: Item) => {
    const normalizedType = type?.toLowerCase()
    if (normalizedType === 'portfolio' || normalizedType === 'invest') {
      return (
        <PortfolioIcon>
          <FontAwesomeIcon icon={['far', 'dollar-sign']} />
        </PortfolioIcon>
      )
    }

    if (normalizedType === 'fund') {
      return (
        <PortfolioIcon>
          <FontAwesomeIcon icon={['far', 'sack-dollar']} />
        </PortfolioIcon>
      )
    }

    if (name && normalizedType === 'user') {
      return <StyledGroupLogo forceInitials name={name} small />
    }

    const image = logo || primaryLogo

    if (image) {
      return (
        <StyledGroupLogo
          imageUrl={(image as Image)?.url ?? image}
          name={name}
          small
        />
      )
    }

    return null
  }

  if (loading) {
    return <ChipLoader />
  }

  return items.length > 0 ? (
    <ChipsList className="fs-exclude">
      {items.map((item) => {
        return (
          <ChipsListItem>
            <Chip
              dataTestId={`group-user-chip-${item.id}`}
              canDelete={
                item.id !== ownedByGroup && canDelete && item.canDelete
              }
              imageComponent={getImageComponent(item)}
              handleDelete={() => {
                handleDelete?.(item.id)
              }}
              text={getItemText(item)}
              isUnreachableEmail={
                item?.unreachableEmailAddress ||
                item?.user?.unreachableEmailAddress
              }
            />
          </ChipsListItem>
        )
      })}
    </ChipsList>
  ) : null
}

export default BoxGroupsAndUsersChips
