import Chip from 'components/Chip'
import { useId, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDynamicWidth } from 'utils/hooks/useDynamicWidth'
import theme, { Color } from 'utils/theme'

import Avatar from 'components/Avatar/Avatar'
import Tooltip from 'components/Tooltip'
import { useEventListener } from 'utils/hooks/useEventListener'
import { Logo } from 'utils/types/common'
import { PortfolioType } from 'utils/types/portfolios'
import { getInitials } from 'utils/functions/user'
import * as Styles from './ChipList.styles'

interface Entity {
  id: string
  name: string
  primaryLogo?: Logo
  type?: PortfolioType
}

interface ChipListProps {
  entities: Entity[]
  tooltipContentType: 'list' | 'grid'
}

const dynamicWidthConfig = {
  rowsCount: 1,
  paddingGap: 8,
  showMoreWidth: 60,
  baseWidth: 19.6,
}

const ChipList: React.FC<ChipListProps> = ({
  entities,
  tooltipContentType,
}) => {
  const [key, setKey] = useState(0)
  const id = useId()
  const intl = useIntl()
  const { containerRef, maxItemsCount: maxChipsCount } = useDynamicWidth(
    entities || [],
    false,
    dynamicWidthConfig
  )

  const visibleEntities = useMemo(
    () => entities?.slice(0, maxChipsCount) ?? [],
    [entities, maxChipsCount]
  )
  const showMore = useMemo(
    () => entities && entities.length > maxChipsCount,
    [entities, maxChipsCount]
  )
  const showMoreEntities = useMemo(
    () => entities?.slice(maxChipsCount, entities.length) ?? [],
    [entities, maxChipsCount]
  )

  const getTooltipContent = () => {
    if (tooltipContentType === 'grid') {
      return (
        <Styles.GridContainer>
          {showMoreEntities.map((entity) => (
            <Chip
              key={'grid-tooltip-content-'.concat(entity.id)}
              maxWidth="14rem"
              text={entity.name}
              canDelete={false}
              backgroundColor={theme.colors.veryLightBlue40}
              titleColor={theme.colors.darkGray}
              className="small"
            />
          ))}
        </Styles.GridContainer>
      )
    }

    return (
      <Styles.ListContainer>
        {showMoreEntities.map((entity) => (
          <Styles.ListItem key={'tooltip-content-'.concat(entity.id)}>
            {entity.primaryLogo && (
              <Styles.AvatarContainer>
                <Avatar
                  avatarStyle="avatarCircleXS"
                  image={entity.primaryLogo.url}
                  initials={getInitials(entity.name)}
                />
              </Styles.AvatarContainer>
            )}
            {entity.type && <Styles.PortfolioIcon type={entity.type} />}
            {entity.name}
          </Styles.ListItem>
        ))}
      </Styles.ListContainer>
    )
  }

  const mainLayoutElement = document.getElementById('mainLayout')
  const tableScrollElement = document.getElementById(
    'scrollable-table-container'
  )
  // Force a re-render on horizontal or vertical scroll to recalculate tooltip position
  useEventListener('scroll', () => setKey((k) => k + 1), mainLayoutElement)
  useEventListener('scroll', () => setKey((k) => k + 1), tableScrollElement)

  if (!entities?.length) {
    return <span>-</span>
  }

  return (
    <Styles.EntitiesChips ref={containerRef}>
      {visibleEntities.map((entity) => {
        if (entity.primaryLogo) {
          return (
            <Chip
              dataTestId={entity.id}
              key={entity.id}
              maxWidth="15rem"
              text={entity.name}
              canDelete={false}
              backgroundColor={theme.colors.veryLightBlue40}
              titleColor={theme.colors.darkGray}
              className="small"
              image={entity.primaryLogo?.url}
            />
          )
        }

        if (entity.type) {
          return (
            <Styles.ListItem
              key={entity.id}
              backgroundColor={theme.colors.veryLightBlue40 as Color}
              borderRadius="1.5rem"
              fontWeight="400"
              padding="0.2rem 1.4rem 0.2rem 0.2rem"
              gap="0.6rem"
            >
              {entity.type && <Styles.PortfolioIcon type={entity.type} />}
              <Styles.Name>{entity.name}</Styles.Name>
            </Styles.ListItem>
          )
        }

        return null
      })}
      {showMore && (
        <Tooltip
          id={id}
          key={key}
          backgroundColor="transparent"
          color="black"
          text={getTooltipContent()}
          delayHide={500}
          clickable
          place="bottom"
          leftOffset={-20}
          topOffset={-7}
        >
          <Styles.ShowMoreChip>
            +{showMoreEntities.length}{' '}
            {intl.formatMessage({
              id: 'common.more',
            })}
          </Styles.ShowMoreChip>
        </Tooltip>
      )}
    </Styles.EntitiesChips>
  )
}

export default ChipList
