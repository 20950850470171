import type {
  CellTemplate,
  Compatible,
  EventHandlers,
  Uncertain,
  UncertainCompatible,
} from '@silevis/reactgrid'
import {
  getCharFromKeyCode,
  isAlphaNumericKey,
  keyCodes,
} from '@silevis/reactgrid'
import { ReactNode, MutableRefObject } from 'react'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { Holding } from 'utils/types/company'
import SpreadsheetHoldingsDropdown from './SpreadsheetHoldingsDropdown/SpreadsheetHoldingsDropdown'
import { CustomCell } from '../../Spreadsheet/types'

export const HOLDING_FETCHED_EVENT = 'SPREADSHEET_HOLDING_FETCHED_EVENT'

export type HoldingFetchedEventType = { holding?: Holding; holdingName: string }

export const UNKNOWN_HOLDING_ID = 'UNKNOWN_HOLDING_ID'

export type UnknownHoldingData = {
  id: string
  name: string
  fetchingHolding?: boolean
  invalid?: boolean
}

export type HoldingInfo = Holding | UnknownHoldingData

export interface HoldingCell extends CustomCell {
  type: 'holdingDropdown'
  holding?: HoldingInfo
  initialChar?: string
  initialHoldings: HoldingInfo[]
  loading?: boolean
  placeholderId?: string
}

export class CustomHoldingCellTemplate implements CellTemplate<HoldingCell> {
  fetchHoldings: (holdingName: string) => Promise<Holding[]>

  eventHandler: MutableRefObject<EventHandlers | undefined>

  constructor(
    fetchHoldings: (holdingName: string) => Promise<Holding[]>,
    eventHandler: MutableRefObject<EventHandlers | undefined>
  ) {
    this.fetchHoldings = fetchHoldings
    this.eventHandler = eventHandler
  }

  // eslint-disable-next-line class-methods-use-this
  getCompatibleCell(
    uncertainCell: Uncertain<HoldingCell>
  ): Compatible<HoldingCell> {
    const { holding } = uncertainCell

    const text = holding?.name || ''
    return {
      ...uncertainCell,
      holding,
      text,
      value: NaN,
      initialHoldings: uncertainCell.initialHoldings || [],
      rowIndex: uncertainCell.rowIndex || 0,
    }
  }

  update(
    cell: Compatible<HoldingCell>,
    cellToMerge: UncertainCompatible<HoldingCell>
  ): Compatible<HoldingCell> {
    let { holding } = cellToMerge
    const { text: holdingName } = cellToMerge
    const isPastingFromExcelOrGSheets = !holding && holdingName
    const isFilling = holding && holdingName && holding.name !== holdingName

    if (isPastingFromExcelOrGSheets || isFilling) {
      holding = cell.initialHoldings.find((m) => m.name === holdingName)

      if (!holding) {
        this.fetchHoldings(holdingName).then((holdings) => {
          const fetchedHolding = holdings.find((m) => m.name === holdingName)
          dispatchEvent<HoldingFetchedEventType>(HOLDING_FETCHED_EVENT, {
            holding: fetchedHolding,
            holdingName,
          })
        })

        return this.getCompatibleCell({
          ...cell,
          error: undefined,
          holding: {
            id: UNKNOWN_HOLDING_ID,
            name: holdingName,
            fetchingHolding: true,
          },
        })
      }
    }

    return this.getCompatibleCell({
      ...cell,
      error: undefined,
      holding,
    })
  }

  // eslint-disable-next-line class-methods-use-this
  handleKeyDown(
    cell: Compatible<HoldingCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<HoldingCell>; enableEditMode: boolean } {
    const char = getCharFromKeyCode(keyCode, shift)
    if (
      !ctrl &&
      !alt &&
      isAlphaNumericKey(keyCode) &&
      !(shift && keyCode === keyCodes.SPACE)
    ) {
      return {
        cell: {
          ...cell,
          initialChar: char,
        },
        enableEditMode: true,
      }
    }
    const isOpen = keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER

    return {
      cell,
      enableEditMode: isOpen,
    }
  }

  render(
    cell: Compatible<HoldingCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<HoldingCell>, commit: boolean) => void
  ): ReactNode {
    return (
      <SpreadsheetHoldingsDropdown
        cell={cell}
        onCellChanged={onCellChanged}
        isInEditMode={isInEditMode}
        loadHoldings={this.fetchHoldings}
        initialHoldings={cell.initialHoldings}
        getCompatibleCell={this.getCompatibleCell}
        eventHandler={this.eventHandler}
      />
    )
  }
}
