import { IFormContext } from 'components/Form/hooks'
import { compact } from 'lodash'
import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { MetricFrequency } from 'utils/constants/metrics'
import { useGroupQuery } from 'utils/hooks/queries/useGroupQuery'
import { useLinkedMetric } from 'utils/hooks/queries/useLinkedMetric'
import { useCreateFounderMetricQuery } from 'utils/hooks/queries/useMetrics'
import useMetricGroups from 'utils/hooks/useMetricGroups'
import useMetricQuery from 'utils/hooks/useMetricQuery'
import { getFounderMetricsSchema } from 'utils/schemas/metrics'
import { CompanyHoldingData } from 'utils/types/company'
import {
  IndexMetric,
  LinkedMetric,
  LinkedMetricState,
  Metric,
} from 'utils/types/metricsV2'

type MyRefType = {
  clear: () => void
  close: () => void
}

type SharedGroups = {
  id: string
  name: string
}

export interface CreateFounderMetricFormType extends IFormContext {
  name: string
  frequency: string
  sharedGroups: SharedGroups[]
}

interface GetInitialValuesProps {
  linkedMetric?: LinkedMetric
  receiverMetric?: Metric
}

const getInitialValues = ({
  linkedMetric,
  receiverMetric,
}: GetInitialValuesProps) => {
  const defaultValues = {
    name: '',
    frequency: MetricFrequency.NONE,
    sharedGroups: [],
  }

  if (linkedMetric) {
    defaultValues.name = receiverMetric?.name ?? defaultValues.name
    defaultValues.frequency = receiverMetric?.frequency || MetricFrequency.NONE
  }

  return defaultValues
}

interface Props {
  closeDrawer: () => void
}

const useCreateFounderMetric = ({ closeDrawer }: Props) => {
  const firstRender = useRef<boolean>(true)
  const dropdownRef = useRef<MyRefType>(null)
  const intl = useIntl()

  const { state } = useLocation<{
    linkedMetric?: LinkedMetric
    companyToAdd?: CompanyHoldingData
  }>()
  const { linkedMetric } = state

  const clearDropdown = () => {
    dropdownRef.current?.clear()
    dropdownRef.current?.close()
  }

  const {
    setCurrentGroups,
    handleAddGroup,
    handleRemoveGroup,
    loadGroups,
    currentGroups,
    searchText: groupSearchText,

    itemLists,
    handleAddItemList,
    handleDeleteItemList,
    handleEditItemList,
  } = useMetricGroups()

  const { metric: receiverMetric } = useMetricQuery({
    metricId: linkedMetric?.receiverMetricId ?? '',
  })
  const { data: initialCurrentGroups } = useGroupQuery(
    linkedMetric?.receiverGroupId ?? '',
    {
      enabled: !!linkedMetric?.receiverGroupId,
    }
  )
  const initialValues = getInitialValues({
    linkedMetric,
    receiverMetric,
  })

  const validationSchema = getFounderMetricsSchema(intl)

  const onSelectGroup = (...params) => {
    handleAddGroup(...params)
    setTimeout(clearDropdown)
  }

  useEffect(() => {
    if (!firstRender.current) return

    if (initialCurrentGroups) {
      setCurrentGroups([
        {
          id: initialCurrentGroups?.id,
          linkedMetricId: state.linkedMetric?.id,
          name: initialCurrentGroups?.name,
          logo: {
            url: initialCurrentGroups?.logo?.url,
          },
          canDelete: false,
        },
      ])
    } else {
      setCurrentGroups([])
    }

    firstRender.current = false
  }, [
    initialCurrentGroups,
    initialCurrentGroups?.id,
    initialCurrentGroups?.logo?.url,
    initialCurrentGroups?.name,
    setCurrentGroups,
    state?.linkedMetric?.id,
  ])

  const { mutate: acceptRequestedLinkedMetric } = useLinkedMetric(
    state.linkedMetric?.id
  )
  const onCreateMetricSuccess = async (metric: IndexMetric) => {
    if (linkedMetric) {
      await acceptRequestedLinkedMetric({
        newState: LinkedMetricState.REQUEST_ACCEPTED,
        metricId: metric.id,
      })
    }
  }

  const { mutate: createMetricMutation, isLoading: isCreatingMetric } =
    useCreateFounderMetricQuery({
      closeDrawer,
      onSuccess: onCreateMetricSuccess,
    })

  const preventCreateANewLinkedMetric = (sharedGroups: SharedGroups[]) => {
    if (linkedMetric) {
      return sharedGroups.filter(
        (group) => linkedMetric.receiverGroupId !== group.id
      )
    }

    return sharedGroups
  }

  const createMetric = (values: CreateFounderMetricFormType) => {
    const sharedGroups = preventCreateANewLinkedMetric(values.sharedGroups)

    return createMetricMutation({ ...values, sharedGroups })
  }

  const getSubOptionById = (id, options) =>
    compact<{ id: string; label: string }>(
      options.map((option) => {
        return option.subOptions.find((subOption) => subOption.id === id)
      })
    )?.[0]

  return {
    initialValues,
    validationSchema,
    isCreatingMetric,
    createMetric,

    currentGroups,
    onSelectGroup,
    handleRemoveGroup,
    loadGroups,
    groupSearchText,

    itemLists,
    handleAddItemList,
    handleDeleteItemList,
    handleEditItemList,

    getSubOptionById,
  }
}

export default useCreateFounderMetric
