import { Item } from 'containers/InvestorManagement/components'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { removeHttp } from 'utils/functions/strings'
import { InvestmentVehicle, Investor } from 'utils/types/investors'
import { AdditionInformation } from '../CompanyHeader/HeaderAdditionalInformation.styles'

interface Props {
  investor: Investor
  isShowingInvestmentVehicle?: boolean
  investmentVehicle?: InvestmentVehicle
}

const HeaderAdditionalInformation = ({
  investor,
  isShowingInvestmentVehicle,
  investmentVehicle,
}: Props) => {
  const intl = useIntl()
  const shareInvestorData =
    investmentVehicle?.investmentVehicleInvestor?.shareData

  return (
    <AdditionInformation padding="1.8rem 0 0 0">
      {!!investor.emails.length && (
        <Item
          isDark
          icon={['far', 'envelope']}
          value={removeHttp(investor.emails[0])}
          type="darkLink"
          maxLines={1}
          id="investor_email"
        />
      )}

      {investor.website && (
        <Item
          isDark
          icon={['far', 'link']}
          value={removeHttp(investor.website)}
          type="darkLink"
          href={investor.website}
          maxLines={1}
          id="investor_website"
          topOffset={50}
          leftOffset={250}
        />
      )}

      {isShowingInvestmentVehicle && !shareInvestorData
        ? investmentVehicle?.phone && (
            <Item
              icon={['far', 'phone-alt']}
              value={investmentVehicle?.phone}
              type="text"
            />
          )
        : investor.phone && (
            <Item
              icon={['far', 'phone-alt']}
              value={investor.phone}
              type="text"
            />
          )}

      {isShowingInvestmentVehicle && !shareInvestorData
        ? investmentVehicle?.locations?.[0]?.place?.formattedAddress && (
            <Item
              icon={['far', 'map-marker-alt']}
              value={investmentVehicle?.locations?.[0].place?.formattedAddress}
              type="text"
            />
          )
        : investor.generalInformation.address && (
            <Item
              icon={['far', 'map-marker-alt']}
              value={investor.generalInformation.address}
              type="text"
            />
          )}

      {investor.foundedDate && (
        <Item
          icon={['far', 'birthday-cake']}
          value={intl.formatMessage(
            {
              id: 'investorManagement.profiles.foundedDate',
            },
            { date: dayjs(investor.foundedDate).format('MMMM YYYY') }
          )}
          type="text"
        />
      )}

      {!isShowingInvestmentVehicle && (
        <>
          {!!investor.generalInformation.teamSize && (
            <Item
              icon={['far', 'users']}
              value={intl.formatMessage(
                {
                  id: 'investorManagement.profiles.teamSize',
                },
                { employeesCount: investor.generalInformation.teamSize }
              )}
              type="text"
            />
          )}

          {investor.legalStructure?.name && (
            <Item
              icon={['far', 'list-ul']}
              value={investor.legalStructure.name}
              type="text"
            />
          )}

          {!!investor.generalInformation.investmentToolIndustries.length && (
            <Item
              isDark
              icon={['far', 'industry-alt']}
              value={investor.generalInformation.investmentToolIndustries
                .map((ind) => ind.name)
                .join(', ')}
              type="tooltip"
              maxLines={3}
            />
          )}
        </>
      )}
    </AdditionInformation>
  )
}

export default HeaderAdditionalInformation
