import { useLocation, useRouteMatch } from 'react-router-dom'

export const SLASH_METRICS = '/metrics'

export const useMetricsRoute = () => {
  const defaultRouteMatch = useRouteMatch<{ tab: string }>('/metrics/:tab')
  const companyProfileRouteMatch = useRouteMatch<{ id: string }>(
    '/companies/:id'
  )
  const groupProfileRouteMatch = useRouteMatch('/group-profile')
  const dashboardRouteMatch = useRouteMatch<{ id: string }>('/dashboard')
  const fundProfileRouteMatch = useRouteMatch('/settings/group_management/fund')
  const privateProfileRouteMatch = useRouteMatch(
    '/settings/group_management/profile'
  )
  const investmentsRouteMatch = useRouteMatch<{ id: string }>('/investments')

  const route = useLocation()
  let value = SLASH_METRICS

  if (defaultRouteMatch) {
    value = defaultRouteMatch!.url
  } else if (companyProfileRouteMatch?.params.id) {
    value = `/companies/${companyProfileRouteMatch.params.id}${SLASH_METRICS}`
  } else if (groupProfileRouteMatch) {
    value = `/group-profile${SLASH_METRICS}`
  } else if (dashboardRouteMatch) {
    value = `/dashboard${SLASH_METRICS}`
  } else if (fundProfileRouteMatch) {
    value = `/settings/group_management/fund${SLASH_METRICS}`
  } else if (privateProfileRouteMatch) {
    value = `/settings/group_management/profile${SLASH_METRICS}`
  } else if (investmentsRouteMatch) {
    const isBaseRouteMetric = route.pathname.endsWith(SLASH_METRICS)
    value = isBaseRouteMetric
      ? route.pathname
      : route.pathname.split('/').slice(0, -1).join('/')
  }

  return value
}
