import {
  type InfiniteData,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import NumbersService from 'api/NumbersService'
import Toast from 'components/Toast'
import useBulkImportMetricValuesModalHandlers from 'containers/Metrics/BulkImportMetricValuesModal/useBulkImportMetricValuesModalHandlers'
import { useMetricsContext } from 'containers/Metrics/MetricsContext'
import { useMetrics } from 'containers/Metrics/MetricsList/useMetrics'
import { getIsReadOnlyMode } from 'selectors/auth'
import { getCurrentCompany } from 'selectors/company'
import { DELETED_METRIC_EVENT } from 'utils/constants/events'
import { MetricSortBy, METRICS_PAGE_SIZE } from 'utils/constants/metrics'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useEventListener } from 'utils/hooks/useEventListener'
import useGroupCompany from 'utils/hooks/useGroupCompany'
import { useHighlightText } from 'utils/hooks/useHighlightText'
import { useMetricsRoute } from 'utils/hooks/useMetricsRoute'
import { companyKeys, CompanyMetricsFilters } from 'utils/queries/companies'
import { CompanyHoldingData } from 'utils/types/company'
import { IndexMetric } from 'utils/types/metricsV2'
import { SortDirection } from 'utils/constants'
import { useProfileMetricsActions } from './useProfileMetricsActions'

export const useProfileMetrics = ({
  companyInfo,
  requestFounderMetrics,
}: {
  companyInfo?: CompanyHoldingData
  requestFounderMetrics?: boolean
} = {}) => {
  const metricsRoute = useMetricsRoute()
  const {
    onAddMetric,
    onUpdateAllMetrics,
    getAddMetricReadOnlyTooltip,
    getUpdateAllMetricsReadOnlyTooltip,
  } = useProfileMetricsActions(companyInfo, requestFounderMetrics)
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationEnabled, setPaginationEnabled] = useState(true)
  const isReadOnlyMode = useAppSelector(getIsReadOnlyMode)
  const currentCompany = useAppSelector(getCurrentCompany)
  const founderCompany = useGroupCompany()
  const queryClient = useQueryClient()
  const { companyId = currentCompany.id } = useParams<{ companyId?: string }>()
  const { debouncedSearch: debouncedMetricSearch, sortCriteria } =
    useMetricsContext()
  const removedMetricPosition = useRef<
    Record<string, { page: number; index: number }>
  >({})

  const { metricBulkImportModal, onHideImportCsvModal, onImportCsv } =
    useBulkImportMetricValuesModalHandlers()

  const {
    onEditMetric,
    onRemoveMetric,
    onAddNewValue,
    onSetMilestone,
    onEditMilestone,
    onViewMetricDetail,
    removedMetricsIds,
    setRemovedMetricsIds,
    exportMetric,
  } = useMetrics()

  const fetchMetrics = async (page: number, filters: CompanyMetricsFilters) => {
    try {
      const metricsData = await NumbersService.getMetrics({
        page,
        metricName: filters.metricName,
        sortDirection: filters.sortDirection,
        subjectId: requestFounderMetrics ? founderCompany?.id : companyId,
      })

      setPaginationEnabled(metricsData.length === METRICS_PAGE_SIZE)

      return { data: metricsData, page }
    } catch (err) {
      Toast.displayIntl('metrics.metricListError', 'error')
      return { data: [], page }
    }
  }

  const {
    data: metrics,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    companyKeys.companyMetricsWithFilters({
      companyId: requestFounderMetrics ? founderCompany?.id! : companyId,
      metricName: debouncedMetricSearch,
      sortBy: sortCriteria?.field,
      sortDirection: sortCriteria?.direction,
    }),
    ({ pageParam = 1, queryKey }) => {
      return fetchMetrics(pageParam, queryKey[2])
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.data.length === 0) return lastPage.page
        return (lastPage?.page ?? 0) + 1
      },
    }
  )

  const addMetricToQueryData = (
    queryKey: readonly ['company-metrics', string, CompanyMetricsFilters],
    metric: IndexMetric
  ) => {
    queryClient.setQueryData(
      queryKey,
      (oldData: InfiniteData<{ data: IndexMetric[] }>) => {
        const metricPosition = removedMetricPosition.current[metric.id]

        if (metricPosition) {
          oldData.pages[metricPosition.page].data.splice(
            metricPosition.index,
            0,
            metric
          )
        } else {
          const lastPage = oldData.pages.length - 1
          oldData.pages[lastPage].data.push(metric)
        }

        return oldData
      }
    )
  }

  const removeMetricFromQueryData = (
    queryKey: readonly ['company-metrics', string, CompanyMetricsFilters],
    id: string
  ) => {
    let removedFromPage = -1
    let removedFromData = -1

    queryClient.setQueryData(
      queryKey,
      (oldData: InfiniteData<{ data: IndexMetric[] }>) => {
        oldData.pages.forEach((page, pageIndex) => {
          page.data.forEach((m, metricIndex) => {
            if (m.id === id) {
              removedFromPage = pageIndex
              removedFromData = metricIndex
            }
          })
        })

        if (removedFromPage !== -1 && removedFromData !== -1) {
          oldData.pages[removedFromPage].data.splice(removedFromData, 1)
        }

        return oldData
      }
    )

    removedMetricPosition.current[id] = {
      page: removedFromPage,
      index: removedFromData,
    }
  }

  useEventListener<{ id: string; hidden: boolean; metric: IndexMetric }>(
    DELETED_METRIC_EVENT,
    ({ id, hidden, metric }) => {
      const companyIdKey = requestFounderMetrics
        ? founderCompany?.id!
        : companyId
      const queryKey = companyKeys.companyMetricsWithFilters({
        companyId: companyIdKey,
        metricName: '',
        sortBy: MetricSortBy.NAME,
        sortDirection: SortDirection.ASC,
      })

      if (hidden) {
        setRemovedMetricsIds((currState) => [...currState, id])
        removeMetricFromQueryData(queryKey, id)
      } else {
        setRemovedMetricsIds((currState) =>
          currState.filter((currId) => currId !== id)
        )
        addMetricToQueryData(queryKey, metric)
      }
    }
  )

  useEffect(() => {
    setCurrentPage(1)
  }, [])

  const metricsVisible = useMemo(() => {
    return {
      ...metrics,
      pages: metrics?.pages?.map((page) => {
        return {
          ...page,
          data: page?.data?.filter?.(
            (metricData) => !removedMetricsIds.includes(metricData.id)
          ),
        }
      }),
    }
  }, [removedMetricsIds, metrics])

  useHighlightText(
    {
      elementClass: '.metric-name',
      text: debouncedMetricSearch,
    },
    [debouncedMetricSearch, metrics]
  )

  const onNameClick = () => {
    window.open(`/companies/${companyInfo?.id}`, '_blank')
  }

  return {
    currentPage,
    isLoading,
    removedMetricsIds,
    metrics: metricsVisible,
    onEditMetric,
    onRemoveMetric,
    onAddNewValue,
    paginationEnabled,
    fetchNextPage,
    isFetchingNextPage,
    onSetMilestone,
    onEditMilestone,
    isReadOnlyMode,
    metricBulkImportModal,
    onImportCsv,
    onHideImportCsvModal,
    onAddMetric,
    onUpdateAllMetrics,
    getAddMetricReadOnlyTooltip,
    getUpdateAllMetricsReadOnlyTooltip,
    showFounderMetricsZeroState:
      !isLoading && metricsVisible?.pages?.[0]?.data?.length === 0,
    onViewMetricDetail,
    exportMetric,
    showNoResults:
      !isLoading &&
      !metricsVisible?.pages?.[0]?.data?.length &&
      !!debouncedMetricSearch,
    metricsRoute,
    searchText: debouncedMetricSearch,
    onNameClick,
  }
}
