import { useIntl } from 'react-intl'
import styled from 'styled-components'

import {
  getDropdownOptionsForStatus,
  groupMilestonesByState,
} from 'utils/functions/metrics'
import { IndexMetric, Milestone } from 'utils/types/metricsV2'

import * as MetricStyles from 'components/ProfileMetrics/components/ProfileMetric/ProfileMetric.styles'
import { useHistory } from 'react-router-dom'
import { useGetMilestonesByMetricId } from 'utils/hooks/queries/useMilestonesQuery'

const ActionsWrapper = styled.div`
  margin-bottom: 0.4rem;
`

interface MetricActionsProps {
  metric: IndexMetric
  onRemoveMetric: (metric: IndexMetric) => void
  onAddNewValue: (metric: IndexMetric) => void
  onEditMetric: (metric: IndexMetric) => void
  onSetMilestone: (metric: IndexMetric) => void
  onEditMilestone: (metric: IndexMetric, milestone: Milestone) => void
  onViewMetricDetail: (metricId: string) => void
  onImportCsv: (metric: IndexMetric) => void
  onExportCsv: (metric: IndexMetric) => Promise<void>
  isContentMenuOpen?: boolean
  onCloseContentMenu?: () => void
  onOpenContentMenu?: () => void
  contextMenuPosition?: { x: number; y: number }
}

const MetricActions: React.FC<MetricActionsProps> = ({
  metric,
  onRemoveMetric,
  onAddNewValue,
  onEditMetric,
  onSetMilestone,
  onEditMilestone,
  onViewMetricDetail,
  onImportCsv,
  onExportCsv,
  isContentMenuOpen,
  onCloseContentMenu,
  onOpenContentMenu,
  contextMenuPosition,
}) => {
  const history = useHistory()
  const intl = useIntl()
  const linkTo = `all/${metric.id}`

  const redirectToLogs = () => {
    history.push(linkTo, { activeTab: 2 })
  }

  const { data: fetchedMilestones } = useGetMilestonesByMetricId(metric.id, {
    staleTime: 1000,
  })

  const { activeMilestones } = groupMilestonesByState({
    milestones: fetchedMilestones,
    metricId: metric.id,
  })

  const onImportCsvHandler = () => {
    onImportCsv(metric)
  }

  return (
    <ActionsWrapper>
      <MetricStyles.OptionsDropdown
        buttonType="square"
        buttonSize="3.2rem"
        overridePosition={contextMenuPosition}
        isOpen={isContentMenuOpen}
        onCloseDropdown={onCloseContentMenu}
        onOpenDropdown={onOpenContentMenu}
      >
        {getDropdownOptionsForStatus({
          metricData: metric,
          onRemoveMetric,
          onAddNewValue,
          onEditMetric,
          activeMilestones,
          onSetMilestone,
          onEditMilestone,
          onViewMetricDetail,
          intl,
          redirectToLogs,
          onImportCsv: onImportCsvHandler,
          onExportCsv,
        })}
      </MetricStyles.OptionsDropdown>
    </ActionsWrapper>
  )
}

export default MetricActions
