import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import GroupService from 'api/GroupService'
import {
  getCurrentGroupId,
  getCurrentGroupType,
  getUser,
  isActingAsFounder,
  isActingAsInvestorGroup,
} from 'selectors/auth'
import { fetchTier, getTier } from 'utils/functions/tier'
import { TierRequestStatus } from 'utils/types/tier'
import { groupKeys } from '../../queries/groups'
import { useAppSelector } from '../reduxToolkit'
import { useQueryData } from '../useQueryData'

export const useGroupQuery = (groupId: string, queryOptions = {}) => {
  return useQuery(
    groupKeys.byId(groupId),
    async () => {
      return GroupService.fetchGroup(groupId)
    },
    {
      enabled: !!groupId,
      ...queryOptions,
    }
  )
}

export const useGroupsQuery = (groupIds?: string[], queryOptions = {}) => {
  return useQuery(
    groupKeys.byIds(groupIds ?? ['group-ids']),
    async () => {
      const groups = await Promise.all(
        groupIds?.map((groupId) => GroupService.fetchGroup(groupId)) ?? []
      )

      return groups
    },
    {
      enabled: !!groupIds,
      ...queryOptions,
    }
  )
}

export const useGroupStorage = () => {
  return useQuery(groupKeys.storageUsage(), GroupService.getGroupStorage)
}

export const useGroupTierQuery = () => {
  const currentUser = useAppSelector(getUser)
  const groupId = useAppSelector(getCurrentGroupId)
  const groupType = useAppSelector(getCurrentGroupType)
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  const isFounder = useAppSelector(isActingAsFounder)
  const cachedTierData = useQueryData<{ status: TierRequestStatus }>(
    groupKeys.tier()
  )

  const { data, isLoading } = useQuery(
    groupKeys.tier(),
    async () => fetchTier(),
    {
      refetchInterval: 30000,
      enabled:
        !isFounder &&
        !!currentUser &&
        !!groupId &&
        !isInvestorGroup &&
        cachedTierData?.status !== TierRequestStatus.NOT_FOUND,
    }
  )

  return {
    isLoading,
    tier: getTier(
      data?.status || TierRequestStatus.IDLE,
      data?.tier,
      groupType
    ),
  }
}

export const useRefetchCurrentGroupTier = () => {
  const queryClient = useQueryClient()

  const refetchCurrentGroupTier = useCallback(() => {
    queryClient.invalidateQueries(groupKeys.tier())
  }, [queryClient])

  return {
    refetchCurrentGroupTier,
  }
}
