import React from 'react'
import SelectDatePicker from 'components/TimeSelect/SelectDatePicker'
import IconButton from 'ui/IconButton'
import Input from 'ui/Input'
import { ErrorType } from 'utils/types/common'
import { buildFormError } from 'utils/functions/forms'
import {
  TableCol,
  TableColIndex,
  TableRow,
} from './BulkImportMetricValuesModal.styles'

interface Props {
  index: number
  name: string
  values: {
    dataPointValue: number
    dataPointDate: Date
  }
  errors: {
    dataPointValue: string
    dataPointDate: string
  }
  touched: {
    dataPointValue: boolean
    dataPointDate: boolean
  }
  showDelete: boolean
  remove: (index: number) => void
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  setFieldValue: (field: string, value: any) => void
}

const ImportMetricValueRow = ({
  index,
  name,
  remove,
  values,
  errors,
  touched,
  showDelete,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  return (
    <TableRow>
      <TableColIndex flex={0}>{index + 1}.</TableColIndex>
      <TableCol flex={1}>
        <Input
          id={`${name}.dataPointValue`}
          name={`${name}.dataPointValue`}
          type="number"
          onChange={handleChange}
          value={values.dataPointValue}
          error={buildFormError(
            errors?.dataPointValue,
            ErrorType.ERROR,
            touched?.dataPointValue
          )}
          onBlur={handleBlur}
          showErrorMessage={false}
        />
      </TableCol>
      <TableCol flex={0.6} minWidth="16rem">
        <SelectDatePicker
          id={`${name}.dataPointDate`}
          name={`${name}.dataPointDate`}
          handleChange={(date) => setFieldValue(`${name}.dataPointDate`, date)}
          onlyMonthYearPicker={false}
          value={values.dataPointDate}
          error={buildFormError(
            errors?.dataPointDate,
            ErrorType.ERROR,
            touched?.dataPointDate
          )}
          dateFormat="dd MMM, yyyy"
          maxDate={new Date()}
          onBlur={handleBlur}
          showErrorMessage={false}
          closeOnScroll
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ]}
        />
      </TableCol>
      <TableCol flex={0}>
        {showDelete && (
          <IconButton
            type="button"
            icon="times"
            onClick={() => remove(index)}
            border={false}
            buttonSize="2.4rem"
          />
        )}
      </TableCol>
    </TableRow>
  )
}

export default React.memo(
  ImportMetricValueRow,
  (prevProps: Props, nextProps: Props) => {
    return (
      prevProps.showDelete === nextProps.showDelete &&
      prevProps.index === nextProps.index &&
      prevProps.values.dataPointDate === nextProps.values.dataPointDate &&
      prevProps.values.dataPointValue === nextProps.values.dataPointValue &&
      prevProps.errors?.dataPointDate === nextProps.errors?.dataPointDate &&
      prevProps.errors?.dataPointValue === nextProps.errors?.dataPointValue &&
      prevProps.touched?.dataPointDate === nextProps.touched?.dataPointDate &&
      prevProps.touched?.dataPointValue === nextProps.touched?.dataPointValue
    )
  }
)
