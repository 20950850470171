import { useQuery } from '@tanstack/react-query'
import HoldingsService from 'api/HoldingsService'
import PortfolioService from 'api/PortfolioService'
import { subjectKeys } from 'utils/queries/subjects'
import {
  getPortfolioTypeBySubjectType,
  SubjectType,
} from 'utils/types/metricsV2'

interface Props {
  subjectId: string
  subjectType: SubjectType
}

export const useSubjectQuery = (
  { subjectId, subjectType }: Props,
  queryOptions?
) => {
  return useQuery(
    subjectKeys.byId(subjectId, subjectType),
    async () => {
      if (!subjectId || !subjectType) {
        return undefined
      }

      const portfolioType = getPortfolioTypeBySubjectType(subjectType)

      if (portfolioType) {
        return PortfolioService.getPortfolio(subjectId, portfolioType)
      }

      const { holdings } = await HoldingsService.getHoldings({
        page: 1,
        filters: {
          idIn: [subjectId],
        },
        companiesPerPage: 1,
      })

      return holdings[0]
    },
    queryOptions
  )
}
