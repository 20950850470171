import { useLocation } from 'react-router-dom'
import { MetricFrequency } from 'utils/constants/metrics'
import { getInvestorMetricsSchema } from 'utils/schemas/metrics'
import { CompanyHoldingData, Holding } from 'utils/types/company'
import { EntityType } from 'utils/constants/groups'
import { IFormContext } from 'components/Form/hooks'
import { useIntl } from 'react-intl'
import { useCreateInvestorMetricQuery } from 'utils/hooks/queries/useMetrics'
import { LinkedMetric, Metric } from 'utils/types/metricsV2'
import useMetricQuery from 'utils/hooks/useMetricQuery'
import { useGroupQuery } from 'utils/hooks/queries/useGroupQuery'
import { Group } from 'utils/types/user'

export interface CreateInvestorMetricFormType extends IFormContext {
  name: string
  frequency: MetricFrequency
  companiesOrPortfolios: {
    id: string
    name: string
    classType: string
    groupId: string
  }[]
  requestDataFromCompanies: boolean
  message?: string
}

const getInitialValues = ({
  companyToAdd,
  linkedMetric,
  senderMetric,
  groupRequestingMetric,
}: {
  companyToAdd?: CompanyHoldingData
  linkedMetric?: LinkedMetric
  senderMetric?: Metric
  groupRequestingMetric?: Group
}) => {
  const defaultValues = {
    name: '',
    frequency: MetricFrequency.NONE,
    companiesOrPortfolios: companyToAdd
      ? [
          {
            ...companyToAdd,
            classType: companyToAdd.classType!,
            groupId: companyToAdd.groupId!,
          },
        ]
      : [],
    requestDataFromCompanies: true,
    message: '',
    groupRequestingMetric,
  }

  if (linkedMetric) {
    defaultValues.name = senderMetric?.name ?? ''
    defaultValues.frequency = senderMetric?.frequency || MetricFrequency.NONE
    const metricCompany = groupRequestingMetric?.companyData?.[0]

    if (metricCompany) {
      defaultValues.companiesOrPortfolios = [
        {
          id: metricCompany.id,
          name: metricCompany.name,
          type: EntityType.COMPANY,
          primaryLogo: {
            url: metricCompany.primaryLogo?.url,
          } as any,
          groupId: metricCompany.groupId,
          classType: (metricCompany as Holding).classType,
        } as any,
      ]
    }
  }

  return defaultValues
}

export const useCreateInvestorMetric = (closeDrawer: () => void) => {
  const intl = useIntl()
  const { state } = useLocation<{
    linkedMetric?: LinkedMetric
    companyToAdd?: CompanyHoldingData
  }>()
  const { linkedMetric } = state ?? {}

  const { metric: senderMetric } = useMetricQuery({
    metricId: linkedMetric?.senderMetricId ?? '',
  })
  const { data: senderGroup } = useGroupQuery(
    linkedMetric?.senderGroupId ?? '',
    {
      enabled: !!linkedMetric,
    }
  )

  const initialValues = getInitialValues({
    companyToAdd: state?.companyToAdd,
    linkedMetric: state?.linkedMetric,
    senderMetric,
    groupRequestingMetric: senderGroup,
  })

  const validationSchema = getInvestorMetricsSchema(intl)
  const isCreatingMetricFromSharedMetric = !!state?.linkedMetric

  const { mutate: createInvestorMetric, isLoading: isCreatingMetric } =
    useCreateInvestorMetricQuery({
      closeDrawer,
      isCreatingMetricFromSharedMetric,
      linkedMetric: state?.linkedMetric,
    })

  return {
    initialValues,
    validationSchema,
    createInvestorMetric,
    isCreatingMetric,
  }
}
