import type { LegendType } from 'recharts'
import type { CurveType } from 'recharts/types/shape/Curve'
import { Nullable, ValueOf } from 'utils/types/common'
import { MetricSources } from 'utils/types/metrics'
import { DataPoint } from 'utils/types/metricsV2'

export interface MetricDataPoint extends DataPoint {
  metricId: string
  metricName: string
  metricSource: MetricSources
}

export interface DataPointsByPeriod {
  [key: string]: MetricDataPoint[]
}

export interface DataPointsByYear extends DataPointsByPeriod {}

export interface DataPointsByQuarter {
  [key: string]: DataPointsByPeriod
}

export interface DataPointsByDate {
  days: MetricDataPoint[]
}

export interface GroupedDataPoints {
  dataPointsByYear: DataPointsByYear
  dataPointsByQuarter: DataPointsByQuarter
  dataPointsByDate: DataPointsByDate
}

export interface ChartConfig {
  mode: CHART_MODE
  yAxisId: string
}

export enum CHART_MODE {
  LINE = 'lineChart',
  DOTTED_LINE = 'dotChart',
  BAR = 'barChart',
}

export const ChartModeIcons: Record<CHART_MODE, string[]> = {
  [CHART_MODE.BAR]: ['fal', 'chart-column'],
  [CHART_MODE.LINE]: ['fal', 'chart-line'],
  [CHART_MODE.DOTTED_LINE]: ['fal', 'wave-triangle'],
}

export interface MetricData {
  metricId: string
  metricSource: MetricSources
  name: string
  value: number
}

export interface ChartMetric {
  year: string
  quarter: string
  date: Nullable<Date>
  metrics: MetricData[]
}

export enum MetricTypes {
  DISTRIBUTION = 'total distributed',
  INVESTED_CAPITAL = 'total invested',
  FMV = 'total fair market value',
  MOIC = 'multiple on invested capital',
}

export const MOIC_NAME = 'MOIC'

export enum ChartPeriods {
  ALL = 'all',
  YEARLY = 'yearly',
  QUARTERLY = 'quarterly',
}

export const CUSTOM_CHART_Y_AXIS_ID = 'custom'
export const DEFAULT_CHART_Y_AXIS_ID = 'default'

export const VISIBLE_METRICS_LIMIT = {
  [ChartPeriods.YEARLY]: {
    DESKTOP: 7,
    MOBILE: 2,
  },
  [ChartPeriods.QUARTERLY]: {
    DESKTOP: 24,
    MOBILE: 4,
  },
  [ChartPeriods.ALL]: {
    DESKTOP: Infinity,
    MOBILE: Infinity,
  },
}

export const CHART_SCROLL_LIMITS = {
  [ChartPeriods.YEARLY]: 1, // 1 year each time the user scrolls
  [ChartPeriods.QUARTERLY]: 4, // 1 year each time the user scrolls
}

/* 
  Changing the order of the filters will change the order in which they are displayed in the Portfolio Metrics tab
*/
export const METRICS_FILTERS = [
  MetricTypes.INVESTED_CAPITAL,
  MetricTypes.FMV,
  MetricTypes.DISTRIBUTION,
  MetricTypes.MOIC,
]

export type ChartCriteriaType = ValueOf<ChartPeriods>

export interface BarChartConfig {
  radius: number
  dataKey: string
  barSize: number
  fill: string
  yAxisId: string
  legendType: LegendType
  name: string
}

export interface LineChartConfig {
  isAnimationActive: boolean
  type: CurveType
  dataKey: string
  yAxisId: string
  legendType: LegendType
  name: string
  strokeDasharray: string
  stroke: string
  strokeWidth: number
}
