import type { InfiniteData } from '@tanstack/react-query'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { setUpdateAllMetricsDismissed } from 'actions/metrics'
import { Metric as MetricCellData } from 'components/Spreadsheet/CellTemplates/MetricCellTemplate'
import { RowNumberCell } from 'components/Spreadsheet/types'
import {
  MetricsSpreadsheetLogic,
  MetricsSpreadsheetMode,
} from 'components/UpdateMetricsGrid/MetricsSpreadsheetLogic'
import { GridType } from 'components/UpdateMetricsGrid/useUpdateMetricsGrid'
import { useCallback, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useParams } from 'react-router-dom'
import { isActingAsFounder, isActingAsFundManager } from 'selectors/auth'
import { getCurrentCompany } from 'selectors/company'
import { SortDirection } from 'utils/constants'
import { UPDATE_ALL_METRICS_EVENT } from 'utils/constants/events'
import { MetricSortBy } from 'utils/constants/metrics'
import { sortMetrics } from 'utils/functions/metricsCard'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import useGroupCompany from 'utils/hooks/useGroupCompany'
import { companyKeys } from 'utils/queries/companies'
import { MetricsFilters, metricsKeys } from 'utils/queries/metrics'
import { Nullable } from 'utils/types/common'
import { CompanyHolding, Holding } from 'utils/types/company'
import { MetricsMode } from 'utils/types/metrics'
import { IndexMetric } from 'utils/types/metricsV2'
import { useMetricsContext } from '../MetricsContext'

type GetInvestorMetricsParams = {
  companyId: string
  queryClient: QueryClient
  metricName?: string
  founderCompanyId?: string
}

const getFounderPreloadedMetrics = (
  companyId: string,
  queryClient: QueryClient
): MetricCellData[] => {
  const metricPages = queryClient.getQueryData(
    companyKeys.companyMetricsWithFilters({
      companyId,
      metricName: '',
      sortBy: MetricSortBy.NAME,
      sortDirection: SortDirection.ASC,
    })
  ) as InfiniteData<{ data: IndexMetric[] }>

  const metrics = metricPages?.pages
    .filter((page) => !!page)
    .map((page) => page.data)
    .flat()

  return (
    metrics?.map<MetricCellData>((metric) => ({
      id: metric.id,
      name: metric.name,
      fetchingMetric: false,
      receiverGroups: metric.senderLinks
        ?.filter((senderLink) => senderLink.receiverGroupId)
        .map((senderLink) => ({
          id: senderLink.receiverGroupId!,
        })),
    })) ?? []
  )
}

const getInvestorMetricsQueryData = ({
  companyId,
  queryClient,
  metricName,
  founderCompanyId,
}: GetInvestorMetricsParams) => {
  const filters: MetricsFilters = {
    metricName,
    companyDatumIdsToExclude: founderCompanyId ? [founderCompanyId] : undefined,
  }
  const metricPages = queryClient.getQueryData(
    metricsKeys.getMetrics(filters)
  ) as InfiniteData<{ data: IndexMetric[] }> | undefined

  const metrics =
    metricPages?.pages
      .filter((page) => !!page)
      .map((page) => page.data)
      .flat() || []
  return metrics.filter((metric) => metric.subject.id === companyId)
}

const getInvestorPreloadedMetrics = ({
  companyId,
  queryClient,
  metricName,
  founderCompanyId,
}: GetInvestorMetricsParams): MetricCellData[] => {
  let metrics = getInvestorMetricsQueryData({
    companyId,
    queryClient,
    metricName: '',
    founderCompanyId,
  })

  if (metrics.length === 0) {
    metrics = getInvestorMetricsQueryData({
      companyId,
      queryClient,
      metricName,
      founderCompanyId,
    })
  }

  if (metrics.length === 0) {
    const metricsFromProfile = getFounderPreloadedMetrics(
      companyId,
      queryClient
    )

    return metricsFromProfile
  }

  metrics = sortMetrics(metrics, {
    sortBy: MetricSortBy.NAME,
    direction: SortDirection.ASC,
  })

  return metrics.map<MetricCellData>((metric) => ({
    id: metric.id,
    name: metric.name,
    fetchingMetric: false,
    receiverGroups: metric.senderLinks
      ?.filter((senderLink) => senderLink.receiverGroupId)
      .map((senderLink) => ({
        id: senderLink.receiverGroupId!,
      })),
  }))
}

const getPreloadedMetrics = ({
  isFundManager,
  founderCompany,
  companyId,
  search,
  locationHoldingId,
  queryClient,
}: {
  isFundManager: boolean
  founderCompany: Nullable<CompanyHolding>
  companyId: string | undefined
  search: string
  locationHoldingId: string | undefined
  queryClient: QueryClient
}): MetricCellData[] => {
  const shouldGetFounderMetrics =
    isFundManager && founderCompany?.id === locationHoldingId
  const shouldGetPreloadedMetrics = shouldGetFounderMetrics || companyId

  if (shouldGetPreloadedMetrics) {
    return shouldGetFounderMetrics
      ? getFounderPreloadedMetrics(founderCompany?.id!, queryClient)
      : getInvestorPreloadedMetrics({
          companyId: companyId!,
          queryClient,
          metricName: search,
          founderCompanyId: founderCompany?.id,
        })
  }

  return []
}

export const useUpdateAllMetrics = () => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const metricsContext = useMetricsContext()
  const founderCompany = useGroupCompany()
  const dispatch = useAppDispatch()
  const params = useParams<{
    companyId?: string
    optionalCompanyId?: string
  }>()
  const location = useLocation<{
    companyName?: string
    grid?: GridType
    mode?: MetricsMode
    spreadsheetMode?: MetricsSpreadsheetMode
    holding?: Holding
  }>()
  const currentCompany = useAppSelector(getCurrentCompany)
  const paramsCompanyId =
    params.companyId || params.optionalCompanyId || location.state?.holding?.id
  const mode = location.state?.mode
  const gridRef = useRef<GridType | undefined>(location.state?.grid)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const isFounder = useAppSelector(isActingAsFounder)
  const isFundManager = useAppSelector(isActingAsFundManager)
  const disclaimerDismissed = useAppSelector(
    (state: any) => state?.metrics?.updateAllMetricsDismissed
  )
  const shouldDisplayDisclaimer =
    (isFounder || mode === MetricsMode.FOUNDER) && !disclaimerDismissed
  const spreadsheetMode =
    location.state?.spreadsheetMode ||
    (isFounder
      ? MetricsSpreadsheetMode.SINGLE_HOLDING
      : MetricsSpreadsheetMode.MULTIPLE_HOLDINGS)

  const spreadsheetLogic = useRef(
    spreadsheetMode === MetricsSpreadsheetMode.MULTIPLE_HOLDINGS
      ? MetricsSpreadsheetLogic.forMultipleHoldings(
          intl,
          queryClient,
          getPreloadedMetrics({
            isFundManager,
            founderCompany,
            companyId: paramsCompanyId,
            search: metricsContext.search,
            locationHoldingId: location.state?.holding?.id,
            queryClient,
          }),
          {
            holding: location.state?.holding,
            holdingId: paramsCompanyId,
          }
        )
      : MetricsSpreadsheetLogic.forSingleHolding({
          company: currentCompany,
          intl,
          queryClient,
          preloadedMetricRows: getFounderPreloadedMetrics(
            founderCompany?.id!,
            queryClient
          ),
        })
  )

  const checkForErrors = useCallback((grid: GridType) => {
    for (let i = 0; i < grid.length; i++) {
      const row = grid[i]
      const rowNumberCell = row[0] as RowNumberCell

      if (rowNumberCell.errors.size > 0) {
        return
      }
    }

    setErrorMessage('')
  }, [])

  const onGridChange = useCallback(
    (grid: GridType) => {
      gridRef.current = grid
      checkForErrors(grid)
    },
    [checkForErrors]
  )

  const uploadValues = useCallback(async () => {
    if (gridRef.current) {
      return spreadsheetLogic.current.getService().uploadValues(gridRef.current)
    }

    return true
  }, [])

  const dismissDisclaimer = useCallback(
    () => dispatch(setUpdateAllMetricsDismissed(true)),
    [dispatch]
  )

  const uploadValuesAndClose = useCallback(
    (close: () => void) => async () => {
      try {
        setLoading(true)
        setErrorMessage('')
        const uploadSuccesful = await uploadValues()
        setLoading(false)

        if (uploadSuccesful) {
          dispatchEvent(UPDATE_ALL_METRICS_EVENT)
          close()
        }
      } catch (error) {
        setLoading(false)
        setErrorMessage(error.message)
      }
    },
    [uploadValues]
  )

  return {
    gridRef,
    loading,
    onGridChange,
    uploadValuesAndClose,
    errorMessage,
    companyId: currentCompany.id,
    optionalCompanyId: params.optionalCompanyId,
    mode,
    shouldDisplayDisclaimer,
    dismissDisclaimer,
    spreadsheetLogic,
  }
}
